import GameScene from '../../partials/game-scene';
import CustomLoader from '../../partials/custom-loader';
import { InputField } from './components/input-field';
import { Color, EasingFunctions, PossibleStates, Rectangle, ScreenElement, Sprite, StateMachine, Timer, Vector } from 'excalibur';
import { res } from './res';
import Instructor from './components/instructor';
import { Player } from './components/player';
import { EVENTS } from './enums';
import { InputEvent, InstructorPoseEvent } from './partials/custom-events';
import { SpriteEntity } from '../../partials/sprite-entity';
import gameController from '../../partials/games-controller';
import soundManager from '../../partials/sound-manager';
import Progress from './components/progress';
import { random } from '../../utils';
import SideScrollEntity from '../../partials/side-scroll-entity';
import SoundManager from './components/sound';

export default class YogaGame extends GameScene {
	loader: CustomLoader = new CustomLoader([...Object.values(res.graphics), ...Object.values(res.sound)]);
	soundManager!: SoundManager;
	private instructor!: Instructor;
	private player!: Player;
	private gameVariation!: number[][];
	private levels!: number[][];
	private currentLevel!: number;
	private currentExpectedInput!: number[];
	private inputField!: InputField;
	private progressBar!: Progress;
	private fsm!: StateMachine<
		PossibleStates<{
			start: string;
			states: {
				START_LEVEL: { onState: () => Promise<void>; transitions: string[] };
				INIT: { transitions: string[] };
				LEVEL_WIN: { onState: () => Promise<void>; transitions: string[] };
				RESTART: { onState: () => Promise<void>; transitions: string[] };
				NEXT_LEVEL: { onState: () => Promise<void>; transitions: string[] };
				START: { onState: () => Promise<void>; transitions: string[] };
				LEVEL_LOOSE: { onState: () => Promise<void>; transitions: string[] };
			};
		}>,
		unknown
	>;

	onInitialize() {
		super.onInitialize();
		this.engine.backgroundColor = Color.fromHex('#00D6FF');
		this.soundManager = new SoundManager();
		this.soundManager.soundEnabled = soundManager.soundEnabled;
	}

	onActivate() {
		super.onActivate();
		this.currentLevel = -1;
		this.fsm = StateMachine.create({
			start: 'INIT',
			states: {
				INIT: {
					transitions: ['START'],
				},
				START: {
					onState: () => this.onStartState(),
					transitions: ['START_LEVEL'],
				},
				RESTART: {
					onState: () => this.onRestartState(),
					transitions: ['START_LEVEL'],
				},
				START_LEVEL: {
					onState: () => this.onStartLevelState(),
					transitions: ['START', 'RESTART', 'LEVEL_WIN', 'LEVEL_LOOSE'],
				},
				NEXT_LEVEL: {
					onState: () => this.onNextLevelState(),
					transitions: ['START_LEVEL'],
				},
				LEVEL_WIN: {
					onState: () => this.onLevelWinState(),
					transitions: ['NEXT_LEVEL'],
				},
				LEVEL_LOOSE: {
					onState: () => this.onLevelLooseState(),
					transitions: ['RESTART'],
				},
			},
		});
		this.camera.pos = Vector.Zero;
		this.generateGameVariations();
		this.addBackground();
		this.addInstructor();
		this.addPlayer();
		this.addInputField();
		this.addProgressBar();
	}

	async showLevelPoses(poses: number[]) {
		return new Promise(resolve => {
			const waitTimer = new Timer({
				fcn: () => {
					this.resetInputField();
					this.instructor.showStartPose();
					resolve(true);
				},
				interval: (poses.length + 1) * 1000,
			});

			this.add(waitTimer.start());

			const timer = new Timer({
				fcn: () => this.eventDispatcher.emit(EVENTS.INSTRUCTOR_POSE, new InstructorPoseEvent(<number>poses.shift())),
				numberOfRepeats: poses.length,
				repeats: true,
				interval: 1000,
			});

			this.add(timer.start());
		});
	}

	public async gameOver() {
		this.soundManager.playChakra();
		this.resetInputField();
		this.updateCurrentLevel();
		this.progressBar.progress(this.currentLevel);
		this.player.showWinPose();
		this.instructor.showWinPose();
		await this.soundManager.stopAll(1000);

		super.gameOver();
	}

	start() {
		super.start();
		this.fsm.go('START');
	}

	poorPerformanceHandler(): void {}

	startMusic(): void {
		this.soundManager.playMusic();
	}

	protected registerEvents() {
		super.registerEvents();

		// @ts-ignore
		this.eventDispatcher.on(EVENTS.PLAYER_POSE, (event: InputEvent) => {
			this.playNotes(event.indexId);
			this.player.showPose(event.indexId);
			this.checkCurrentLevelInput(event.indexId);
		});
		this.eventDispatcher.on(EVENTS.RESTART, () => this.fsm.go('RESTART'));
		// @ts-ignore
		this.eventDispatcher.on(EVENTS.INSTRUCTOR_POSE, (event: InstructorPoseEvent) => this.playNotes(event.indexId));
	}

	private generateGameVariations() {
		const algorithm = [0, +2, +3, +4, +3, +1, +2];

		this.gameVariation = [];

		for (let i = 0; i < 5; i++) {
			this.gameVariation.push(
				algorithm.map(n => {
					return ((n + i) % 5) + 1;
				}),
			);
		}
	}

	private setLevels() {
		const currentLevel = random.pickOne(this.gameVariation);
		const levelLogic = [];

		for (let i = 1; i < currentLevel.length + 1; i++) {
			levelLogic.push(currentLevel.slice(0, i));
		}

		this.levels = levelLogic;
	}

	private playNotes(id: number) {
		switch (id) {
			case 1:
				return this.soundManager.playNote1();
			case 2:
				return this.soundManager.playNote2();
			case 3:
				return this.soundManager.playNote3();
			case 4:
				return this.soundManager.playNote4();
			case 5:
				return this.soundManager.playNote5();

			default:
				return;
		}
	}

	private addBackground() {
		const { screen } = this.engine;

		const sky = new SpriteEntity();
		const skySprite = <Sprite>res.graphics.bg.getFrameSprite('assets/yoga/BG/gradient');
		skySprite.scale.x = (gameController.drawWidth + 500 * gameController.pixelRatio) / skySprite.width;
		skySprite.scale.y = (gameController.halfDrawHeight + 100 * gameController.pixelRatio) / skySprite.height;
		sky.graphics.use(skySprite, {
			anchor: new Vector(0.5, 1),
		});
		sky.pos = new Vector((500 * gameController.pixelRatio) / 2, 100 + gameController.pixelRatio);

		const rect = new Rectangle({
			width: screen.drawWidth + 500 * gameController.pixelRatio,
			height: screen.halfDrawHeight,
			color: Color.fromHex('#7EC578'),
		});

		const field = new SpriteEntity();
		field.pos = new Vector((500 * gameController.pixelRatio) / 2, screen.halfDrawHeight / 2 + 50 * gameController.pixelRatio);
		field.graphics.use(rect);

		const carpet1 = new SpriteEntity();
		carpet1.pos = new Vector(0, 150 * gameController.pixelRatio);
		carpet1.graphics.use(<Sprite>res.graphics.bg.getFrameSprite('assets/yoga/BG/bg_lv1_vera'), {
			offset: new Vector(-25 * gameController.pixelRatio, -10 * gameController.pixelRatio),
		});

		const carpet2 = new SpriteEntity();
		carpet2.pos = new Vector(880, 150 * gameController.pixelRatio);
		carpet2.graphics.use(<Sprite>res.graphics.bg.getFrameSprite('assets/yoga/BG/bg_lv1_maxim'), {
			offset: new Vector(15 * gameController.pixelRatio, -22 * gameController.pixelRatio),
		});

		const clouds = new SpriteEntity();
		clouds.pos = new Vector(-40, -230 * gameController.pixelRatio);
		clouds.graphics.use(<Sprite>res.graphics.bg.getFrameSprite('assets/yoga/BG/bg_lv4'));
		clouds.parallax.parallaxFactor = new Vector(0, 1);

		const tree = new SpriteEntity();
		tree.pos = new Vector(525, -80 * gameController.pixelRatio);
		tree.graphics.use(<Sprite>res.graphics.bg.getFrameSprite('assets/yoga/BG/bg_lv3'));
		tree.parallax.parallaxFactor = new Vector(0.33, 1);

		const bushSprite = <Sprite>res.graphics.bg.getFrameSprite('assets/yoga/BG/bg_lv2');

		const bush = new SideScrollEntity(screen.drawWidth, bushSprite, 0);
		bush.pos = new Vector(0, 30 * gameController.pixelRatio);
		bush.parallax.parallaxFactor = new Vector(0.66, 1);

		this.add(sky);
		this.add(field);
		this.add(carpet1);
		this.add(carpet2);
		this.add(clouds);
		this.add(tree);
		this.add(bush);
	}

	private addInstructor() {
		this.instructor = new Instructor();
		this.add(this.instructor);
	}

	private addPlayer() {
		this.player = new Player();
		this.add(this.player);
	}

	private addProgressBar() {
		this.progressBar = new Progress();
		this.add(this.progressBar);
	}

	private addInputField() {
		this.inputField = new InputField();
		this.add(this.inputField);
	}

	private resetInputField() {
		this.inputField.reset();
	}

	private async moveCameraToInstructor() {
		this.disableInput();
		await this.camera.move(new Vector(this.instructor.pos.x, 0), 750, EasingFunctions.EaseInOutCubic);
	}

	private async moveCameraToPlayer() {
		await this.camera.move(new Vector(this.player.pos.x, 0), 750, EasingFunctions.EaseInOutCubic);
		this.enableInput();
	}

	private async onStartState() {
		this.setLevels();
		await this.startCountdown();
		this.updateCurrentLevel();
		this.fsm.go('START_LEVEL');
	}

	private async startCountdown() {
		const num = new ScreenElement();
		num.anchor = Vector.Half;
		num.pos = this.engine.worldToScreenCoordinates(this.camera.pos);
		this.add(num);

		num.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/yoga/UI/countdown/3'), { anchor: Vector.Half });
		await gameController.waitFor(1000);
		num.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/yoga/UI/countdown/2'), { anchor: Vector.Half });
		await gameController.waitFor(1000);
		num.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/yoga/UI/countdown/1'), { anchor: Vector.Half });
		await gameController.waitFor(1000);
		num.kill();
	}

	private updateCurrentLevel() {
		this.currentLevel++;
		this.resetCurrentExpectedInput();
	}

	private async startCurrentLevel() {
		await this.showLevelPoses([...this.levels[this.currentLevel]]);
		await this.moveCameraToPlayer();
	}

	private async onRestartState() {
		await this.moveCameraToInstructor();
		this.resetInputField();
		this.progressBar.reset();
		this.instructor.showStartPose();
		this.player.showStartPose();
		this.setLevels();
		this.currentLevel = 0;
		this.resetCurrentExpectedInput();
		await this.startCountdown();
		this.fsm.go('START_LEVEL');
	}

	private resetCurrentExpectedInput() {
		this.currentExpectedInput = [...this.levels[this.currentLevel]];
	}

	private async onNextLevelState() {
		this.resetInputField();
		this.instructor.showStartPose();
		this.player.showStartPose();
		this.fsm.go('START_LEVEL');
	}

	private async onLevelWinState() {
		this.disableInput();
		await gameController.waitFor(1000);

		if (this.currentLevel === 4) {
			return this.gameOver();
		}

		this.resetInputField();
		this.updateCurrentLevel();
		this.progressBar.progress(this.currentLevel);
		this.soundManager.playChakra();

		if (this.currentLevel === 2 || this.currentLevel === 4) {
			await this.snowDrinkScene();
		} else {
			await this.showWinScene();
		}

		this.fsm.go('NEXT_LEVEL');
	}

	private async showWinScene() {
		this.player.showStartPose();
		await this.moveCameraToInstructor();
		this.instructor.showWinPose();
		await gameController.waitFor(1500);
	}

	private async snowDrinkScene() {
		this.player.showStartPose();
		await gameController.waitFor(500);
		this.player.showDrinksPose();
		await gameController.waitFor(1000);
		await this.moveCameraToInstructor();
		this.instructor.showDrinksPose();
		await gameController.waitFor(1500);
	}

	private async onLevelLooseState() {
		this.disableInput();
		this.player.showLoosePose();
		this.instructor.showLoosePose();
		await gameController.waitFor(500);
		this.resetInputField();
		this.fsm.go('RESTART');
	}

	private checkCurrentLevelInput(type: number) {
		const isWrong = <number>this.currentExpectedInput.shift() !== type;
		const isCompleted = this.currentExpectedInput.length === 0;

		if (isWrong) return this.fsm.go('LEVEL_LOOSE');
		if (isCompleted) return this.fsm.go('LEVEL_WIN');
	}

	private async onStartLevelState() {
		await this.startCurrentLevel();
		this.enableInput();
	}
}
