import { Actor, ActorArgs, CollisionGroup, CollisionType, GameEvent, Shape, Sprite, Vector } from 'excalibur';
import { EVENTS, TRASH_TYPE } from '../enums';
import Trash from './trash';
import { trashCollisionGroup } from '../collisions';
import { CatchTrashEvent } from '../partials/custom-events';
import gameController from '../../../partials/games-controller';
import { res } from '../res';
import { easyTween } from '../../../utils';

export default class Trap extends Actor {
  private successColor!: string;

  constructor(props: ActorArgs, private object: TRASH_TYPE) {
    super({
      ...props,
      width: 70 * gameController.pixelRatio,
      height: 25 * gameController.pixelRatio,
      collisionType: CollisionType.Fixed,
      collisionGroup: CollisionGroup.collidesWith([trashCollisionGroup]),
    });

    const collider =
      object === TRASH_TYPE.RECYCLE4
        ? Shape.Edge(new Vector(-115 * gameController.pixelRatio, 0), new Vector(115 * gameController.pixelRatio, 0))
        : Shape.Edge(new Vector(-29 * gameController.pixelRatio, 0), new Vector(29 * gameController.pixelRatio, 0));
    this.collider.set(collider);

    this.successColor = (() => {
      switch (this.object) {
        case TRASH_TYPE.RECYCLE1:
          return 'red';
        case TRASH_TYPE.RECYCLE2:
          return 'blue';
        case TRASH_TYPE.RECYCLE3:
          return 'green';
        case TRASH_TYPE.RECYCLE4:
          return 'booster';

        default:
          return '';
      }
    })();
  }

  onInitialize() {
    this.addGraphics();
    this.registerEvents();
  }

  private registerEvents() {
    this.on('collisionstart', e => {
      const target = e.other;
      if (target instanceof Trash) {
        if (e.other.isKilled()) return;
        e.other.kill();

        if (target.type === TRASH_TYPE.RECYCLE5) return this.success(target.type);

        if (target.type === TRASH_TYPE.RECYCLE4 && this.object !== TRASH_TYPE.RECYCLE4) {
          this.success(target.type);
          return this.scene.eventDispatcher.emit(EVENTS.BOOST, new GameEvent());
        }

        if (target.type === this.object) {
          return this.success(target.type);
        } else {
          return this.fail(target.type);
        }
      }
    });
  }

  private async successGraphicsAnimation() {
    await easyTween(progress => {
      this.graphics.opacity = progress;
    }, 150);

    await easyTween(progress => {
      this.graphics.opacity = 1 - progress;
    }, 200);
  }

  private async failGraphicsAnimation() {
    await easyTween(progress => {
      this.graphics.opacity = progress;
    }, 200);

    await easyTween(progress => {
      this.graphics.opacity = 1 - progress;
    }, 300);
  }

  private success(type: TRASH_TYPE) {
    this.showSuccess();
    this.scene.eventDispatcher.emit(EVENTS.GOOD_CATCH, new CatchTrashEvent(type));
    this.successGraphicsAnimation();
  }

  private fail(type: TRASH_TYPE) {
    this.showFail();
    this.scene.eventDispatcher.emit(EVENTS.BAD_CATCH, new CatchTrashEvent(type));
    this.failGraphicsAnimation();
  }

  private showSuccess() {
    this.graphics.layers.get('fail').hide();
    this.graphics.layers.get('success').use(<Sprite>res.assets.getFrameSprite(`assets/catch/assets/player-glow_${this.successColor}`), {
      anchor: new Vector(0.5, 1),
      offset: new Vector(0, -18 * gameController.pixelRatio),
    });
  }

  private showFail() {
    this.graphics.layers.get('success').hide();
    this.graphics.layers.get('fail').use(<Sprite>res.assets.getFrameSprite('assets/catch/assets/player-glow_wrong'), {
      anchor: new Vector(0.5, 1),
      offset: new Vector(0, -18 * gameController.pixelRatio),
    });
  }

  private addGraphics() {
    this.graphics.opacity = 0;
    this.graphics.layers.create({
      name: 'success',
      order: 0,
    });

    this.graphics.layers.create({
      name: 'fail',
      order: 0,
    });
  }
}
