import { ScreenElement, Vector } from 'excalibur';
import gameController from '../../../partials/games-controller';
import Chakra from './chakra';

export default class Progress extends ScreenElement {
	onInitialize() {
		this.anchor = Vector.Half;
		this.pos.x = this.scene.engine.worldToScreenCoordinates(this.scene.camera.pos).x;
		this.pos.y = 85 * gameController.pixelRatio;

		this.addChakras();
		this.reset();
	}

	private addChakras() {
		let startAngle = 208;
		let rad = 235 * gameController.pixelRatio;
		let offset = new Vector(0, rad);
		let radOffset = -14;

		for (let i = 0; i < 5; i++) {
			let currentAngle = (Math.PI / 180) * (startAngle + i * radOffset);

			this.addChild(
				new Chakra(
					{
						pos: new Vector(rad * Math.sin(currentAngle) + offset.x, rad * Math.cos(currentAngle) + offset.y),
					},
					i + 1,
				),
			);
		}
	}

	reset() {
		for (const [, chakra] of this.children.entries()) {
			(<Chakra>chakra).disable();
		}
	}

	progress(level: number) {
		for (const [, chakra] of this.children.entries()) {
			if ((<Chakra>chakra).stateId === level) (<Chakra>chakra).activate();
		}
	}
}
