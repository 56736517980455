import {
	Actor,
	CollisionEndEvent,
	CollisionGroup,
	Engine,
	GameEvent,
	ParticleEmitter,
	PreCollisionEvent,
	Sprite,
	Vector
} from 'excalibur';
import { wordsCollisionGroup } from '../collisions';
import Word from './word';
import { EVENTS } from '../enum';
import gameController from '../../../partials/games-controller';
import config from '../config';
import { res } from '../res';
import Sparks from './sparks';

export default class Trap extends Actor {
	private drag = false;
	private initPosition!: Vector;
	private emitter!: ParticleEmitter;
	private activeNotes = 0;

	constructor() {
		super({
			width: 90 * gameController.pixelRatio,
			height: 30 * gameController.pixelRatio,
			collisionGroup: CollisionGroup.collidesWith([wordsCollisionGroup]),
			anchor: new Vector(1, 0.5),
			z: 1,
		});
	}

	onInitialize() {
		const { x, y } = config.trapStartPosition.clone().scaleEqual(gameController.pixelRatio);
		this.initPosition = new Vector(gameController.halfDrawWidth + x, gameController.halfDrawHeight + y);
		this.pos = this.initPosition.clone();

		this.addGraphics();
		this.registerEvents();
		this.addSparks();
	}

	onPreUpdate(_engine: Engine, _delta: number) {
		super.onPreUpdate(_engine, _delta);

		this.changePositionHandler();
	}

	update(engine: Engine, delta: number) {
		super.update(engine, delta);

		const wordFieldHalfHeight = 70 * gameController.pixelRatio;
		this.pos.y = Math.max(Math.min(this.pos.y, this.initPosition.y + wordFieldHalfHeight), this.initPosition.y - wordFieldHalfHeight);
		this.emitter.pos = this.pos.sub(new Vector(95 * gameController.pixelRatio, 0));
	}

	activate() {
		this.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/guitar/ui/pick_dark_logo_glow'), {
			offset: new Vector(3 * gameController.pixelRatio, 0),
			anchor: new Vector(1, 0.5),
		});
	}

	disable() {
		this.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/guitar/ui/pick_dark_logo'), {
			anchor: new Vector(1, 0.5),
		});
	}

	success() {
		this.emitter.isEmitting = true;
	}

	fail() {
		this.emitter.isEmitting = false;
	}

	killStars() {
		this.emitter.kill();
	}

	private registerEvents() {
		this.on('pointerdown', () => (this.drag = true));
		this.on('pointerup', () => (this.drag = false));
		this.on('precollision', e => this.preCollisionEvent(e));
		this.on('collisionend', e => this.collisionEndEvent(e));
	}

	// @ts-ignore
	private addSparks() {
		this.emitter = new Sparks();
		this.scene.add(this.emitter);
	}

	private changePositionHandler() {
		if (!this.drag) return;

		const wordFieldHalfHeight = 70 * gameController.pixelRatio;
		const { lastWorldPos } = gameController.input.pointers.primary;

		this.pos.y = Math.max(Math.min(lastWorldPos.y, this.initPosition.y + wordFieldHalfHeight), this.initPosition.y - wordFieldHalfHeight);
	}

	private addGraphics() {
		this.disable();
	}

	private collisionEndEvent(e: CollisionEndEvent) {
		const target = e.other;

		if (target instanceof Word) {
			this.activeNotes--;
			target.reset();

			if (this.activeNotes === 0) this.disable();
		}
	}

	private preCollisionEvent(e: PreCollisionEvent) {
		const target = e.other;

		if (target instanceof Word) {
			if (!target.activated) {
				if (this.activeNotes === 0) this.activate();
				this.activeNotes++;
				target.activate();
				this.scene.eventDispatcher.emit(EVENTS.SUCCESS, new GameEvent());
			}
		}
	}
}
