<template>
  <div class="wrapper-global-fix concert-outro" id="wrapperglb">
    <!-- push-wrapper -->
    <div class="push-wrapper">
    <!-- container-->
    <div class="container bg-green">
      <TopBar progress="2" />
    </div>
    <!-- /container -->
    <!-- container-->
    <div class="container second-container">
      <div class="wrapper">
        <ImageCdn filename="concert-outro-002.webp" width="1000" height="752" class="centered" />
        <Comment class="concert-guy" text="Спасибо, что выручил!" />
        <ImageCdn filename="concert-outro-003.webp" width="1000" height="752" class="centered" />
        <Comment class="concert-guy" text="Вот, держи яблочный шорли от Самоката, чтобы освежиться после выступления." />
        <Comment class="answer" text="Спасибо, что поддержали меня, было здорово!" />
        <div class="img-block m-t-20 maks-drink-ctm">
          <img src="../assets/img/concert-outro-004.webp" class="" alt="" />
        </div>
        <ImageCdn filename="concert-outro-005.webp" width="1440" height="990" class="dog-jump" />
        <ImageCdn filename="concert-outro-006.webp" width="1000" height="752" class="centered" />
        <Comment class="dog" text="Гав-гав-гав!" />
        <Comment class="answer" text="Что это такое?" />

        <div class="wrapper-wide btn-row">
          <LetoButton @click="next" text="Продолжить" />
        </div>
      </div>
    </div>
    <!-- /container -->
   <div id="recaptcha"
             class="g-recaptcha"
             :data-sitekey="siteKey"
             data-callback="getToken"
             data-size="invisible" />

      <div id="triggerto" ref="triggerto"></div>
    </div>
    <!-- / push-wrapper -->
  </div>
</template>


<!-- <script setup>
import { defineComponent } from 'vue';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import TopBar from '@/components/TopBar.vue';
import LetoButton from '@/components/LetoButton.vue';
import Comment from '@/components/Comment.vue';
import ImageBox from '@/components/ImageBox.vue';
import TextBox from '@/components/TextBox.vue';
import CpBox from '@/components/CpBox.vue';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { usePromocodeStore } from "@/store/promocode";
const promocodeStore = usePromocodeStore();

gsap.registerPlugin(ScrollTrigger);

const router = useRouter();


function next() {
  // window.grecaptcha.reset();
  window.grecaptcha.execute();
  console.log('кнопка нажата');
};
function goTo() {
  console.log('start router');
  router.push({
    path: ROUTES.PROMOCODE_CONCERT,
  });
};

</script> -->
<script lang="js">
import { defineComponent } from 'vue';
import http from 'axios';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import TopBar from '@/components/TopBar.vue';
import LetoButton from '@/components/LetoButton.vue';
import Comment from '@/components/Comment.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import TextBox from '@/components/TextBox.vue';
import CpBox from '@/components/CpBox.vue';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { usePromocodeStore } from "@/store/promocode";
import { SOUND_SCENES } from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';

gsap.registerPlugin(ScrollTrigger);

export default defineComponent({
  name: 'ConcertOutroView',
  components: {
    TopBar,
    LetoButton,
    Comment,
    ImageBox,
    ImageCdn,
    TextBox,
    CpBox,
    soundManager,
  },
  data() {
    return {
      siteKey: "6LdG3dAmAAAAAJOCNKRny-f0gW0s0jsd6tavSbdu",
      observer: null,
    }
  },
  setup() {
    const router = useRouter();
    const promocodeStore = usePromocodeStore();

    return {
      router,
      promocodeStore,
    }
  },
  methods: {
    next() {
      grecaptcha.execute();
      console.log("Click");
      // console.log(this.promocodeStore);
    },
    async goToFinn() {
      this.router.push({
        path: ROUTES.PROMOCODE_CONCERT,
      });
    },
    async playMusic() {
      soundManager.play('crowd', true)
    },
  },
  mounted() {
    console.log(this.promocodeStore.promocode);

    this.playMusic();

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // alert("The target element enters the visible area.");
          exponea.track('summer_samokat', {
            event_action: 'summer_scroll2_concert'
          });
          // alert("Sent data v.2");
        }
      });
    },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );
    this.observer.observe(triggerto);

    grecaptcha.ready(function () {
      console.log('g-ready');
      grecaptcha.render('recaptcha');
    });
    window.getToken = (response) => {
      // console.log(response);
      if (response) {
        http.post('/api/promocode', {
          'token': response
        }).then((res) => {
          var newPormic = res.data.code;
          this.promocodeStore.setPromocode(newPormic);
          console.log(newPormic);
          console.log(this.promocodeStore.promocode);
        });

        this.goToFinn();
      } else {
        alert('no response');
      }
    }
  },
  unmounted() {
    ScrollTrigger.killAll();
  },
});
</script>
<style scoped>
.concert-outro .container {
  background-color: var(--bg-purple-color);
}

.maks-drink-ctm img {
  height: 254px;
}

/* .dog-jump :deep() img {
  height: 495px;
} */
 #recaptcha {
        /* z-index: -10000; */
        visibility: hidden;
    }
</style>
