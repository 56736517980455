import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_useReCaptcha = _resolveComponent("useReCaptcha")!

  return (_openBlock(), _createBlock(_component_useReCaptcha, {
    sitekey: _ctx.siteKey,
    "load-recaptcha-script": true,
    onVerify: _ctx.handleSuccess,
    onError: _ctx.handleError
  }, null, 8, ["sitekey", "onVerify", "onError"]))
}