export enum SCENES {
	YOGA = 'yoga',
	GUITAR = 'guitar',
	WAKE = 'wake',
	CATCH = 'catch',
}

export enum SOUND_SCENES {
	PICNIC_INTRO = 'picnicIntro',
	SURF_INTRO = 'surfIntro',
	YOGA_INTRO = 'yogaIntro',
	CONCERT_INTRO = 'concertIntro',
}

export enum GAME_EVENTS {
	START = 'start',
	GAME_OVER = 'gameOver',
	SOUND_TOGGLE = 'soundToggle',
	LOW_FPS = 'lowFPS',
	SCREEN_ORIENTATION_CHANGED = 'screenOrientationChanged',
}
