import { GameEvent, ScreenElement, Sprite, Vector } from 'excalibur';
import { EVENTS, INPUT_DIRECTION } from '../enums';
import { InputEvent } from '../partials/custom-events';
import { res } from '../res';
import gameController from '../../../partials/games-controller';

export default class InputField extends ScreenElement {
	private left!: ScreenElement;
	private right!: ScreenElement;

	onInitialize() {
		this.pos = new Vector(gameController.halfDrawWidth, gameController.drawHeight - 140 * gameController.pixelRatio);
		this.addLeft();
		this.addRight();
		this.release();
	}

	release() {
		this.left.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/wake/ui/arrows/left'), {
			anchor: Vector.Half,
			offset: new Vector(25 * gameController.pixelRatio, 35 * gameController.pixelRatio),
		});

		this.right.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/wake/ui/arrows/right'), {
			anchor: Vector.Half,
			offset: new Vector(25 * gameController.pixelRatio, 35 * gameController.pixelRatio),
		});
	}

	leftDown() {
		this.left.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/wake/ui/arrows/left_down'), {
			anchor: Vector.Half,
			offset: new Vector(25 * gameController.pixelRatio, 35 * gameController.pixelRatio),
		});
	}

	rightDown() {
		this.right.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/wake/ui/arrows/right_down'), {
			anchor: Vector.Half,
			offset: new Vector(25 * gameController.pixelRatio, 35 * gameController.pixelRatio),
		});
	}

	private addLeft() {
		this.left = new ScreenElement({
			width: 50 * gameController.pixelRatio,
			height: 70 * gameController.pixelRatio,
			pos: new Vector((-120 - 40) * gameController.pixelRatio, 0),
		});

		this.left.on('pointerdown', () => {
			this.leftDown();
			this.scene.eventDispatcher.emit(EVENTS.PLAYER_INPUT_DOWN, new InputEvent(INPUT_DIRECTION.LEFT));
		});
		this.left.on('pointerup', () => {
			this.release();
			this.scene.eventDispatcher.emit(EVENTS.PLAYER_INPUT_UP, new GameEvent());
		});

		this.addChild(this.left);
	}

	private addRight() {
		this.right = new ScreenElement({
			width: 50 * gameController.pixelRatio,
			height: 70 * gameController.pixelRatio,
			pos: new Vector(110 * gameController.pixelRatio, 0),
		});

		this.right.on('pointerdown', () => {
			this.rightDown();
			this.scene.eventDispatcher.emit(EVENTS.PLAYER_INPUT_DOWN, new InputEvent(INPUT_DIRECTION.RIGHT));
		});
		this.right.on('pointerup', () => {
			this.release();
			this.scene.eventDispatcher.emit(EVENTS.PLAYER_INPUT_UP, new GameEvent());
		});

		this.addChild(this.right);
	}
}
