import {ActorArgs, ScreenElement, Sprite, Vector} from 'excalibur';
import {EVENTS} from '../enums';
import {InputEvent} from '../partials/custom-events';
import GameScene from '../../../partials/game-scene';
import {res} from '../res';

export class InputItem extends ScreenElement {
    constructor(args: ActorArgs, public readonly indexId: number) {
        super(args);
    }

    onInitialize() {
        this.addGraphic();
        this.registerEvent();
    }

    private addGraphic() {
        this.graphics.use(<Sprite>res.graphics.ui.getFrameSprite(`assets/yoga/UI/lotus/l_${this.indexId}`), {
            anchor: Vector.Half,
        });
    }

    async activateFx() {
        const fx = new ScreenElement({
            z: this.z,
        });
        fx.graphics.use(<Sprite>res.graphics.ui.getFrameSprite(`assets/yoga/UI/lotus/l_${this.indexId}_color`), {
            anchor: Vector.Half,
        });
        this.addChild(fx);
        await fx.actions.fade(0, 1500).toPromise();
        fx.kill();
    }

    private registerEvent() {
        this.on('pointerdown', () => this.emitInputEvent());
    }

    private emitInputEvent() {
        if (!(<GameScene>this.scene).inputAvailable) return;

        this.activateFx();
        const event = new InputEvent(this.indexId);
        this.scene.eventDispatcher.emit(EVENTS.PLAYER_POSE, event);
    }
}
