
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import BubbleRule5 from '@/components/BubbleRule5.vue';
import GameBar from '@/components/GameBar.vue';
import gameController from "../../games/src/partials/games-controller";
import { SCENES } from "../../games/src/enums";
import { GAME_EVENTS } from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';

let promice;
var text;
export default defineComponent({
    name: 'ConcertGame',
    components: {
        GameBar,
        LetoButton,
        BubbleRule5,
    },
    setup() {
        // const wrppr = ref();
        const router = useRouter();
        return {
            // wrppr,
            router
        }
    },
    created() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent,)) {
            this.textBefore = 'В этой игре есть музыка. Чтобы получилось классное выступление, советуем отключить беззвучный режим или надеть наушники.';
            this.mapImage = "help-rock-game-audio.webp";
            this.textAfter = 'Зажмите на экране медиатор и ловите им правильные ноты.';
            this.mapImage2 = "help-rock-game.webp";
            this.textBottom = 'Игра закончится, когда заполнится лайкометр.';
        } else {
            this.textBefore = 'В этой игре есть музыка. Чтобы получилось классное выступление, советуем отключить беззвучный режим или надеть наушники.';
            this.mapImage = "help-rock-game-audio.webp";
            this.textAfter = 'Управляйте медиатором с помощью стрелок на клавиатуре и ловите им ноты.';
            this.mapImage2 = "help-rock-game.webp";
            this.textBottom = 'Игра закончится, когда заполнится лайкометр.';
        }
    },
    data() {
        return {
            showWrpr: true,
            textBefore: "",
            textAfter: "",
            textBottom: "",
            mapImage: "",
            mapImage2: "",
            exponea: "summer_game_concert",
            // showBar: false,
        };
    },
    methods: {
        // startGame() {
        //     this.showWrpr = false;
        //     // this.showBar = true;
        //     gameController.startCurrentScene();
        // },
        endGame() {
            document.querySelector('canvas')?.classList.remove('show');
            this.router.push({
              path: ROUTES.CONCERT_OUTRO
            });
        }
    },
    async mounted() {
        gameController.startCurrentSceneMusic();
        document.querySelector('canvas')?.classList.add('show');
        // promice = await gameController.prepareScene(SCENES.WAKE);
        // gameController.activateScene(SCENES.WAKE);
        // gameController.startCurrentScene();
        // console.log(promice);
        document.querySelector('canvas')?.addEventListener(GAME_EVENTS.GAME_OVER, () => {
            this.endGame();
        }, {
          once: true
        });

    }, unmounted() {
    },
});
