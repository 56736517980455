import Splash from '../splash';
import { res } from '../../res';
import { Sprite, Vector } from 'excalibur';
import gameController from '../../../../partials/games-controller';

export default class Splash2 extends Splash {
	private bandOffset = new Vector(0, -40 * gameController.pixelRatio);
	private maxOffset = new Vector(120 * gameController.pixelRatio, gameController.halfDrawHeight - 170 * gameController.pixelRatio);
	private frontmanOffset = new Vector(30 * gameController.pixelRatio, gameController.halfDrawHeight - 130 * gameController.pixelRatio);

	addGraphics(): void {
		this.graphics.layers
			.create({
				name: 'band-face',
				order: 0,
			})
			.use(<Sprite>res.graphics.splash2.getFrameSprite('assets/guitar/splash/splash2/band-good-face'));

		this.graphics.layers
			.create({
				name: 'max-face',
				order: 0,
			})
			.use(<Sprite>res.graphics.splash2.getFrameSprite('assets/guitar/splash/splash2/max-good-face'), {
				anchor: new Vector(0.5, 1),
			});

		this.graphics.layers
			.create({
				name: 'frontman-face',
				order: 0,
			})
			.use(<Sprite>res.graphics.splash2.getFrameSprite('assets/guitar/splash/splash2/frontman-good-face'), {
				anchor: new Vector(0.5, 1),
			});
	}

	fail(): void {
		super.fail();
		this.graphics.layers.get('band-face').use(<Sprite>res.graphics.splash2.getFrameSprite('assets/guitar/splash/splash2/band-bad-face'));
		this.graphics.layers.get('max-face').use(<Sprite>res.graphics.splash2.getFrameSprite('assets/guitar/splash/splash2/max-bad-face'), {
			anchor: new Vector(0.5, 1),
		});
		this.graphics.layers.get('frontman-face').use(<Sprite>res.graphics.splash2.getFrameSprite('assets/guitar/splash/splash2/frontman-bad-face'), {
			anchor: new Vector(0.5, 1),
		});
	}

	success(): void {
		super.success();
		this.graphics.layers.get('band-face').use(<Sprite>res.graphics.splash2.getFrameSprite('assets/guitar/splash/splash2/band-good-face'));
		this.graphics.layers.get('max-face').use(<Sprite>res.graphics.splash2.getFrameSprite('assets/guitar/splash/splash2/max-good-face'), {
			anchor: new Vector(0.5, 1),
		});
		this.graphics.layers.get('frontman-face').use(<Sprite>res.graphics.splash2.getFrameSprite('assets/guitar/splash/splash2/frontman-good-face'), {
			anchor: new Vector(0.5, 1),
		});
	}

	process(progress: number): void {
		this.graphics.layers.get('band-face').offset = this.bandOffset.clone().addEqual(new Vector(progress * 40 * gameController.pixelRatio, 0));
		this.graphics.layers.get('max-face').offset = this.maxOffset.clone().addEqual(new Vector(progress * -80 * gameController.pixelRatio, 0));
		this.graphics.layers.get('frontman-face').offset = this.frontmanOffset.clone().addEqual(new Vector(progress * -130 * gameController.pixelRatio, 0));
	}

	reset(): void {
		this.graphics.layers.get('band-face').offset = this.bandOffset;
		this.graphics.layers.get('max-face').offset = this.maxOffset;
		this.graphics.layers.get('frontman-face').offset = this.frontmanOffset;
	}
}
