import GameSound from '../../../partials/game-sound';
import { res } from '../res';

export default class SoundManager extends GameSound<typeof res.sound> {
	constructor() {
		super(res.sound);
	}

	playMusic() {
		this.play('music');
	}

	playSolo() {
		this.play('solo');
	}
}
