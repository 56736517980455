<template>
  <div class="wrapper-global-fix" id="wrapperglb">
    <!-- container-->
    <div class="container promocode-container">
        <div class="wrapper">
                    <ImageCdn class="polaroid-new polaroid-reverse" filename="polaroid-yoga.webp" />
            <div class="promocode-container__header">
                <h1>У вас классно получилось!</h1>
                <p>Держите промокод за отличную тренировку.</p>
            </div>
            <form class="promocode-form">
                <input type="text" class="promocode-input" id="promocode" ref="prmcde" value="МОМЕНТЫЛЕТА" inputmode='none' />
                <!-- bubbble -->
                <div class="bubble" ref="infobbl">
                    Промокод скопирован
                </div>
                <!-- /bubbble -->
            </form>
            <div class="promocode__text-under-input">
                    Используйте промокод в нашем приложении, чтобы получить пять дополнительных карточек в большом розыгрыше от Самоката.
            </div>
            <div class="promocode__text-before-button m-t-36">
               Закажите продукты от Самоката прямо сейчас, чтобы добавить ещё один яркий момент к этому лету. Или продолжите приключение, а промокод сохраните в заметки.
            </div>
            <div class="btn-row">
                <LetoButton @click="next" text="Продолжить" />
            </div>
            <PromocodeFooter />
        </div>
    </div>
    <!-- /container -->
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import TopBar from '@/components/TopBar.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import PromocodeFooter from '@/components/PromocodeFooter.vue';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SOUND_SCENES } from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';

gsap.registerPlugin(ScrollTrigger);
// const input = ref(null);
export default defineComponent({
  name: 'PromoCodeYogaView',
    components: {
        TopBar,
        LetoButton,
        ImageBox,
        ImageCdn,
        PromocodeFooter,
}   ,

    setup() {
        const prmcde = ref();
        const infobbl = ref();
        const router = useRouter();
        return {
            prmcde,
            infobbl,
            router
        }
    },
    methods: {
        async next() {
            await soundManager.stopAll(250);
           this.router.push({
                path: ROUTES.CONCERT
            });
            exponea.track('summer_samokat', {
                event_action: 'summer_yoga_next'
            });
        },
        async playMusic() {
            await soundManager.play('yogaIntro', true);
        },
    },
    mounted() {
        soundManager.play('photo2')
        soundManager.load(SOUND_SCENES.CONCERT_INTRO);
        var pole = this.prmcde;
        // var prmcdetext = this.prmcde.value;
        var bbl = this.infobbl;
        // console.log(prmcdetext);
        pole.addEventListener('click', function () {
            var prmcdetext = pole.value;
            navigator.clipboard.writeText(prmcdetext);
            pole.classList.add('done');
            // bbl.classList.add('active');
            setTimeout(() => {
                pole.classList.remove('done');
                bbl.classList.remove('active');
            }, 2500);
        });
    },
    unmounted() {
        soundManager.stopAll(250);
        ScrollTrigger.killAll();
    },
});
</script>
<style scoped>

    form.promocode-form {
        position: relative;
    }
   .promocode-container {
        position: fixed;
        inset: 0;
        overflow-y: scroll;
        color: var(--text-dark-green);
        background-color:  var(--bg-light-green-color);
    }

    .promocode-container__header {
        margin-top: 24px;
        width: auto;
        /* max-width: 420px; */
        margin-inline: auto;
        margin-bottom: 40px;
        text-align: center;
    }
    .promocode-container__header h1 {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        margin-bottom: 16px;
    }

    .promocode-container__header p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }
    .promocode__text-under-input {
        margin-block-start: 12px;
        margin-block-end: 40px;
        max-width: 320px;
        margin-inline: auto;
        color: #000;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        opacity: 0.5;
    }

    .promocode__text-before-button {
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        /* max-width: 320px; */
        margin-inline: auto;
    }
    .bubble {
        /* position: fixed; */
        position: absolute;
        color: var(--white-color);
        /* bottom: -110px; */
        /* width: 150px; */
        top: 1px;
        left: calc(50% - 110px);
        padding: 16px 22px;
        border-radius: 20px 20px 0 0;
        background-color: var(--pink-color);
        transition: top 0.3s ease;
        z-index: 20;
    }

    .bubble.active {
        /* bottom: 35px; */
        top: -45px;
    }

    .m-t-36 {
        margin-top: 36px;
    }
    @media only screen and (max-width: 600px) {
        .promocode-container__header {
            max-width: 420px;
        }
        .promocode__text-before-button {
            max-width: 320px;
        }
    }

</style>
