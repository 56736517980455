import { GameEvent } from 'excalibur';
import { InputItem } from '../components/input-item';

export class InputEvent extends GameEvent<InputItem> {
	constructor(public indexId: number) {
		super();
	}
}

export class InstructorPoseEvent extends GameEvent<number> {
	constructor(public indexId: number) {
		super();
	}
}
