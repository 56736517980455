<template>
  <div class="wrapper-global-fix finish" ref="wrppr" id="wrapperglb">
   <!-- container-->
      <div class="container" >
          <TopBar progress="3" />
          <ImageCdn filename="finish-001-1.webp" width="390" height="458" class="centered m-t-0 m-b-40" />
      </div>
      <!-- /container -->
      <!-- container-->
      <div class="container">
        <div id="box-image" class="wrapper">
                <Comment class="answer" text="Так ты собака? Ха-ха, ну хватит! Щекотно!" />
                <ImageCdn filename="finish-002.webp" width="1000" height="752" />
                <Comment class="bag" text="Псс… Максим, это я!" />
                <ImageCdn filename="finish-003.webp" class="maks-finish-wow" />
                <Comment class="answer" text="О, привет, Сумка Самоката! Давно не виделись." />
                <Comment class="bag" text="Это точно! Кажется, мой пёс Кат-кат случайно остался в одном из миров, пока мы путешествовали между вселенными." />
            </div>
        </div>
        <!-- /container -->
        <!-- container-->
        <div class="container" >
              <ImageCdn filename="finish-004.webp" width="1440" height="990" class="centered adaptive-first-image-container" />
        </div>
        <!-- /container -->
        <!-- container-->
        <div class="container">
            <div class="wrapper">
                <ImageCdn filename="finish-005.webp" width="1000" height="752" />
                <Comment class="bag" text="Он натренирован находить самые вкусные продукты. Как только Кат-кат учует что-то от Самоката — тут же срывается с места. Пришлось немного побегать..." />
                <Comment class="answer" text="Так вот, почему стала пропадать еда! А он знает толк во вкусных продуктах." />
                <Comment class="bag" text="Надеюсь, пёс не доставил особых проблем?" />
                <ImageCdn filename="finish-006.webp" class="maks-finish-wow" />
                <Comment class="answer" text="Знаешь, Кат-кат, наоборот, добавил много ярких впечатлений в моё лето!" />
                <Comment class="bag" text="Ну, ладно, нам пора. Скоро увидимся!" />
                </div>
            </div>
            <!-- /container -->
            <!-- container-->
            <div class="container" >
                  <ImageCdn filename="finish-007.webp" width="1000" height="752" @click="goToGamesLanding" class="centered portal-image-container" />
            </div>
            <!-- /container -->
            <!-- container-->
            <div class="container">
                <div class="wrapper">
                <ImageCdn filename="finish-008.webp" width="1460" height="1556" />
              <div class="text-block finish-outro-text">
                <h3>Вы разгадали, кто украл вкусные продукты!</h3>
                <p>Но на этом приключения от Самоката не заканчиваются.</p>
                <p>Если хотите наполнить лето яркими моментами — перейдите в чат-бот «Лето впечатлений». Там вас ждут интересные задания от блогеров из комикса и классные подарки.</p>
              </div>
              <div class="wrapper-normal btn-row">
                  <a href="https://app.adjust.com/cfpq1sa?campaign=soberi_leto&adgroup=to_chat_bot&creative=leto_landing&redirect=https%3A%2F%2Ft.me%2Fsmktleto_bot%20" @click="botClick" class="btn-next btn-full" target="_blank">Перейти в чат-бот</a>
              </div>
              <div class="appdownload">
                <a href="https://samokat.go.link/main?showcaseType=MINIMARKET&adj_t=10oehtvh&adj_campaign=rassvet_samokat&adj_adgroup=to_install" @click="appClick" target="_blank">Скачать приложение</a>
              </div>
              <div class="text-block footer-copyright">
                <p>Сделано в <a href="https://groznov.co" target="_blank">GROZNOV</a>®</p>
              </div>
          </div>
      </div>
      <!-- /container -->
  </div>
</template>
<script>
import {defineComponent, ref} from 'vue';
import {useRouter} from "vue-router";
import TopBar from '@/components/TopBar.vue';
import LetoButton from '@/components/LetoButton.vue';
import Comment from '@/components/Comment.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import TextBox from '@/components/TextBox.vue';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import soundManager from '../../games/src/partials/sound-manager';
import {SOUND_SCENES} from "../../games/src/enums";

gsap.registerPlugin(ScrollTrigger);


// const wrppr = ref();
export default defineComponent({
  name: 'GlobalOutroView',
  components: {
    TopBar,
    LetoButton,
    Comment,
    ImageBox,
    ImageCdn,
    TextBox,
    soundManager,
  },
  setup() {
    const router = useRouter();
    const wrppr = ref();
    return {
      router,
      wrppr
    }
  },
  methods: {
    next() {
      // this.router.push({
      //     path: ROUTES.PICNIC_OUTRO_1,
      // });
    },
    onScroll(e) {
      this.windowTop = window.top.scrollY; /* or: e.target.documentElement.scrollTop */
    },
    botClick() {
      // window.location.href = this.botLink;
      exponea.track('summer_samokat', {
        event_action: 'summer_click_bot'
      });
    },
    appClick() {
      window.location.href = this.appLink;
      exponea.track('summer_samokat', {
        event_action: 'summer_click_install'
      });
    },
    goToGamesLanding() {
      window.location.href = "https://game.samokat.ru/?utm_source=summer_samokat&utm_medium=secret_entry&utm_campaign=from_summer_to_game";
      exponea.track('summer_samokat', {
        event_action: 'summer_click_portal'
      });
    },
    scrollPage() {
      //   console.log(window.top.scrollY);
      // this.lastKnownScrollPosition = window.scrollY;

    },
    async playMusic() {
      soundManager.play('concertIntro', true, 0)
      soundManager.crossfade('crowd', 'concertIntro', 0, true, false)
    },
  },
  data() {
    return {
      lastKnownScrollPosition: 0,
      scroll: null,
      appLink: "https://samokat.go.link/main?showcaseType=MINIMARKET&adj_t=10oehtvh&adj_campaign=rassvet_samokat&adj_adgroup=to_install",
      botLink: "https://app.adjust.com/cfpq1sa?campaign=soberi_leto&adgroup=to_chat_bot&creative=leto_landing&redirect=https%3A%2F%2Ft.me%2Fsmktleto_bot%20",
    };
  },
  async mounted() {
    this.playMusic();

    gsap.timeline({
      scrollTrigger: {
        start: 'top top',
        end: '+=70%',
        scroller: '#wrapperglb',
        onUpdate: (self) => {
          soundManager.crossfade('crowd', 'concertIntro', self.progress, true, false)
        }
      }
    })

    // this.wrppr.addEventListener("scroll", this.scrollPage);
  },
  created() {
    // if (/Android/i.test(navigator.userAgent,)) {
    //     this.appLink = 'https://play.google.com/store/apps/details?id=ru.sbcs.store';
    // } else {
    //     this.appLink = 'https://apps.apple.com/ru/app/%D1%81%D0%B0%D0%BC%D0%BE%D0%BA%D0%B0%D1%82-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D0%BE%D0%B2-%D0%B5%D0%B4%D1%8B/id1401500803';
    // }
  },
  unmounted() {
    soundManager.stopAll(250);
    ScrollTrigger.killAll();
    // this.wrppr.removeEventListener('scroll', this.scrollPage);
    // console.log('leave page');
  },
});
</script>
<style scoped>

.finish .container {
  background-color: var(--bg-dark-purple-color);
}

.appdownload {
  width: auto;
  max-width: 420px;
  margin-inline: auto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.finish-outro-text {
  text-align: left;
  margin-block-start: 20px;
  margin-block-end: 0;
  color: var(--white-color);
}

.finish-outro-text h3 {
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 28px;
}

.finish-outro-text p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-block-start: 20px;
}

.finish-outro-text p:first-child {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-block-start: 0;
}

.footer-copyright {
  text-align: left;
}

.footer-copyright p {
  color: var(--white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.appdownload a {
  display: block;
  background-image: url(../assets/img/samokat-logo-mini.svg);
  background-position: left;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  padding: 10px 0 10px 52px;
}

.maks-finish-wow :deep() img {
  height: 254px;
}

.appdownload a {
  text-decoration: none;
}

.appdownload a,
.footer-copyright p a {
  color: var(--white-color);
}

.btn-full {
  width: 100%;
  text-align: center;
}
</style>
