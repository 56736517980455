
import { defineComponent } from 'vue';
import LetoButton from '@/components/LetoButton.vue';
import { globalCookiesConfig, useCookies } from "vue3-cookies"

export default defineComponent({
    name: 'CoockieBar',
    components: {
        LetoButton,
        globalCookiesConfig,
        useCookies,
    },
    data() {

        const { cookies } = useCookies();
        return {
            hideBar: true,
            // cookieAuth: false,
            cookies,
        }
    },
    methods: {
        accept() {
            this.hideBar = false;
            this.cookies.set("authCoocke", "true", "globalCookiesConfig");
        },
        cookiesFunc() {
            // this.cookies.set("authCoocke", "true");
            let cookieAuth = this.cookies.get("authCoocke");
            if (cookieAuth == "false" || cookieAuth == null) {
                this.hideBar = true;
            } else {
                this.hideBar = false;
            }
            // console.log(cookieAuth);
        },
    },
    mounted() {
        this.cookiesFunc();
    }
})
