import GameScene from '../../partials/game-scene';
import CustomLoader from '../../partials/custom-loader';
import { res } from './res';
import SplashScreen from './components/splash-screen';
import { Color, Engine, Input, PossibleStates, Sprite, StateMachine, Vector } from 'excalibur';
import SoundManager from './components/sound';
import Word from './components/word';
import Trap from './components/trap';
import FailCatcher from './components/fail-catcher';
import gameController from '../../partials/games-controller';
import soundManager from '../../partials/sound-manager';
import { EVENTS } from './enum';
import SideScrollEntity from '../../partials/side-scroll-entity';
import Likes from './components/likes';
import config from './config';
import { easyRepeater } from '../../utils';

export default class GuitarGame extends GameScene {
	loader: CustomLoader = new CustomLoader([res.srt, ...Object.values(res.sound), ...Object.values(res.graphics)]);
	soundManager!: SoundManager;
	private abortController!: AbortController;
	private splashScreen!: SplashScreen;
	private likes!: Likes;
	private speed!: number;
	private offset!: number;
	private lastFailTime!: DOMHighResTimeStamp;
	private trap!: Trap;
	private startLoopPromise!: Promise<boolean>;
	private failTimeouts: number[] = [];
	private isGameOver = false;
	private fsm!: StateMachine<
		PossibleStates<{
			start: string;
			states: {
				INIT: { transitions: string[] };
				SUCCESS: { onState: () => void; transitions: string[] };
				START: { onState: () => void; transitions: string[] };
				NEUTRAL: { onState: () => void; transitions: string[] };
				FAIL: { onState: () => void; transitions: string[] };
				FINISH: { onState: () => void; transitions: string[] };
			};
		}>,
		unknown
	>;
	private failCount!: number;

	private static getNotesDuration(bpm: number) {
		const beatSize = 60 / bpm;

		return {
			32: beatSize / 8,
			16: beatSize / 4,
			8: beatSize / 2,
			4: beatSize,
			2: beatSize * 2,
			1: beatSize * 4,
		};
	}

	onInitialize() {
		super.onInitialize();

		this.engine.backgroundColor = Color.fromHex('#2b1333');
		this.soundManager = new SoundManager();
		this.soundManager.soundEnabled = soundManager.soundEnabled;
	}

	async onActivate() {
		super.onActivate();
		this.offset = 0;
		this.speed = 200 * gameController.pixelRatio;
		this.failCount = 0;
		this.fsm = StateMachine.create({
			start: 'INIT',
			states: {
				INIT: {
					transitions: ['START'],
				},
				START: {
					onState: this.onStartState.bind(this),
					transitions: ['NEUTRAL'],
				},
				SUCCESS: {
					onState: this.onSuccessState.bind(this),
					transitions: ['NEUTRAL', 'FINISH'],
				},
				NEUTRAL: {
					onState: this.onNeutralState.bind(this),
					transitions: ['FAIL', 'SUCCESS', 'FINISH'],
				},
				FAIL: {
					onState: this.onFailState.bind(this),
					transitions: ['NEUTRAL', 'FINISH'],
				},
				FINISH: {
					onState: this.onFinishState.bind(this),
					transitions: [],
				},
			},
		});

		this.addSplashScreen();
		this.addInputContainer();
		this.addFailCatcher();
		this.addWords();
		this.addTrap();
		this.addLikeMeter();
	}

	onPreUpdate(_engine: Engine, _delta: number) {
		if (this.isGameOver) return;

		super.onPreUpdate(_engine, _delta);
		this.moveTrap(_engine, _delta);

		let currentProgress = 0;

		if (this.soundManager.isPlaying('loop')) {
			currentProgress = this.soundManager.progress('loop') % <number>this.soundManager.duration('loop');
		}

		if (this.soundManager.isPlaying('music')) {
			currentProgress = (this.soundManager.progress('music') % <number>this.soundManager.duration('music')) + <number>this.soundManager.duration('loop');
		}

		for (const item of this.cullingItems.values()) {
			if (item instanceof Word) {
				// @ts-ignore
				item.move(this.speed * currentProgress);
			}
		}
	}

	async gameOver() {
		this.isGameOver = true;
		this.disableInput();
		await this.soundManager.stopAll(1000);
		super.gameOver();
	}

	async start() {
		super.start();
		this.fsm.go('START');
	}

	poorPerformanceHandler(): void {
		this.likes.disable();
		this.trap.killStars();
	}

	async reload(): Promise<void> {
		// this.abortController?.abort();
		// super.reload();
	}

	startMusic(): void {
		this.soundManager.play('crowd', true);
		this.startLoopPromise = this.soundManager.play('loop', true);
	}

	protected registerEvents() {
		super.registerEvents();

		this.eventDispatcher.on(EVENTS.FAIL, () => {
			this.failTimeouts.push(setTimeout(this.onFail.bind(this), 150));
		});
		this.eventDispatcher.on(EVENTS.SUCCESS, () => {
			this.onSuccess();
			for (const failTimeout of this.failTimeouts) {
				clearTimeout(failTimeout);
			}
		});
		this.eventDispatcher.once(EVENTS.FINISH, () => {
			this.fsm.go('FINISH');
		});
	}

	private async onStartState() {
		this.enableInput();
		this.splashScreen.start();
		this.abortController = new AbortController();
		// @ts-ignore
		this.lastFailTime = performance.now() + res.srt.data[0].startSeconds * 1000;
		await this.startSound(this.abortController.signal);
		this.fsm.go('NEUTRAL');
	}

	private async onSuccessState() {
		this.trap.success();
		this.splashScreen.showSuccess();
		this.soundManager.play('cheerCrowd');
		await gameController.waitFor(5000);
		this.fsm.go('NEUTRAL');
	}

	private async wordsBlink() {
		let opacity = 2;
		for await (const value of easyRepeater(16, 150, () => ++opacity % 2)) {
			for (const word of this.cullingItems.values()) {
				if (word instanceof Word) {
					if (value === 0) {
						word.reset();
					} else {
						word.activate();
					}
				}
			}
		}
	}

	private async onFinishState() {
		this.soundManager.crossfade('solo', 'soloFail', 0, true, false);
		await gameController.waitFor(2750);
		for (const word of this.cullingItems.values()) {
			if (word instanceof Word) {
				word.reset();
				word.blocked = true;
			}
		}
		this.soundManager.play('cheerCrowd');
		this.wordsBlink();
		this.likes.blink();
		await gameController.waitFor(2250);
		this.likes.stop();
		this.splashScreen.stop();
		this.gameOver();
	}

	private onNeutralState() {
		this.resetFail();
		this.splashScreen.success();
		this.likes.start();
		this.trap.fail();
	}

	private async onFailState() {
		this.splashScreen.fail();
		this.likes.stop();
		this.soundManager.play('angryCrowd');
		await gameController.waitFor(5000);
		this.fsm.go('NEUTRAL');
	}

	private resetFail() {
		this.lastFailTime = performance.now();
		this.failCount = 0;
	}

	private addWords() {
		const yOffsetStep = gameController.halfDrawHeight - 176 * gameController.pixelRatio;
		const notesDurations = GuitarGame.getNotesDuration(100);

		// @ts-ignore
		for (const item of res.srt.data) {
			const { startSeconds, endSeconds, text } = item;
			const [offset, note] = text.split('-');

			let duration = Math.floor((endSeconds - startSeconds) * 100) / 100;
			const size = Math.floor(duration / notesDurations[16]);
			duration = size * notesDurations[16];

			const word = new Word(
				(size - 1) * notesDurations[16] * this.speed,
				new Vector(
					-(<number>this.soundManager.duration('loop')) * this.speed -
						(startSeconds + this.offset) * this.speed +
						(gameController.halfDrawWidth - 100 * gameController.pixelRatio),
					yOffsetStep + offset * 35 * gameController.pixelRatio,
				),
				note,
				size,
			);

			this.addToCulling(word);
		}
	}

	private addTrap() {
		this.trap = new Trap();
		this.add(this.trap);
	}

	private addLikeMeter() {
		this.likes = new Likes();
		if (gameController.poorPerformance) {
			this.likes.disable();
		}
		this.add(this.likes);
	}

	private addFailCatcher() {
		this.add(new FailCatcher());
	}

	private addInputContainer() {
		const stringsSprite = <Sprite>res.graphics.ui.getFrameSprite('assets/guitar/ui/strings');
		const strings = new SideScrollEntity(gameController.drawWidth, stringsSprite, 0);
		strings.pos = new Vector(0, gameController.halfDrawHeight - stringsSprite.height / 2 - 34 * gameController.pixelRatio);

		const stringsBgSprite = <Sprite>res.graphics.ui.getFrameSprite('assets/guitar/ui/shadow');
		const stringsBg = new SideScrollEntity(gameController.drawWidth, stringsBgSprite, 0);
		stringsBg.pos = new Vector(0, gameController.halfDrawHeight - stringsBgSprite.height / 2);

		this.add(stringsBg);
		this.add(strings);
	}

	private async onSuccess() {
		if (!this.inputAvailable) return;
		if (this.fsm.in('FINISH')) return;

		if (!this.fsm.in('SUCCESS')) {
			const now = performance.now();
			const lastFailTimeDuration = now - this.lastFailTime;

			if (lastFailTimeDuration >= config.successCoolDown) {
				this.fsm.go('SUCCESS');
			}
		}

		this.soundManager.crossfade('solo', 'soloFail', 0, true, false);
	}

	private onFail() {
		if (!this.inputAvailable) return;
		if (this.fsm.in('FINISH')) return;

		if (!this.fsm.in('FAIL')) {
			const now = performance.now();
			const lastFailTimeDuration = now - this.lastFailTime;

			if (lastFailTimeDuration < config.failTimeToFailState) {
				if (++this.failCount >= config.failCountToFailState) {
					this.fsm.go('FAIL');
				}
			} else {
				this.resetFail();
				this.failCount++;
			}
		}

		!this.fsm.in('SUCCESS') && this.soundManager.crossfade('solo', 'soloFail', 1, true, false);
	}

	private addSplashScreen() {
		this.splashScreen = new SplashScreen();

		this.add(this.splashScreen);
	}

	private async startSound(signal: AbortSignal) {
		this.soundManager.unLoop('loop');
		await this.startLoopPromise;
		if (signal.aborted) return;
		this.soundManager.stop('crowd', 3000);
		this.soundManager.play('music', true);
		this.soundManager.crossfade('solo', 'soloFail', 0, true);
	}

	private moveTrap(engine: Engine, delta: number) {
		if (!this.inputAvailable) return;

		if (engine.input.keyboard.isHeld(Input.Keys.Up) || engine.input.keyboard.isHeld(Input.Keys.W)) {
			this.trap.pos.y -= (500 / 1000) * delta;
		}

		if (engine.input.keyboard.isHeld(Input.Keys.Down) || engine.input.keyboard.isHeld(Input.Keys.S)) {
			this.trap.pos.y += (500 / 1000) * delta;
		}
	}
}
