import AnimationsList from '../../partials/animations-list';
import { Animation, AnimationStrategy } from 'excalibur';
import SpriteSheetAnimation from '../../partials/spritesheet-animation';
import { res } from './res';

type CatchAnim = {
	garbage: Animation;
};

class Animations extends AnimationsList<CatchAnim> {
	constructor() {
		super(res.assets);
	}

	protected getList(): CatchAnim {
		const spriteAnim = new SpriteSheetAnimation(this.spritesheet.data, this.spritesheet.imageSource);

		return {
			garbage: <Animation>spriteAnim.getAnimation('assets/catch/assets/opponent', { strategy: AnimationStrategy.Loop }),
		};
	}
}

const animations = new Animations();

export default animations;
