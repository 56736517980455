export enum INPUT_DIRECTION {
	LEFT = -1,
	RIGHT = 1,
}

export enum EVENTS {
	RESTART = 'restart',
	PLAYER_INPUT_DOWN = 'playerInputDown',
	PLAYER_INPUT_UP = 'playerInputUp',
	TRICK = 'trick',
}
