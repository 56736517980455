import { Entity, GraphicsComponent, Sprite, TransformComponent, Vector } from 'excalibur';
import { res } from '../res';
import gameController from '../../../partials/games-controller';

export class Player extends Entity {
	private transform!: TransformComponent;
	private graphics!: GraphicsComponent;

	constructor() {
		super([new TransformComponent(), new GraphicsComponent()]);

		this.transform = <TransformComponent>this.get(TransformComponent);
		this.graphics = <GraphicsComponent>this.get(GraphicsComponent);
	}

	get pos() {
		return this.transform.pos;
	}

	set pos(v: Vector) {
		this.transform.pos = v;
	}

	onInitialize() {
		this.pos = new Vector(880, 150 * gameController.pixelRatio);

		this.showPose(0);
	}

	showPose(index: number) {
		const xAnchor = (() => {
			switch (index) {
				case 3:
					return 0.539;

				default:
					return 0.5;
			}
		})();

		this.graphics.use(<Sprite>res.graphics.player.getFrameSprite(`assets/yoga/Maxim/maxim${index}`), { anchor: new Vector(xAnchor, 1) });
	}

	showStartPose() {
		this.graphics.use(<Sprite>res.graphics.player.getFrameSprite(`assets/yoga/Maxim/maxim0`), { anchor: new Vector(0.5, 1) });
	}

	showLoosePose() {
		this.graphics.use(<Sprite>res.graphics.player.getFrameSprite(`assets/yoga/Maxim/maxim_loose`), { anchor: new Vector(0.5, 0.8) });
	}

	showWinPose() {
		this.graphics.use(<Sprite>res.graphics.player.getFrameSprite(`assets/yoga/Maxim/maxim_win`), {
			anchor: new Vector(0.5, 1),
		});
	}

	showDrinksPose() {
		this.graphics.use(<Sprite>res.graphics.player.getFrameSprite(`assets/yoga/Maxim/maxim_drinks`), {
			anchor: new Vector(0.5, 1),
		});
	}
}
