<template>
    <div class="info-bubble__wrapper" v-if="hideBubble">
        <div class="info-bubble">
            <h3>Как играть</h3>
            <p>{{ textBefore }}</p>
                <p>{{ textBeforeBefore }}</p>
            <img :src="require(`@/assets/img/${mapImage}`)" alt="" />
            <p>{{ textAfter }}</p>
            <LetoButton @click="startGame" text="Играть" />
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import gameController from "../../games/src/partials/games-controller";

import LetoButton from '@/components/LetoButton.vue';
export default defineComponent({
    name: 'BubbleRule2Top',
    components: {
        LetoButton
    },
    data() {
        return {
            hideBubble: true,
        }
    },
    props: {
        textBefore: String,
        textAfter: String,
        mapImage: String,
        textBeforeBefore: String,
        exponea: String,
    },
    methods: {
        startGame() {
            gameController.startCurrentScene();
            this.hideBubble = false;
            exponea.track('summer_samokat', {
                event_action: this.exponea
            });


        }
    },
    // mounted() {
    //     if (
    //         /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    //             navigator.userAgent,
    //         )
    //     ) {
    //         this.textBefore = 'Чтобы добраться до дома клиента, смахивайте вправо-влево и&nbsp;перепрыгивайте с&nbsp;листка на листок. Если по пути подберёте круассан от Самоката — он перенесёт вас ближе к цели.';
    //     } else {
    //         this.textBefore = 'Чтобы добраться до&nbsp;дома клиента, нажимайте стрелки на&nbsp;клавиатуре и&nbsp;перепрыгивайте с листка на листок. Если по пути подберёте круассан от Самоката — он перенесёт вас ближе к цели.';
    //     }
    // },
})
</script>
<style scoped>
.info-bubble__wrapper {
    position: fixed;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5000;
}

.info-bubble {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /* gap: 32px;  */
    align-items: center;
    justify-content: center;
    padding: 32px 36px 36px 36px;
    width: 300px;
    /* bottom: 25px;
        left: calc(50% - 150px); */
    border-radius: 24px;
    background-color: var(--white-color);
    /* box-shadow: 0 0 0 100vmax rgba(0,0,0,0.4);
        clip-path: inset(-100vmax -100vmax); */
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
    z-index: 1000;
}

.info-bubble h3 {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    margin-block-end: 20px;
}

.info-bubble p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
    margin-block-end: 32px;
}

.info-bubble img {
    margin-block-end: 32px;
}

.info-bubble .btn-next {
    font-size: 16px;
    line-height: 20px;
    padding: 16px 52px;
}
</style>