
import { computed, defineComponent } from 'vue';
import CapchaBoxx from 'vue-recaptcha';

export default defineComponent({
    name: 'CaptchaBox',
    components: {
        // CapchaBoxx,
    },
    setup() {
        const siteKey = computed(() => {
            return '6LdG3dAmAAAAAJOCNKRny-f0gW0s0jsd6tavSbdu';
        });

        const handleError = () => {
            // Do some validation
        };

        const handleSuccess = (response: string) => {
            // Do some validation
        };

        return {
            handleSuccess,
            handleError,
            siteKey,
        };
    }
});
