<template>
  <div class="picnic-wrapper-outro wrapper-global-fix" id="wrapperglb">
        <!-- container-->
        <div class="container">
              <TopBar progress="0" />
        </div>
        <!-- /container -->
        <!-- container -->
        <div class="container outro-blogger-kombucha">
            <div class="wrapper">
                <Comment class="kote" text="Смотрите, мы забыли достать из сумки комбучу от Самоката. Угощайтесь, её хватит на всех." />

                    <ImageCdn filename="image-04-01.webp" width="716" height="536" />
                    <Comment class="kote" text="Держи тоже! Ты здорово нам помог." />
                    <Comment class="answer" text="Это то, что нужно в жару. Спасибо большое!" />
        </div>
        </div>
        <!-- /container -->
        <!-- container -->
        <div class="container">
            <div class="btn-row">
                          <LetoButton @click="next" text="Продолжить" />
            </div>
        </div>
        <!-- /container -->
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import Comment from '@/components/Comment.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import TextBox from '@/components/TextBox.vue';
import TopBar from '@/components/TopBar.vue'; // @ is an alias to /src
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SOUND_SCENES } from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';
gsap.registerPlugin(ScrollTrigger);

export default defineComponent({
  name: 'PicnicOutro2SurfView',
  components: {
    TopBar,
    LetoButton,
    Comment,
    ImageBox,
    ImageCdn,
    TextBox,
    soundManager,
  }, setup() {
        const router = useRouter();
        return {
            router
        }
    },
    methods: {
        next() {
            this.router.push({
                path: ROUTES.PROMOCODE_PICNIC_SURF,
            });
            exponea.track('summer_samokat', {
                event_action: 'summer_picnic_next'
            });
        },
        async playMusic() {
            await soundManager.play('picnicIntro', true);
        },
    },
    mounted() {
        // ScrollTrigger.killAll();
        // this.playMusic();
    },
    unmounted() {
        // soundManager.stopAll(250);
        ScrollTrigger.killAll();
        // console.log('leave page');
    },
});
</script>
<style scoped>
    #wrapperglb.picnic-wrapper-outro {
        background: var(--bg-green-color-new);
    }
    .outro-blogger-kombucha {
        padding-block-start: 34px;
        /* background: rgb(71,207,255);
        background: linear-gradient(180deg, rgba(71,207,255,1) 60%, rgba(142,195,128,1) 100%); */
    }
    .maks-drink-kombucha-bottom {
        background-color: var(--bg-light-green-color);
        /* padding-block-start: 62px;
        padding-block-end: 42px; */
        margin-top: -2px;
    }
    .kombucha-drink {
        max-width: 100%;
        /* width: 450px; */
        margin-inline: auto;
        transform: rotate(-13.86deg);
    }
</style>
