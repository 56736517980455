
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import BubbleRule from '@/components/BubbleRule.vue';
import BubbleRule2Top from '@/components/BubbleRule2Top.vue';
import GameBar from '@/components/GameBar.vue';
import gameController from "../../games/src/partials/games-controller";
import { SCENES } from "../../games/src/enums";
import { GAME_EVENTS } from "../../games/src/enums";
import { SOUND_SCENES } from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';

var text;
export default defineComponent({
    name: 'PicnicGame',
    components: {
        GameBar,
        LetoButton,
        BubbleRule,
        BubbleRule2Top,
    },
    setup() {
        // const wrppr = ref();
        const router = useRouter();
        return {
            // wrppr,
            router
        }
    },
    data() {
        return {
            showWrpr: true,
            textBefore: "",
            textBeforeBefore: "",
            textAfter: "",
            mapImage: "",
            exponea: "summer_game_picnic"
            // showBar: false,
        };
    },
    created() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent,)) {
            this.textBefore = 'Ловите мусор в подходящий по цвету контейнер. Для этого — перетаскивайте его по экрану.';
            this.textBeforeBefore = "";
            this.textAfter = 'Чтобы ловить мусор стало проще — поймайте салфетки от Самоката';
            this.mapImage = "help-picnin-game-mobile.webp";
        } else {
            this.textBefore = 'Помогите ребятам убраться и отсортировать упаковки от еды.';
            this.textBeforeBefore = " Ловите мусор подходящим контейнером с помощью стрелок на клавиатуре.";
            this.textAfter = 'Чтобы стало проще, поймайте салфетки от Самоката.';
            this.mapImage = "help-picnin-game-desktop.webp";
        }
    },
    methods: {
        // startGame() {
        //     this.showWrpr = false;
        //     // this.showBar = true;
        //     gameController.startCurrentScene();
        // },
        endGame() {
            setTimeout(() => {
                // this.showWrpr = true;
                // this.showBar = false;

                document.querySelector('canvas')?.classList.remove('show');
                this.router.push({
                    path: ROUTES.PICNIC_OUTRO_1
                });
            }, 1500);
        }
    },
    async mounted() {
        gameController.startCurrentSceneMusic();
        document.querySelector('canvas')?.classList.add('show');
        // promice = await gameController.prepareScene(SCENES.CATCH);
        // gameController.activateScene(SCENES.CATCH);
        // gameController.startCurrentScene();
        // console.log(promice);
        document.querySelector('canvas')?.addEventListener(GAME_EVENTS.GAME_OVER, () => {
            this.endGame();
        }, {
          once: true
        });
        console.log(this.textBefore);
    },
    unmounted() {
    },
});
