import {
  Actor,
  Animation,
  CollisionType,
  EasingFunctions,
  Engine,
  PossibleStates,
  Sprite,
  StateMachine,
  Vector
} from 'excalibur';
import { res } from '../res';
import { config } from '../config';
import { lerp } from '../../../utils';
import gameController from '../../../partials/games-controller';
import { animations } from '../animations';

let yMoveProgress = 0;
let yMoveDirection = 1;

export default class Player extends Actor {
  fsm!: StateMachine<PossibleStates<{ start: string; states: { INIT: { transitions: string[] }; TRICK: { onState: () => void; transitions: string[] }; FAIL_B: { onState: () => Promise<void>; transitions: string[] }; FAIL_F: { onState: () => Promise<void>; transitions: string[] }; RIDE: { onState: () => void; transitions: string[] } } }>, unknown>;
  private initPos = config.playerStartPosition.clone();

  onInitialize() {
    this.fsm = StateMachine.create({
      start: 'INIT',
      states: {
        INIT: {
          transitions: ['RIDE'],
        },
        TRICK: {
          onState: this.onTrickState.bind(this),
          transitions: ['RIDE'],
        },
        FAIL_F: {
          onState: this.onFailFrontState.bind(this),
          transitions: ['RIDE'],
        },
        FAIL_B: {
          onState: this.onFailBackState.bind(this),
          transitions: ['RIDE'],
        },
        RIDE: {
          onState: this.onRideState.bind(this),
          transitions: ['TRICK', 'FAIL_F', 'FAIL_B'],
        },
      },
    });
    this.pos = this.initPos.scaleEqual(gameController.pixelRatio);
    this.body.collisionType = CollisionType.Fixed;
    this.fsm.go('RIDE');
  }

  update(engine: Engine, delta: number) {
    super.update(engine, delta);

    this.move(delta);
  }

  stop() {
    this.actions.clearActions();
  }

  progressMove(progress: number) {
    const { playerStartPosition, playerPositionXOffset } = config;

    const posOffset = playerPositionXOffset * gameController.pixelRatio * progress;
    this.pos.x = playerStartPosition.x + posOffset;
  }

  async trick() {
    this.fsm.go('TRICK');
    await this.actions.easeBy(Vector.Up.scaleEqual(100), 250, EasingFunctions.EaseOutQuad).toPromise();
    await this.actions.easeBy(Vector.Down.scaleEqual(100), 250, EasingFunctions.EaseInQuad).toPromise();
  }

  fallBack() {
    this.resetBlink();
    this.fsm.go('FAIL_B');
  }

  fallFront() {
    this.resetBlink();
    this.fsm.go('FAIL_F');
  }

  reset() {
    this.pos = config.playerStartPosition;
    this.fsm.go('RIDE');
  }

  blink() {
    this.actions.getQueue().isComplete() &&
    this.actions.repeatForever(async actions => {
      await actions.fade(0, 100).toPromise();
      await actions.fade(1, 100).toPromise();
    });
  }

  resetBlink() {
    this.actions.clearActions();
    this.graphics.opacity = 1;
  }

  private onTrickState() {
    this.vel = Vector.Zero;
    this.graphics.use(<Sprite>res.graphics.player.getFrameSprite('assets/wake/player/trick'), {
      anchor: new Vector(0.5, 1),
    });
    this.hideFoam();
  }

  private async onFailBackState() {
    this.stop();
    this.graphics.use(<Sprite>res.graphics.player.getFrameSprite('assets/wake/player/fail back'), {
      anchor: new Vector(0.5, 1),
    });
    this.showSplash();
  }

  private async onFailFrontState() {
    this.graphics.use(<Sprite>res.graphics.player.getFrameSprite('assets/wake/player/fail front'), {
      anchor: new Vector(0.5, 1),
    });
    this.showSplash();
  }

  private onRideState() {
    this.vel = Vector.Zero;
    this.graphics.use(<Sprite>res.graphics.player.getFrameSprite('assets/wake/player/ride'), {
      anchor: new Vector(0.5, 1),
    });
    this.graphics.layers
      .create({
        name: 'anim',
        order: 0,
        offset: new Vector(-35, -8).scaleEqual(gameController.pixelRatio),
      })
      .use(<Animation>animations.getAnimation('boardTrace'));

    animations.play('boardTrace');
  }

  private async showSplash() {
    const splash = <Animation>animations.getAnimation('splash');

    this.hideFoam();
    this.vel = Vector.Left.scaleEqual(750 * gameController.pixelRatio);

    await gameController.waitFor(100);
    splash.reset();
    splash.play();

    this.graphics.use(splash, {
      anchor: new Vector(0.5, 1),
      offset: new Vector(0, 30).scaleEqual(gameController.pixelRatio),
    });
  }

  private hideFoam() {
    this.graphics.layers.get('anim')?.hide();
  }

  private move(delta: number) {
    if (!this.fsm.in('RIDE')) return;

    yMoveProgress += (delta / 450) * yMoveDirection;
    yMoveProgress = Math.max(Math.min(yMoveProgress, 1), 0);
    yMoveDirection = yMoveProgress === 1 ? -1 : yMoveProgress === 0 ? 1 : yMoveDirection;

    this.pos.y = lerp(-10, 10, yMoveProgress);
  }
}
