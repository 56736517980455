<template>
  <div class="wrapper-global-fix surf-outro" id="wrapperglb">
     <!-- push-wrapper -->
        <div class="push-wrapper">
        <!-- container-->
        <div class="container">
                    <TopBar progress="1"/>
            <div class="img-block m-t-n-30 adaptive-first-image-container">
                <img src="../assets/img/post-surf-001.webp" class="centered" alt="" />
            </div>
        </div>
        <!-- /container -->
        <!-- container -->
        <div class="container surf-first-gradient">
            <div class="wrapper">
                <ImageCdn filename="post-surf-002.webp" width="1000" height="752" />
                <Comment class="stas" text="Ну какова красота! Классные трюки, парень." />
                <ImageCdn filename="post-surf-003.webp" width="1000" height="752" />
                <Comment class="answer" text="Спасибо! Я видел, как что-то столкнуло тебя с доски. Расскажи, что это было?" />
                <ImageCdn filename="post-surf-004.webp" width="" height="" class="maks-show-polaroid"/>

                <Comment class="stas" text="Ах, да! Пока я снимал видео в образе, меня вдруг накрыло большой волной. Я оказался в воде, да ещё и мороженое пропало из рук." />

                <ImageCdn filename="post-surf-005.webp" width="1000" height="752" />


                <Comment class="stas" text="Вот, сам посмотри." />
                <ImageCdn filename="post-surf-006.webp" class="surf-smartphone" width="716" height="1212" />
                
                <Comment class="answer" text="Кажется, тут что-то есть. Виднеется розовый след... И он ведёт прямо к концерту." />
                <div class="img-block  surf-smartphone surf-smartphone__trigger">
                    <img src="../assets/img/post-surf-007.webp" alt="" />

                    <img src="../assets/img/post-surf-finger.webp" class="surf-smartphone-finger" alt="" />
                </div>

                <Comment class="answer" text="Ну конечно! Похититель наверняка отправился на фестиваль за едой." />
                <Comment class="glass-guy" text="Хей, ребята! Не хотите немного перекусить?" />

                <ImageCdn filename="post-surf-008.webp" width="1000" height="752" />

                <Comment class="answer" text="О, это шоколадное мороженое от Самоката? Обожаю его, такое вкусное и сливочное." />

            </div>
        </div>
        <!-- container -->
        <!-- container -->
        <div class="container">
            <div class="btn-row">
                                      <LetoButton @click="next" text="Продолжить" />
            </div>
        </div>
        <!-- /container -->
                        <div id="triggerto" ref="triggerto"></div>
                    </div>
                    <!-- / push-wrapper -->
  </div>
</template>
<script >
import { onUnmounted, defineComponent } from 'vue';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import Comment from '@/components/Comment.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import TextBox from '@/components/TextBox.vue';
import TopBar from '@/components/TopBar.vue'; // @ is an alias to /src
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import soundManager from '../../games/src/partials/sound-manager';
import { SOUND_SCENES } from "../../games/src/enums";


let anim;

export default defineComponent({
  name: 'SurfOutroView',
  components: {
    TopBar,
    LetoButton,
    Comment,
    ImageBox,
    ImageCdn,
    TextBox,
    soundManager,
  },
  setup() {
        const router = useRouter();
        return {
            router,
        }
    },
    data() {
        return {
            observer: null,
        }
    },
    methods: {

        async next() {
             this.router.push({
                path: ROUTES.PROMOCODE_SURF
            });
        }
    },
  mounted() {

        soundManager.play('surfIntro', true);
    //    this.anim = gsap.to(".surf-smartphone-finger", {
    //         scrollTrigger: {
    //             trigger: ".surf-smartphone__trigger",
    //             scroller: "#wrapperglb",
    //             start: "top center", // the default values
    //             end: "bottom center",
    //             scrub: true,
    //             // markers: true,
    //         },
    //         // duration: 2,
    //         right: "-20%",
    //         // opacity: 1,
    //     });
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // alert("The target element enters the visible area.");
                    exponea.track('summer_samokat', {
                        event_action: 'summer_scroll2_lake'
                    });
                    // alert("Sent data v.2");
                }
            });
        },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0,
            }
        );
        this.observer.observe(triggerto);
  },
    unmounted() {
        // this.anim.kill();
        ScrollTrigger.killAll();
    },
});
</script>
<style scoped>
    .surf-outro .container {
        background-color: var( --bg-blue-color-2);
    }

    .surf-smartphone {
        position: relative;
        margin-block: 60px;
    }

    .maks-show-polaroid :deep() img {
        height: 254px;
    }
    .surf-smartphone-finger {
        position: absolute;
        top: 30%;
        height: 350px;
        right: -110px;
    }

    .icecream-like__wrapper {
        margin-block-start: 40px;
        margin-block-end: 20px;
    }

    img.icecream-like {transform: rotate(-4.052deg);}

    @media only screen and (max-width: 500px) {
        .surf-smartphone-finger {
                height: 227px;
        }
    }
</style>
