import GameSound from '../../../partials/game-sound';
import { res } from '../res';

export default class SoundManager extends GameSound<typeof res.sound> {
	constructor() {
		super(res.sound);
	}

	playMusic() {
		this.play('music', true);
	}

	playChakra() {
		this.play('chakra');
	}

	playNote1() {
		this.play('note1');
	}

	playNote2() {
		this.play('note2');
	}

	playNote3() {
		this.play('note3');
	}

	playNote4() {
		this.play('note4');
	}

	playNote5() {
		this.play('note5');
	}
}
