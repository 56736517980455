<template>
  <div class="wrapper-global-fix concert-promocode-bg" id="wrapperglb">
    <!-- container-->
    <div class="container promocode-container">
        <div class="wrapper">
           <ImageCdn class="polaroid-new" filename="polaroid-concert.webp" width="840" height="956" />
            <div class="promocode-container__header">
                <h1>Тайна исчезнувших продуктов почти разгадана</h1>
                <p>Держите промокод за выступление на сцене.</p>
            </div>
            <form class="promocode-form">
                <input type="text" class="promocode-input" id="promocode" ref="prmcde" :value="promocodeStore.promocode" inputmode='none' />
                <!-- bubbble -->
                <div class="bubble" ref="infobbl">
                    Промокод скопирован
                </div>
                <!-- /bubbble -->
            </form>
            <div class="promocode__text-under-input">
                Он даст скидку 100 ₽ при заказе <span class="nobr">от 1000 ₽.</span> Действует до 1 июля 2024 года.
            </div>
            <div class="promocode__text-before-button">
                Заказывайте продукты от Самоката прямо сейчас, чтобы добавить ещё один яркий момент к этому лету. Или продолжайте приключение, а промокод сохраните в заметки.
            </div>
            <div class="btn-row">
                <LetoButton @click="next" text="Продолжить" />
            </div>
            <PromocodeFooter class="white-link" />
        </div>
    </div>
    <!-- /container -->
  </div>
</template>

<script>
import { ref, onMounted, defineComponent } from 'vue';
import { useRouter } from "vue-router";
import http from 'axios';
import { ROUTES } from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import TopBar from '@/components/TopBar.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import PromocodeFooter from '@/components/PromocodeFooter.vue';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SOUND_SCENES } from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';

import { usePromocodeStore } from "@/store/promocode";
// const input = ref(null);
const router = useRouter();

const prmcde = ref();
export default defineComponent({
    name: 'PromoCodeConcertView',
    components: {
        TopBar,
        LetoButton,
        ImageBox,
        ImageCdn,
        PromocodeFooter,
        soundManager,
    },

    setup() {
        const prmcde = ref();
        const infobbl = ref();
        const router = useRouter();
        const promocodeStore = usePromocodeStore();
        return {
            prmcde,
            infobbl,
            router,
            promocodeStore,
        }
    },
    methods: {
        async next() {
            this.router.push({
                path: ROUTES.FINISH
            });
            exponea.track('summer_samokat', {
                event_action: 'summer_concert_next'
            });
        },
        async playMusic() {
          soundManager.play('crowd', true)
        },
    },
    mounted() {
        soundManager.play('photo2')
        var pole = this.prmcde;
        // var prmcdetext = this.prmcde.value;
        var bbl = this.infobbl;
        // console.log(prmcdetext);
        pole.addEventListener('click', function () {
            var prmcdetext = pole.value;
            navigator.clipboard.writeText(prmcdetext);
            pole.classList.add('done');
            // bbl.classList.add('active');
            setTimeout(() => {
                pole.classList.remove('done');
                bbl.classList.remove('active');
            }, 2500);
        });
    },
    unmounted() {
        ScrollTrigger.killAll();
    },
});
</script>

<!-- <script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import TopBar from '@/components/TopBar.vue';
import ImageBox from '@/components/ImageBox.vue';
import CpBox from '@/components/CpBox.vue';
import PromocodeFooter from '@/components/PromocodeFooter.vue';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
// const input = ref(null);
export default defineComponent({
  name: 'PromoCodeConcertView',
    components: {
        TopBar,
        LetoButton,
        ImageBox,
        PromocodeFooter,
        CpBox,
    },

    setup() {
        const prmcde = ref();
        const infobbl = ref();
        const router = useRouter();
        return {
            prmcde,
            infobbl,
            router
        }
    },
    methods: {
        next() {
           this.router.push({
                path: ROUTES.FINISH
            });
        },
    },
    mounted() {
        var pole = this.prmcde;
        console.log(pole);
        // var prmcdetext = this.prmcde.value;
        var bbl = this.infobbl;
        // console.log(prmcdetext);
        pole.addEventListener('click', function () {
            var prmcdetext = pole.value;
            // console.log(prmcdetext);
            navigator.clipboard.writeText(prmcdetext);
            pole.classList.add('done');
            // bbl.classList.add('active');
            setTimeout(() => {
                pole.classList.remove('done');
                // bbl.classList.remove('active');
            }, 2500);
        });
    },
});
</script> -->
<style scoped>
    form.promocode-form {
        position: relative;
    }
    .concert-promocode-bg {
        color: var(--white-color);
        background: linear-gradient(180deg, #93628E 0%, #615575 100%);
    }

    .promocode-container {
        position: fixed;
        inset: 0;
        overflow-y: scroll;
    }
    .promocode-container__header {
        margin-top: 24px;
        width: auto;
        /* max-width: 420px; */
        margin-inline: auto;
        margin-bottom: 40px;
        text-align: center;
    }
    .promocode-container__header h1 {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        margin-bottom: 16px;
    }

    .promocode-container__header p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }
    .promocode__text-under-input {
        margin-block-start: 12px;
        margin-block-end: 40px;
        max-width: 320px;
        margin-inline: auto;
        color: #000;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        opacity: 0.5;
    }

    .concert-promocode-bg .promocode__text-under-input,
    .concert-promocode-bg .promocode__legal-text a {
        color: var(--white-color);
    }

    .promocode__text-before-button {
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        /* max-width: 320px; */
        margin-inline: auto;
    }

    .bubble {
        /* position: fixed; */
        position: absolute;
        color: var(--white-color);
        /* bottom: -110px; */
        /* width: 150px; */
        top: 1px;
        left: calc(50% - 110px);
        padding: 16px 22px;
        border-radius: 20px 20px 0 0;
        background-color: var(--pink-color);
        transition: top 0.3s ease;
        z-index: 20;
    }

    .bubble.active {
        /* bottom: 35px; */
        top: -45px;
    }

     @media only screen and (max-width: 600px) {
        .promocode-container__header {
            max-width: 420px;
        }
        .promocode__text-before-button {
            max-width: 320px;
        }
    }

</style>
