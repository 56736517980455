


import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LetoButton',
    props: {
        text: String,
        toLeft: Boolean,
        toRight: Boolean,
        btnSmall: Boolean,
        btnVerySmall: Boolean,
        disabled: Boolean
    },
});
