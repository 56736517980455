import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d018e03"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn-next", { "to-left": _ctx.toLeft, "to-right": _ctx.toRight, "btn-small": _ctx.btnSmall, "btn-very-small":_ctx.btnVerySmall }]),
    disabled: _ctx.disabled
  }, _toDisplayString(_ctx.text), 11, _hoisted_1))
}