import {
  Actor,
  Circle,
  Color,
  Engine,
  GameEvent,
  GraphicsLayer,
  ParticleEmitter,
  Rectangle,
  Sprite,
  toRadians,
  Vector
} from 'excalibur';
import { res } from '../res';
import gameController from '../../../partials/games-controller';
import { SpriteEntity } from '../../../partials/sprite-entity';
import config from '../config';
import { EVENTS } from '../enum';

export default class Likes extends Actor {
  private particles!: ParticleEmitter;
  private progressBar!: Rectangle;
  private progressBarHeader!: GraphicsLayer;
  private onProgress = false;
  private progress = 0;
  private meterSprite = <Sprite>res.graphics.ui.getFrameSprite('assets/guitar/ui/likemeter');
  private progressBarHeaderSprite!: Circle;

  constructor() {
    super({
      pos: new Vector(gameController.halfDrawWidth - 30 * gameController.pixelRatio, gameController.halfDrawHeight - 340 * gameController.pixelRatio),
    });
  }

  onInitialize() {
    this.addParticles();
    this.addMeter();
  }

  update(engine: Engine, delta: number) {
    super.update(engine, delta);

    if (!this.onProgress) return;

    this.progress += delta / config.progressTime;
    this.progress = Math.min(this.progress, 1);
    this.setProgress();

    if (this.progress >= (config.progressTime - 5000) / config.progressTime) {
      this.scene.eventDispatcher.emit(EVENTS.FINISH, new GameEvent());
    }

    if (this.progress === 1) {
      this.stop();
    }
  }

  async blink() {
  }

  start() {
    this.particles.isEmitting = true;
    this.onProgress = true;
  }

  stop() {
    this.progressBar.opacity = 1;
    this.progressBarHeaderSprite.opacity = 1;
    this.particles.isEmitting = false;
    this.onProgress = false;
  }

  boost() {
  }

  setProgress() {
    this.progressBar.height = this.progress * 185 * gameController.pixelRatio;
    this.progressBarHeader.offset = new Vector(0, (90 + this.progress * -185) * gameController.pixelRatio);
    this.particles.emitRate = 1 + this.progress * 4;
  }

  disable() {
    this.particles.kill();
  }

  private addMeter() {
    const meter = new SpriteEntity();
    this.progressBar = new Rectangle({
      width: 7 * gameController.pixelRatio,
      height: 0,
      color: Color.fromHex('#FF335F'),
    });

    meter.graphics.use(this.meterSprite);
    meter.graphics.layers
      .create({
        name: 'path',
        order: 0,
        offset: new Vector(0, 90).scaleEqual(gameController.pixelRatio),
      })
      .use(this.progressBar, {
        anchor: new Vector(0.5, 1),
      });

    this.progressBarHeader = meter.graphics.layers.create({
      name: 'header',
      order: 0,
      offset: new Vector(0, 90).scaleEqual(gameController.pixelRatio),
    });

    this.progressBarHeaderSprite = new Circle({
      radius: 3.5 * gameController.pixelRatio,
      color: Color.fromHex('#FF335F'),
    });

    this.progressBarHeader.use(this.progressBarHeaderSprite);

    this.addChild(meter);
  }

  private addParticles() {
    this.particles = new ParticleEmitter({
      y: 90 * gameController.pixelRatio,
      particleSprite: <Sprite>res.graphics.ui.getFrameSprite('assets/guitar/ui/heart'),
      radius: 0,
      minVel: 0,
      maxVel: 175,
      minAngle: 0,
      maxAngle: toRadians(180),
      isEmitting: false,
      emitRate: 1,
      fadeFlag: true,
      particleLife: 2000,
      maxSize: 0,
      minSize: 0,
      startSize: 0,
      endSize: 0,
      acceleration: new Vector(0, -400).scaleEqual(gameController.pixelRatio),
    });

    this.addChild(this.particles);
  }
}
