<template>
  <div class="orientation-informer">
    <div class="text">Поверните экран</div>
  </div>
</template>

<script>
export default {
  name: "ChangedOrientationInformer"
}
</script>

<style scoped>
.orientation-informer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5010;
  background-color: #000000;
}

.text {
  font-family: 'Euclid';
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
  transform: rotate(-90deg);
}
</style>
