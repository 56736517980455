import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65b04c37"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-bar__photo-icon" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sound = _resolveComponent("Sound")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["wrapper top-bar", { "global": _ctx.global}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: require(`@/assets/img/photocard.webp`),
        alt: "photocard"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.progress) + " из 3", 1)
    ]),
    _createVNode(_component_Sound)
  ], 2))
}