import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-417d88d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["polaroid-photo img-block", `-${$props.turn}`])
  }, [
    _createElementVNode("img", {
      src: require(`@/assets/img/${$props.img}`),
      alt: ""
    }, null, 8, _hoisted_1)
  ], 2))
}