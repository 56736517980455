import { Actor, Sprite, Vector } from 'excalibur';
import { wordsCollisionGroup } from '../collisions';
import { res } from '../res';
import gameController from '../../../partials/games-controller';

export default class Word extends Actor {
	activated = false;
	blocked = false;
	failed = false;
	private sprite!: Sprite;

	constructor(width: number, private initPos: Vector, private note: string, private size: number) {
		super({
			width,
			height: 34 * gameController.pixelRatio,
			anchor: new Vector(1, 0.5),
			collisionGroup: wordsCollisionGroup,
			pos: initPos,
		});
	}

	onInitialize() {
		this.addGraphics();
		this.registerEvents();
	}

	activate() {
		this.activated = true;
		this.failed = false;
		this.graphics.layers.get('failed')?.hide();
		this.graphics.layers
			.create({
				name: 'active',
				order: 0,
			})
			.use(<Sprite>res.graphics.notes.getFrameSprite(`assets/guitar/plashki/Light-${this.size}`));
	}

	reset() {
		this.activated = false;
		this.failed = false;
		this.graphics.layers.get('failed')?.hide();
		this.graphics.layers.get('active')?.hide();
	}

	fail() {
		this.failed = true;
		this.graphics.layers.get('active')?.hide();
		this.graphics.layers
			.create({
				name: 'failed',
				order: 0,
			})
			.use(<Sprite>res.graphics.notes.getFrameSprite(`assets/guitar/plashki/Shadow-${this.size}`));
	}

	move(progress: number) {
		this.pos.x = this.initPos.x + progress;
	}

	private registerEvents() {
		this.on('enterviewport', () => {
			this.graphics.layers.get('failed')?.hide();
			this.graphics.layers.get('active')?.hide();
			this.activated = false;
			this.failed = false;
			this.blocked = false;
		});
	}

	private addGraphics() {
		this.sprite = <Sprite>res.graphics.notes.getFrameSprite(`assets/guitar/plashki/${this.note}-${this.size}`);
		this.graphics.use(this.sprite);
	}
}
