
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'TextBox',
    setup() {

    },
    props: {
        text: String,
    },
})
