import Splash from '../splash';
import { Sprite, Vector } from 'excalibur';
import { res } from '../../res';
import gameController from '../../../../partials/games-controller';

export default class Splash1 extends Splash {
  private spotlightOffset = new Vector(0, -gameController.halfDrawHeight + 30 * gameController.pixelRatio);
  private lightsOffset = new Vector(-50, -gameController.halfDrawHeight - 125 * gameController.pixelRatio);
  private faceOffset = new Vector(150, 25).scaleEqual(gameController.pixelRatio);

  addGraphics(): void {
    this.graphics.layers
      .create({
        name: 'spotlight',
        order: 0,
      })
      .use(<Sprite>res.graphics.splash1.getFrameSprite('assets/guitar/splash/splash1/spotlight'), {
        anchor: new Vector(0.5, 0),
      });

    this.graphics.layers
      .create({
        name: 'lights',
        order: 0,
      })
      .use(<Sprite>res.graphics.splash1.getFrameSprite('assets/guitar/splash/splash1/lights'), {
        anchor: new Vector(0.5, 0),
      });

    this.graphics.layers
      .create({
        name: 'face',
        order: 0,
      })
      .use(<Sprite>res.graphics.splash1.getFrameSprite('assets/guitar/splash/splash1/max-good-face001'));
  }

  fail(): void {
    super.fail();
    this.graphics.layers.get('face')?.use(<Sprite>res.graphics.splash1.getFrameSprite('assets/guitar/splash/splash1/max-bad-face001'));
  }

  success(): void {
    super.success();
    this.graphics.layers.get('face')?.use(<Sprite>res.graphics.splash1.getFrameSprite('assets/guitar/splash/splash1/max-good-face001'));
  }

  process(progress: number): void {
    this.graphics.layers.get('spotlight').offset = this.spotlightOffset.clone().addEqual(new Vector(progress * 50 * gameController.pixelRatio, 0));
    this.graphics.layers.get('lights').offset = this.lightsOffset.clone().addEqual(new Vector(progress * 100 * gameController.pixelRatio, 0));
    this.graphics.layers.get('face').offset = this.faceOffset.clone().addEqual(new Vector(progress * -150 * gameController.pixelRatio, 0));
  }

  reset(): void {
    this.graphics.layers.get('spotlight').offset = this.spotlightOffset;
    this.graphics.layers.get('lights').offset = this.lightsOffset;
    this.graphics.layers.get('face').offset = this.faceOffset;
  }
}
