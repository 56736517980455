
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Comment',
    setup() {
        
    },
    props: {
        text: String,
        answer: Boolean,
        pMan: Boolean,
        bMan: Boolean,
        dog: Boolean,
        picnicGirl: Boolean,
        kote: Boolean,
    },
})
