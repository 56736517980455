import { Actor, ActorArgs, CollisionType, Engine, Shape, Sprite, Vector } from "excalibur";
import { EVENTS, TRASH_TYPE } from '../enums';
import { trashCollisionGroup } from '../collisions';
import { res } from '../res';
import { random } from '../../../utils';
import gameController from '../../../partials/games-controller';
import { CatchTrashEvent } from '../partials/custom-events';

export default class Trash extends Actor {
	private readonly initType!: TRASH_TYPE;

	constructor(props: ActorArgs, public type: TRASH_TYPE) {
		super({
			...props,
			collisionType: CollisionType.Active,
			collisionGroup: trashCollisionGroup,
			collider: Shape.Circle(15 * gameController.pixelRatio),
			z: 1,
		});

		this.initType = this.type;
		this.acc = Vector.Down.scaleEqual(75 * (this.type % 3));
		this.angularVelocity = random.pickOne([1, -1, 1.5, -1.5, 0.5, -0.5]);
	}

	onInitialize() {
		this.addGraphics();
	}

	boost() {
		this.type = TRASH_TYPE.RECYCLE5;
		this.graphics.use(<Sprite>res.assets.getFrameSprite(`assets/catch/assets/recyclable_5`));
	}

	unboost() {
		this.type = this.initType;
		this.addGraphics();
	}

	update(engine: Engine, delta: number) {
		super.update(engine, delta);

		if ((this.pos.y > this.scene.camera.pos.y) && this.isOffScreen){
			this.kill();
			this.scene.eventDispatcher.emit(EVENTS.BAD_CATCH, new CatchTrashEvent(this.type));
		}
	}

	private addGraphics() {
		this.graphics.use(<Sprite>res.assets.getFrameSprite(`assets/catch/assets/recyclable_${this.type + 1}`));
	}
}
