import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { gsap } from 'gsap'
import { globalCookiesConfig } from "vue3-cookies";
import gameController from "../games/src/partials/games-controller";

const pinia = createPinia()
globalCookiesConfig({
  expireTimes: "2d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None",
});

createApp(App).use(router).use(gsap).use(pinia).mount('#samokat')
// console.log(gameController);