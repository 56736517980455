<template>
  <div class="home wrapper-global-fix" id="wrapperglb">
    <!-- push-wrapper -->
    <div class="push-wrapper">
      <!-- container-->
      <div class="container bg-beige bg-beige-with-gradient">
          <TopBar progress="0" />
          <div class="wrapper phone-interface" ref="phoneInt">
              <img src="https://d8ee5427-d9ac-4e02-972a-fc031e943b6a.selcdn.net/img/celsium.svg" width="258" height="152" class="centered" alt="" />
              <!-- interface-bubbles -->
              <div class="phone-interface-bubbles">
                  <div class="phone-interface-bubble-one">
                      <div>
                          <img src="https://d8ee5427-d9ac-4e02-972a-fc031e943b6a.selcdn.net/img/dj.webp" alt="" />
                          <div class="song-wrapper">
                                <div class="song-line"><span class="scroll-text">Ураганный Шорли — Лето впечатлений &bull; </span></div>
                          </div>
                      </div>
                      <img src="https://d8ee5427-d9ac-4e02-972a-fc031e943b6a.selcdn.net/img/equalizer.svg" alt="" />
                  </div>
                  <div class="phone-interface__bottom">
                    <div class="phone-interface-bubble-two">
                        <img src="https://d8ee5427-d9ac-4e02-972a-fc031e943b6a.selcdn.net/img/mini-sun.svg" alt="" />
                        <div class="phone-interface__termometr">
                            <div class="phone-interface__termometr__header">Без осадков</div>
                            <div class="phone-interface__termometr__digits">
                                <span>Сегодня жарко</span>
                            </div>
                        </div>
                    </div>
                    <div class="phone-interface-bubble-three">
                        <img src="https://d8ee5427-d9ac-4e02-972a-fc031e943b6a.selcdn.net/img/schedule.svg" alt="" />
                        <div class="phone-interface__schedule">
                            <div class="phone-interface__schedule__header">Суббота</div>
                            <span>Нет событий</span>
                        </div>
                    </div>
                  </div>
              </div>
              <!-- /interface-bubbles -->
          </div>
          <TextBox class="wrapper index-slide-text main-text" text="В свой выходной Максим не мог придумать, чем бы таким интересным заняться. И он решил пойти прогуляться в парк." />
          <div class="wrapper-wide paralax-container__index">
              <!-- p-slider__one -->
              <div class="p-slider__one">
                  <!-- p-slider__slide -->
                      <div class="p-slider__slide s1" data-speed="1.2">
                      </div>
                      <!-- /p-slider__slide -->
                          <!-- p-slider__slide -->
                          <div class="p-slider__slide s2" data-speed="1.6">
                          </div>
                          <!-- /p-slider__slide -->
                      <!-- p-slider__slide -->
                      <div class="p-slider__slide s3" data-speed="1.8">
                      </div>
                      <!-- /p-slider__slide -->
                  <!-- p-slider__slide -->
                  <div class="p-slider__slide s4" data-speed="0">
                  </div>
                  <!-- /p-slider__slide -->
                <div class="p-slider__one__bird">
                    <img src="https://d8ee5427-d9ac-4e02-972a-fc031e943b6a.selcdn.net/img/index-slider/005bird.webp" alt="" />
                </div>
                <div class="paralx-trigger-one"></div>

              </div>
              <!-- /p-slider__one -->
          </div>
      </div>
      <!-- /container -->
      <!-- container-->
      <div class="container second-container bg-gradient-biege b-t-35"  ref="wrapperglb">
          <div class="wrapper">
            <Comment class="answer" text="Эх, а так хотелось наполнить лето классными впечатлениями.." />
            <TextBox class="text-dark-green" text="Максим сел на скамейку, чтобы перекусить любимым фруктово-ореховым батончиком от Самоката. Как вдруг..." />
              <div class="img-block mystery-meteor meteor-trigger">
                  <img src="../assets/img/city-001-pre.webp" class="calm-man" alt="" />
                  <img src="../assets/img/city-001.webp" class="wow-man" alt="" />
                  <div class="meteor"></div>
              </div>

              <TextBox class="text-dark-green" text="Случилось что-то странное — батончик внезапно исчез, как будто просто испарился в воздухе. И судя по суматохе вокруг, Максим не единственный, у кого пропала еда." />

              <ImageCdn filename="city-002.webp" width="500" height="375" class="" />

                <Comment class="picnic-girl" text="Куда делась вся еда?" />

                    <Comment class="answer" text="Хм, что-то любопытное происходит, пойду разберусь!" />
          </div>
          <div class="wrapper-wide">
             <ImageCdn filename="image-1-11.webp" width="700" height="480" class="no-round maks-speed-run" />
          </div>
          <div class="wrapper-wide btn-row last-button-row">
              <LetoButton @click="next" text="Продолжить" />
          </div>
      </div>
      <!-- /container -->
                  <div id="triggerto" ref="triggerto"></div>
              </div>
              <!-- / push-wrapper -->
  </div>
</template>

<script>
import { ref, defineComponent, nextTick, onUnmounted } from 'vue';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import TopBar from '@/components/TopBar.vue';
import Comment from '@/components/Comment.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import TextBox from '@/components/TextBox.vue';
import LetoButton from '@/components/LetoButton.vue';
import { SOUND_SCENES } from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';
import PopUpBlock from '@/components/PopUpBlock.vue';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);
//let mm = gsap.matchMedia();
// var route;

export default defineComponent({
  name: 'HomeView',
  components: {
    TopBar,
    LetoButton,
    Comment,
    ImageBox,
    ImageCdn,
    TextBox,
    soundManager,
    PopUpBlock,
  },
  data() {
    return {
        observer: null,
    }
  },
  setup() {
        const router = useRouter();
        const wrapperglb = ref();
        return {
            router,
            wrapperglb,
        }
  },
  methods: {
    async next() {
        await soundManager.stopAll(250);
        this.router.push({
            path: ROUTES.PICNIC,
        });
        exponea.track('summer_samokat', {
                event_action: 'summer_continue_main'
        });


    },
    async playMusic() {
      soundManager.crossfade('cityAmb', 'picnicIntro', 0.3, true)
    }
  },
  mounted() {
    // var phint = this.$refs.phoneInt;
    // console.log(phint);
    this.playMusic();
    // this.loadMusic();
    let mm = gsap.matchMedia();
    var slides = gsap.utils.toArray(".p-slider__slide:not(.s4)");
    this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                // alert("The target element enters the visible area.");
                exponea.track('summer_samokat', {
                    event_action: 'summer_scroll_picnic'
                });
                // alert("Sent data v.2");
            }
        });
    },
        {
            root: null,
            rootMargin: "0px",
            threshold: 0,
        }
    );
        // console.log(wrapperglb);
        this.observer.observe(triggerto);
        // console.log(this.observer);

    // gsap.to('.phone-interface', {
    //   scrollTrigger: {
    //     trigger: ".phone-interface",
    //     scroller: "#wrapperglb",
    //     start: "top top", // the default values
    //     // end: "bottom top",
    //     scrub: true,
    //     // markers: true,
    //   },
    //   // duration: 2,
    //   opacity: 0
    // });
    // gsap.to(".index-slide-text", {
    //   scrollTrigger: {
    //     trigger: ".phone-interface",
    //     scroller: "#wrapperglb",
    //     start: "top top", // the default values
    //     // end: "bottom top",
    //     scrub: true
    //   },
    //   // duration: 2,
    //   opacity: 0
    // });
    //  gsap.to(".p-slider__one__bird", {
    //         scrollTrigger: {
    //             trigger: ".index-slide-text",
    //             scroller: "#wrapperglb",
    //             start: "top 50%", // the default values
    //             end: "bottom top",
    //             scrub: true,
    //             // markers: true,
    //         },
    //         // duration: 2,
    //         // opacity: 0
    //         top: "-300px",
    //         left: "110%",
    //         // duration: 2000,
    //     });
    gsap.to(".mystery-meteor img.calm-man", {
      scrollTrigger: {
        trigger: ".meteor-trigger",
        scroller: "#wrapperglb",
        start: "top 65%", // the default values
        end: "top 65%",
        scrub: true,
        // markers: true,
      },
      // duration: 2,
      opacity: 0
    });
    gsap.to(".meteor", {
      scrollTrigger: {
        trigger: ".meteor-trigger",
        scroller: "#wrapperglb",
        start: "top 80%", // the default values
        end: "bottom 45%",
        scrub: true,
        // markers: true,
      },
      // duration: 2,
      left: "200%",
    //   opacity: 1,
    });
    mm.add("(max-width: 799px)", () => {
      var tl = gsap.timeline({
        scrollTrigger: {
          // stagger: 0.5,
          // trigger: item,
          trigger: ".p-slider__one",
                    scroller: "#wrapperglb",
          // pin: ".p-slider__one__wrapper",
          // pinnedContainer: '.p-slider__one__wrapper',
          // pinType: "fixed",
          // anticipatePin: 1,
          scrub: true,
          start: "top 30%",  // start when top of trigger target hits 50% point of viewport
          // toggleActions: "restart none none reset",
          // end: "bottom 65%",
          end: "+=200%",
          onUpdate: ()=>{
            const progress = 1 - Math.min(ScrollTrigger.positionInViewport(document.querySelector('.s4'), 'top'), .7)
            soundManager.crossfade('cityAmb', 'picnicIntro', progress, true, false)
          }
          // end: "+=100vh",
        //   markers: true,
        //   pin: true,
        //   pinSpacing: false,
        },
      });
        // tl.to(".p-slider__slide.s1", {
        //     marginBlockEnd: "-420px",
        // });
        // tl.to(".p-slider__slide.s2", {
        //     marginBlockEnd: "-550px",
        // });
        //  tl.to(".p-slider__slide.s3", {
        //         marginBlockEnd: "-490px",
        //     });
      tl.to(".p-slider__slide:not(.s4)", {
        // duration: 5,
        // marginBlockEnd: "-" + margin.toFixed(2) + "px",
        // marginBlockEnd: "-51%",
        marginBlockEnd: "-450px",
      });  // mobile setup code here...
    });

    //for desktop
    mm.add("(min-width: 800px)", () => {
      var tl = gsap.timeline({
        scrollTrigger: {
          // stagger: 0.5,
          // trigger: item,
          trigger: ".p-slider__one",
                    scroller: "#wrapperglb",
          // pin: ".p-slider__one__wrapper",
          // pinnedContainer: '.p-slider__one__wrapper',
          // pinType: "fixed",
          // anticipatePin: 1,
          scrub: true,
          start: "top 30%",  // start when top of trigger target hits 50% point of viewport
          // toggleActions: "restart none none reset",
          end: "bottom 80%",
          onUpdate: ()=>{
            const progress = 1 - Math.min(ScrollTrigger.positionInViewport(document.querySelector('.s4'), 'top'), .7)
            soundManager.crossfade('cityAmb', 'picnicIntro', progress, true, false)
          }
        //   end: "+=1000",
          // end: "+=100vh",
        //   markers: true,
          // pin: true,
          // pinSpacing: false,
        },
      });

      tl.to(".p-slider__slide:not(.s4)", {
        // duration: 5,
        // marginBlockEnd: "-" + margin.toFixed(2) + "px",
        // marginBlockEnd: "-51%",
        marginBlockEnd: "-600px",
      }).duration(10);//     // desktop setup code here...
    });

  },
  unmounted() {
        soundManager.stopAll(250);
        ScrollTrigger.killAll();
        // console.log('leave page');
    },
});
</script>

<style scoped>
.phone-interface {
    margin-block-start: 20px;
}

.phone-interface-bubbles {
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content max-content;
    gap: 10px; */
    width: auto;
    /* max-width: 345px; */
    max-width: 355px;
    margin-inline: auto;
    margin-block-start: clamp(20px,5vh,48px);
}

.phone-interface-bubble-one,
.phone-interface-bubble-two,
.phone-interface-bubble-three {
    background-color: var(--white-color);
}

.phone-interface-bubble-one {
    grid-column: 1 / span 2;
    padding: 6px 14px 6px 8px;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 100vw;
}

.phone-interface-bubble-one:hover {
  cursor: pointer;
}

.phone-interface-bubble-one div.song-title {
    /* position: relative; */
  overflow: hidden;
}

.phone-interface-bubble-one div.song-wrapper {
    position: relative;
    overflow: hidden;
    z-index: 100;
}

.phone-interface-bubble-one div.song-wrapper:before {
    content: "";
    position: absolute;
    top: 0;
    left: -5px;
    bottom: 0;
    width: 20px;
    background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0.4) 100%);
z-index: 1000;
}

.phone-interface-bubble-one div.song-wrapper:after {
    content: "";
    position: absolute;
    top: 0;
    right: -5px;
    bottom: 0;
    width: 20px;
    background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,0.4) 50%, rgba(255,255,255,1) 100%);
z-index: 1000;
}

.phone-interface-bubble-one span.scroll-text {
    display: inline-block;
    white-space: nowrap;
    animation: marq 10s linear infinite 1s both;
}

.phone-interface-bubble-one span.scroll-text:after {
    content: "Ураганный Шорли — Лето впечатлений";
}

.phone-interface-bubble-one:hover span.scroll-text {
  animation-play-state: paused;
}

.phone-interface-bubble-one > div {
    display: flex;
    flex-wrap: nowrap;
    /* gap: 10px; */
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.phone-interface-bubble-one > div > img {
  width: 32px;
  height: 32px;
}

.phone-interface__bottom {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 8px;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
}

.phone-interface-bubble-two {
    grid-column: 1 / span 1;
    /* padding: 15px 15px 15px 16px; */
    padding: 12px 20px 12px 12px;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 10px;
    align-items: center;
    border-radius: 16px;
}

.phone-interface__termometr__header,
.phone-interface__schedule__header {
    color: var(--black-color);
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    /* margin-block-end: 4px; */
}

.phone-interface__termometr__digits {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    /* justify-content: space-between; */
    align-items: center;
}

.phone-interface-bubble-three {
    grid-column: 2 / span 1;
    /* padding: 15px 8.5px; */
    padding: 12px 17px 12px 12px;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 6px;
    align-items: center;
    border-radius: 16px;
}

.phone-interface__schedule {
    line-height: 16px;
}

.phone-interface__termometr__digits span,
.phone-interface__schedule span {
    color: var(--gray-from-black);
    font-size: 14px;
    line-height: 14px;
}

.paralax-container__index {
    margin-top: -170px;
}

.p-slider__one {
    position: relative;
    height: 100%;
    /* min-height: 65vh; */
    /* margin-block-end: -15px; */
    /* background: rgb(71,207,255);
background: linear-gradient(180deg, rgba(71,207,255,1) 0%, rgba(142,195,128,1) 100%); */
}

.p-slider__slide {
    position: relative;
    background-position: top center;
    background-repeat: no-repeat;
    /* background-size: cover; */
    height: 570px;
    /* bottom: 0; */
    /* object-fit: cover; */
}

.p-slider__slide:not(.s4) {
    /* margin-block-start: -20%; */
    /* margin-block-end: -43%; */
    /* margin-block-end: -836px; */
}

.p-slider__slide img {
    /* max-width: 2500px; */
    margin-inline: auto;
}

.p-slider__slide.s1 {
    z-index: 70;
    /* margin-block-end: -38%; */
    /* margin-block-end: -760px; */
       margin-block-end: -432px;
    background-image: url(../assets/img/index-slider/001-el-2.webp);
}

.p-slider__slide.s2 {
    z-index: 80;
    /* margin-block-end: -9%; */
    height: 900px;
       margin-block-end: -535px;
    background-image: url(../assets/img/index-slider/002-el-2.webp);
}

.p-slider__slide.s3 {
    z-index: 90;
       /* margin-block-end: -22.5%; */
       margin-block-end: -470px;
    background-position: bottom center;
    background-image: url(../assets/img/index-slider/003-el-2.webp);
}

.p-slider__slide.s4 {
    /* margin-block-start: -23%; */
    background-position: bottom center;
    background-image: url(../assets/img/index-slider/004-el-2.webp);
    z-index: 100;
}

.p-slider__one__bird {
    position: absolute;
    /* top: -106px; */
    top: 55px;
    /* width: 10vw; */
    width: 138px;
    height: 91px;
    left: 55%;
    z-index: 1000;
}

.mystery-meteor {
    position: relative;
    max-width: 500px;
    margin-inline: auto;
    overflow: hidden;
}

.mystery-meteor img.calm-man {
    display: block;
    position: absolute;
    opacity: 1;
}

.meteor {
    position: absolute;
    top: 40%;
    left: -150%;
    width: 150%;
    height: 70%;
    background-image: url(../assets/img/meteor.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    /* opacity: 0; */
}

.bush-line {
    position: absolute;
    top:-160px;
    left: 0;
    width: 100%;
}

.bush-line img {
    /* margin-inline: auto; */
    object-fit: contain;
}

.first-flash-block img {
    position: relative;
    z-index: 100;
}

.main-text{
    color: var(--text-dark-blue);
  z-index: 71;
}

.maks-speed-run {
    max-width: 700px;
    margin-inline: auto;
}


@keyframes marq {
    from {transform: translateX(0%);}
     to {transform: translateX(-51%);}
}

@media only screen and (max-width: 800px) {
    .p-slider__slide {
        height: 390px;
        background-size: auto 520px;
    }

    .p-slider__slide:not(.s4) {
        /* margin-block-start: -20%; */
        /* margin-block-end: -43%; */
        /* margin-block-end: -836px; */
    }

    .p-slider__slide.s1 {
        margin-block-end: -310px;
    }

    .p-slider__slide.s2 {
        height: 500px;
        margin-block-end: -370px;
    }

    .p-slider__slide.s3 {
        margin-block-end: -330px;
    }
}
</style>
