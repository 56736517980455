import { Actor, BoundingBox, Engine, GameEvent, Scene, Vector } from 'excalibur';
import CustomLoader from './custom-loader';
import { GAME_EVENTS } from '../enums';
import GameSound from './game-sound';

export default abstract class GameScene extends Scene {
	abstract loader: CustomLoader;
	inputAvailable!: boolean;
	isStarted!: boolean;
	abstract soundManager: GameSound<any>;
	protected cullingItems!: Map<number, Actor>;

	constructor() {
		super();

		this.camera.pos = Vector.Zero;
	}

	start() {
		this.isStarted = true;
	}

	public abstract startMusic(): void;

	public abstract poorPerformanceHandler(): void;

	onInitialize() {
		this.registerEvents();
	}

	onActivate() {
		this.isStarted = false;
		this.cullingItems = new Map();
		this.disableInput();
	}

	public enableInput() {
		this.inputAvailable = true;
	}

	public disableInput() {
		this.inputAvailable = false;
	}

	public async prepare() {
		return await this.preload();
	}

	public gameOver() {
		this.engine.eventDispatcher.emit(GAME_EVENTS.GAME_OVER, new GameEvent());
	}

	onPreUpdate(_engine: Engine, _delta: number) {
		super.onPreUpdate(_engine, _delta);

		const { viewport } = this.camera;

		for (const item of Array.from(this.cullingItems.values())) {
			this.culling(viewport, item);
		}
	}

	addToCulling(en: Actor) {
		en.active = false;
		this.cullingItems.set(en.id, en);
	}

	removeFromCulling(en: Actor) {
		this.cullingItems.has(en.id) && this.cullingItems.delete(en.id);
	}

	protected registerEvents() {
		this.eventDispatcher.on(GAME_EVENTS.GAME_OVER, () => this.gameOver());
		this.eventDispatcher.on(GAME_EVENTS.LOW_FPS, this.poorPerformanceHandler.bind(this));
	}

	protected async preload() {
		await this.loader.load();
	}

	private culling(viewport: BoundingBox, en: Actor) {
		const entityBb = en.graphics.localBounds.translate(en.pos);

		if (en.isOffScreen && en.active) this.remove(en);

		if (viewport.intersect(entityBb) && (!en.active || !en.scene)) this.add(en);
	}
}
