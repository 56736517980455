import {createMemoryHistory, createRouter, createWebHistory, RouteRecordRaw, RouterOptions} from 'vue-router'
import {ROUTES} from "@/preroutes";
import CoverPage from '../views/CoverPage.vue'
import HomeView from '../views/HomeView.vue'
import PicnicView from '../views/PicnicView.vue'
import PicnicOutro1View from '../views/PicnicOutro1View.vue'
import PicnicOutro2YogaView from '../views/PicnicOutro2YogaView.vue'
import PicnicOutro2SurfView from '../views/PicnicOutro2SurfView.vue'
import SurfView from '../views/SurfView.vue'
import SurfOutroView from '../views/SurfOutroView.vue'
import PromoCodePicnicToYogaView from '../views/PromoCodePicnicToYogaView.vue'
import PromoCodePicnicToSurfView from '../views/PromoCodePicnicToSurfView.vue'
import PromoCodeSurfView from '../views/PromoCodeSurfView.vue'
import PromoCodeConcertView from '../views/PromoCodeConcertView.vue'
import PromoCodeYogaView from '../views/PromoCodeYogaView.vue'
import YogaGame from '../views/YogaGame.vue'
import SurfGame from '../views/SurfGame.vue'
import ConcertGame from '../views/ConcertGame.vue'
import PicnicGame from '../views/PicnicGame.vue'
import YogaView from '../views/YogaView.vue'
import YogaOutroView from '../views/YogaOutroView.vue'
import ConcertView from '../views/ConcertView.vue'
import ConcertOutroView from '../views/ConcertOutroView.vue'
import GlobalOutroView from '../views/GlobalOutroView.vue'
import LoaderView from '@/views/LoaderView.vue'

const routes = <RouteRecordRaw[]>[
    {
        path: ROUTES.INDEX,
        name: 'home',
        component: CoverPage
    }, {
        path: ROUTES.CITY,
        name: 'city',
        component: HomeView
    },
    {
        path: ROUTES.PICNIC,
        name: 'picnic',
        component: PicnicView
    },
    {
        path: ROUTES.PICNIC_OUTRO_1,
        name: 'picnic-outro',
        component: PicnicOutro1View,
    },
    {
        path: ROUTES.PICNIC_OUTRO_2_YOGA,
        name: 'picnic-outro-2-yoga',
        component: PicnicOutro2YogaView,
    },
    {
        path: ROUTES.PICNIC_OUTRO_2_SURF,
        name: 'picnic-outro-2-surf',
        component: PicnicOutro2SurfView,
    },
    {
        path: ROUTES.SURF,
        name: 'surf',
        component: SurfView,
    },
    {
        path: ROUTES.SURF_OUTRO,
        name: 'surf-outro',
        component: SurfOutroView,
    },
    {
        path: ROUTES.PROMOCODE_PICNIC_YOGA,
        name: 'promocode-picnic-yoga',
        component: PromoCodePicnicToYogaView,
        meta: {transition: 'flash'},
    },
    {
        path: ROUTES.PROMOCODE_PICNIC_SURF,
        name: 'promocode-picnic-surf',
        component: PromoCodePicnicToSurfView,
        meta: {transition: 'flash'},
    },
    {
        path: ROUTES.PROMOCODE_SURF,
        name: 'promocode-surf',
        component: PromoCodeSurfView,
        meta: {transition: 'flash'},
    },
    {
        path: ROUTES.PROMOCODE_CONCERT,
        name: 'promocode-concert',
        component: PromoCodeConcertView,
        meta: {transition: 'flash'},
    },
    {
        path: ROUTES.PROMOCODE_YOGA,
        name: 'promocode-yoga',
        component: PromoCodeYogaView,
        meta: {transition: 'flash'},
    },
    {
        path: ROUTES.YOGA_GAME,
        name: 'yoga-game',
        component: YogaGame,
    },
    {
        path: ROUTES.CONCERT_GAME,
        name: 'concert-game',
        component: ConcertGame,
    },
    {
        path: ROUTES.SURF_GAME,
        name: 'surf-game',
        component: SurfGame,
    },
    {
        path: ROUTES.PICNIC_GAME,
        name: 'picnic-game',
        component: PicnicGame,
    },
    {
        path: ROUTES.YOGA,
        name: 'yoga',
        component: YogaView,
    },
    {
        path: ROUTES.YOGA_OUTRO,
        name: 'yoga-outro',
        component: YogaOutroView,
    },
    {
        path: ROUTES.CONCERT,
        name: 'concert',
        component: ConcertView,
    },
    {
        path: ROUTES.CONCERT_OUTRO,
        name: 'concert-outro',
        component: ConcertOutroView,
    },
    {
        path: ROUTES.FINISH,
        name: 'finish',
        component: GlobalOutroView,
    },
    {
        path: ROUTES.LOADER,
        name: 'loader',
        component: LoaderView
    }
]

// const historyMode = process.env.VUE_APP_HISTORY_MODE;

const router = createRouter({
    //  history: createWebHistory(process.env.BASE_URL),
    history: createMemoryHistory(),
    // history: process.env.VUE_APP_HISTORY_MODE,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // document.querySelector('#samokat')?.scroll({ top: 0, left: 0 });
        // always scroll to top
        return {top: 0, left: 0};
    },
})

export default router
