import { Actor, CollisionGroup, GameEvent, PreCollisionEvent, Vector } from 'excalibur';
import { wordsCollisionGroup } from '../collisions';
import Word from './word';
import { EVENTS } from '../enum';
import gameController from '../../../partials/games-controller';

export default class FailCatcher extends Actor {
  constructor() {
    super({
      width: 10 * gameController.pixelRatio,
      height: 140 * gameController.pixelRatio,
      collisionGroup: CollisionGroup.collidesWith([wordsCollisionGroup]),
      pos: new Vector(gameController.halfDrawWidth - 90 * gameController.pixelRatio, gameController.halfDrawHeight - 35 * gameController.pixelRatio),
      anchor: new Vector(1, 1),
    });
  }

  onInitialize() {
    this.registerEvents();
  }

  registerEvents() {
    this.on('precollision', e => this.fail(e));
  }

  private fail(e: PreCollisionEvent) {
    const target = e.other;

    if (target instanceof Word) {
      if (!target.activated && !target.blocked && !target.failed) {
        target.fail();
        this.scene.eventDispatcher.emit(EVENTS.FAIL, new GameEvent());
      }
    }
  }
}
