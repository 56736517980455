import { Sound } from 'excalibur';
import { getAssetUrl } from './utils';

export const soundRes = {
	picnicIntro: new Sound(getAssetUrl('./assets/sound/01_game_intro.mp3')),
	picnicIntroDrums: new Sound(getAssetUrl('./assets/sound/02_game_intro_drums.mp3')),
	surfIntro: new Sound(getAssetUrl('./assets/sound/04_intro_board.mp3')),
	yogaIntro: new Sound(getAssetUrl('./assets/sound/06_intro_yoga.mp3')),
	concertIntro: new Sound(getAssetUrl('./assets/sound/08_intro_concert.mp3')),
	cityAmb: new Sound(getAssetUrl('./assets/sound/fx/city_amb.mp3')),
	photo2: new Sound(getAssetUrl('./assets/sound/fx/polaroid.mp3')),
	prePortal: new Sound(getAssetUrl('./assets/sound/pre_portal.mp3')),
	crowd: new Sound(getAssetUrl('./assets/sound/fx/cheer_crowdloop.mp3')),
	vibration: new Sound(getAssetUrl('./assets/sound/fx/vibration.mp3')),
};
