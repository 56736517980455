
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'PromocodeFooter',
    setup() {
        
    },
    props: {
        whiteLink: Boolean,
    },
})
