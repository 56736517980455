export enum ROUTES {
	INDEX = '/',
	CITY = '/city',
	PICNIC = '/picnic',
	PICNIC_OUTRO_1 = '/picnic-outro',
	PICNIC_OUTRO_2_YOGA = '/picnic-outro-2-yoga',
	PICNIC_OUTRO_2_SURF = '/picnic-outro-2-surf',
	SURF = '/surf',
	SURF_OUTRO = '/surf-outro',
	PROMOCODE_PICNIC_YOGA = '/promocode-picnic-yoga',
	PROMOCODE_PICNIC_SURF = '/promocode-picnic-surf',
	PROMOCODE_YOGA = '/promocode-yoga',
	PROMOCODE_SURF = '/promocode-surf',
	PROMOCODE_CONCERT = '/promocode-concert',
	YOGA_GAME = '/yoga-game',
	SURF_GAME = '/surf-game',
	CONCERT_GAME = '/concert-game',
	PICNIC_GAME = '/picnic-game',
	YOGA = '/yoga',
	YOGA_OUTRO = '/yoga-outro',
	CONCERT = '/concert',
	CONCERT_OUTRO = '/concert-outro',
	FINISH = '/finish',
	LOADER = '/loader'
}
