import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c78fc00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container game-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GameBar = _resolveComponent("GameBar")!
  const _component_BubbleRule5 = _resolveComponent("BubbleRule5")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_GameBar)
    ]),
    _createVNode(_component_BubbleRule5, {
      exponea: _ctx.exponea,
      textBefore: _ctx.textBefore,
      textAfter: _ctx.textAfter,
      textBottom: _ctx.textBottom,
      mapImage: _ctx.mapImage,
      mapImage2: _ctx.mapImage2
    }, null, 8, ["exponea", "textBefore", "textAfter", "textBottom", "mapImage", "mapImage2"])
  ]))
}