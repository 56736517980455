import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["top-bar__sound", { mute: this.sound.muted }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (this.sound.toggle && this.sound.toggle(...args))),
    title: "Звук"
  }, null, 2))
}