import GameScene from '../../partials/game-scene';
import CustomLoader from '../../partials/custom-loader';
import { res } from './res';
import Player from './components/player';
import Boat from './components/boat';
import { SpriteEntity } from '../../partials/sprite-entity';
import {
	Animation,
	Color,
	Engine,
	Entity,
	Input,
	PossibleStates,
	Rectangle,
	ScreenElement,
	Sprite,
	StateMachine,
	Vector
} from 'excalibur';
import InputField from './components/input';
import TrickMeter from './components/trickmeter';
import { EVENTS, INPUT_DIRECTION } from './enums';
import { InputEvent } from './partials/custom-events';
import { config } from './config';
import gameController from '../../partials/games-controller';
import { easeInCirc, easyTween, isBetween, random } from '../../utils';
import SideScrollEntity from '../../partials/side-scroll-entity';
import SoundManager from './components/sound';
import { animations } from './animations';
import Trick from './components/trick';
import soundManager from '../../partials/sound-manager';

export default class Wake extends GameScene {
	loader: CustomLoader = new CustomLoader([...Object.values(res.graphics), ...Object.values(res.sound)]);
	soundManager!: SoundManager;
	private progress!: number;
	private currentLevel!: number;
	private trickMeter!: TrickMeter;
	private playerInput!: INPUT_DIRECTION | null;
	private player!: Player;
	private bgList: Entity[] = [];
	private defaultCameraPos!: Vector;
	private inputField!: InputField;
	private trickScene!: Trick;
	private fsm!: StateMachine<PossibleStates<{
		start: string;
		states: {
			START_LEVEL: { onState: () => void; transitions: string[] };
			INIT: { transitions: string[] };
			TRICK: { onState: () => Promise<void>; transitions: string[] };
			RESTART: { onState: () => void; transitions: string[] };
			NEXT_LEVEL: { onState: () => Promise<void>; transitions: string[] };
			START: { onState: () => Promise<void>; transitions: string[] };
		};
	}>,
		unknown>;
	private boatStartPosition!: Vector;
	private boat!: Boat;

	onInitialize() {
		super.onInitialize();

		this.engine.backgroundColor = Color.fromHex('#95eeff');
		this.soundManager = new SoundManager();
		this.soundManager.soundEnabled = soundManager.soundEnabled;
	}

	onActivate() {
		super.onActivate();
		this.progress = 0;
		this.currentLevel = 0;
		this.fsm = StateMachine.create({
			start: 'INIT',
			states: {
				INIT: {
					transitions: ['START'],
				},
				START: {
					onState: () => this.startGame(),
					transitions: ['START_LEVEL'],
				},
				RESTART: {
					onState: () => this.restartGame(),
					transitions: ['START'],
				},
				NEXT_LEVEL: {
					onState: () => this.onNextLevelState(),
					transitions: ['START_LEVEL'],
				},
				START_LEVEL: {
					onState: () => this.onStartLevelState(),
					transitions: ['RESTART', 'TRICK'],
				},
				TRICK: {
					onState: () => this.onTrickState(),
					transitions: ['NEXT_LEVEL'],
				},
			},
		});

		this.defaultCameraPos = config.cameraDefaultPosition.clone();
		this.boatStartPosition = config.boatStartPosition.clone();
		this.camera.pos = this.defaultCameraPos.scaleEqual(gameController.pixelRatio);

		this.addWater();
		this.addBG();
		this.addBoat();
		this.addPlayer();
		this.addUI();
	}

	update(engine: Engine, delta: number) {
		super.update(engine, delta);

		if (this.inputAvailable) {
			this.setProgress(delta);
			this.checkTrick();
			this.checkFail();
		}
	}

	async gameOver() {
		await this.soundManager.stopAll(1000);
		super.gameOver();
	}

	start() {
		super.start();
		this.fsm.go('START');
	}

	poorPerformanceHandler(): void {
	}

	startMusic(): void {
		this.soundManager.playMusic();
	}

	protected registerEvents() {
		super.registerEvents();

		// @ts-ignore
		this.eventDispatcher.on(EVENTS.PLAYER_INPUT_DOWN, (e: InputEvent) => (this.playerInput = e.direction));
		this.eventDispatcher.on(EVENTS.PLAYER_INPUT_UP, () => (this.playerInput = null));

		this.engine.input.keyboard.on('press', ({ key }) => {
			this.playerInput = key === Input.Keys.A || key === Input.Keys.Left ? INPUT_DIRECTION.LEFT : this.playerInput;
			this.playerInput = key === Input.Keys.D || key === Input.Keys.Right ? INPUT_DIRECTION.RIGHT : this.playerInput;

			if (this.playerInput === INPUT_DIRECTION.LEFT) this.inputField.leftDown();
			if (this.playerInput === INPUT_DIRECTION.RIGHT) this.inputField.rightDown();
		});
		this.engine.input.keyboard.on('release', () => {
			this.inputField.release();
			this.playerInput = null;
		});

		this.eventDispatcher.on(EVENTS.TRICK, () => this.fsm.go('TRICK'));
	}

	private setProgress(delta: number) {
		const randomRatio = random.floating(1, 2.5);

		if (this.playerInput) {
			this.progress += this.playerInput * config.inputValue * (delta / 1000) * randomRatio;
		}

		const progressDirection = this.progress <= 0 ? -1 : 1;
		this.progress += (delta / config.levels[this.currentLevel].failTime) * progressDirection * randomRatio;
		this.progress = Math.max(Math.min(this.progress, 1), -1);

		this.player.progressMove(easeInCirc(this.progress) * progressDirection);
		this.trickMeter.setProgress(easeInCirc(this.progress) * progressDirection);
	}

	private checkTrick() {
		const { trick } = config;

		if (isBetween(-trick, trick, this.progress)) {
			this.trickMeter.trickProgress(this.currentLevel);
			// @ts-ignore
			this.trickMeter.showLine(['green', 'orange', 'red'][this.currentLevel]);
		} else {
			this.trickMeter.resetTrickProgress();
			this.trickMeter.hideLine();
		}
	}

	private async checkFail() {
		const { fail } = config;

		if (this.progress <= -1 + fail || this.progress >= 1 - fail) {
			this.player.blink();
		} else this.player.resetBlink();

		if (this.progress === -1 || this.progress === 1) {
			this.disableInput();
			this.player.stop();
			this.progress === -1 ? this.player.fallBack() : this.player.fallFront();
			this.camera.shake(20 * gameController.pixelRatio, 20 * gameController.pixelRatio, 500);
			this.soundManager.playFail();
			this.soundManager.applyLowPass();
			await gameController.waitFor(2000);
			this.fsm.go('RESTART');
		}
	}

	private async showTrickScene() {
		await gameController.waitFor(config.cameraZoomTime / 2);
		this.trickScene.show(this.currentLevel + 1);
	}

	private async hideTrickScene() {
		await gameController.waitFor(config.cameraZoomTime / 2);
		this.trickScene.hide();
	}

	private async onTrickState() {
		this.disableInput();
		this.addTrickScene();

		if (this.currentLevel === 0) {
			this.boat.throwCan();
			await gameController.waitFor((1000 / 20) * 3);
		}

		await Promise.all([
			this.camera.zoomOverTime(config.cameraZoomValue, config.cameraZoomTime),
			this.camera.move(this.player.pos, config.cameraZoomTime),
			this.showTrickScene(),
			easyTween(progress => {
				const rate = 1 - 0.75 * progress;
				this.soundManager.playbackRate('music', rate);
			}, config.cameraZoomTime),
		]);
		// @ts-ignore
		for (const [, en] of this.bgList.entries()) {
			this.remove(en);
		}
		// @ts-ignore
		await this.trickScene.playTrick(`trick${this.currentLevel + 1}`);
		this.soundManager.playTrick();
		this.progress = 0;
		this.trickMeter.reset();
		for (const [, en] of this.bgList.entries()) {
			this.add(en);
		}
		await Promise.all([
			this.camera.zoomOverTime(1, config.cameraZoomTime),
			this.camera.move(this.defaultCameraPos, config.cameraZoomTime),
			this.hideTrickScene(),
			easyTween(progress => {
				const rate = 0.25 + 0.75 * progress;
				this.soundManager.playbackRate('music', rate);
			}, config.cameraZoomTime),
		]);

		this.remove(this.trickScene);
		this.fsm.go('NEXT_LEVEL');
	}

	private addPlayer() {
		this.player = new Player();
		this.add(this.player);

		this.bgList.push(this.player);
	}

	private addBoat() {
		const wave = new SpriteEntity();
		wave.pos = new Vector(this.boatStartPosition.x - 300, this.boatStartPosition.y - 30).scaleEqual(gameController.pixelRatio);
		wave.graphics.use(<Animation>animations.getAnimation('wave'));
		animations.play('wave');
		this.add(wave);

		this.boat = new Boat();
		this.add(this.boat);

		const boatTrace = new SpriteEntity();
		boatTrace.pos = new Vector(this.boatStartPosition.x - 60, this.boatStartPosition.y + 20).scaleEqual(gameController.pixelRatio);
		boatTrace.graphics.use(<Animation>animations.getAnimation('boatTrace'));
		animations.play('boatTrace');
		this.add(boatTrace);

		const foam = new SpriteEntity();
		foam.pos = new Vector(this.boatStartPosition.x - 400, this.boatStartPosition.y - 15).scaleEqual(gameController.pixelRatio);
		foam.transform.z = 1;
		foam.graphics.use(<Animation>animations.getAnimation('foam'));
		animations.play('foam');
		this.add(foam);

		this.bgList.push(...[wave, this.boat, boatTrace, foam]);
	}

	private addWater() {
		const water = new SpriteEntity();
		water.graphics.use(
			new Rectangle({
				width: gameController.drawWidth + 100,
				height: gameController.drawHeight,
				color: Color.fromHex('#0a749a'),
			}),
			{
				anchor: new Vector(0.5, 0),
			},
		);
		water.pos = new Vector(0, -175).scaleEqual(gameController.pixelRatio);

		this.add(water);
	}

	private addBG() {
		const sky = new SpriteEntity();
		const skySprite = <Sprite>res.graphics.bg.getFrameSprite('assets/wake/bg/gradient');
		skySprite.scale.x = (gameController.drawWidth + 100 * gameController.pixelRatio) / skySprite.width;
		skySprite.scale.y = (skySprite.height + 100 * gameController.pixelRatio) / skySprite.height;
		sky.graphics.use(skySprite, {
			anchor: new Vector(0.5, 1),
		});
		sky.pos = new Vector(0, -185).scaleEqual(gameController.pixelRatio);

		const clouds = new SpriteEntity();
		clouds.graphics.use(<Sprite>res.graphics.bg.getFrameSprite('assets/wake/bg/bg_lv4'));
		clouds.pos = new Vector(0, -315).scaleEqual(gameController.pixelRatio);

		const trees = new SideScrollEntity(
			gameController.drawWidth,
			<Sprite>res.graphics.bg.getFrameSprite('assets/wake/bg/bg_lv2'),
			-125 * gameController.pixelRatio,
		);
		trees.pos = new Vector(0, -255).scaleEqual(gameController.pixelRatio);
		trees.move();

		const bush = new SideScrollEntity(
			gameController.drawWidth,
			<Sprite>res.graphics.bg.getFrameSprite('assets/wake/bg/bg_lv3'),
			-150 * gameController.pixelRatio,
		);
		bush.pos = new Vector(0, -205).scaleEqual(gameController.pixelRatio);
		bush.move();

		const grass = new SpriteEntity();
		grass.graphics.use(
			new Rectangle({
				width: gameController.drawWidth + 100,
				height: 35 * gameController.pixelRatio,
				color: Color.fromHex('#7EC578'),
			}),
		);
		grass.pos = new Vector(0, -175).scaleEqual(gameController.pixelRatio);

		const sidewalk = new SpriteEntity();
		sidewalk.graphics.use(
			new Rectangle({
				width: gameController.drawWidth + 100,
				height: 8 * gameController.pixelRatio,
				color: Color.fromHex('#D9D9D9'),
			}),
		);
		sidewalk.pos = new Vector(0, -158).scaleEqual(gameController.pixelRatio);

		this.add(sky);
		this.add(clouds);
		this.add(trees);
		this.add(grass);
		this.add(bush);
		this.add(sidewalk);

		this.bgList = [sky, clouds, trees, grass, bush, sidewalk];
	}

	private addUI() {
		this.trickMeter = new TrickMeter({
			time: config.levels[0].time,
			radius: config.levels[0].radius,
		});
		this.add(this.trickMeter);

		this.inputField = new InputField();
		this.add(this.inputField);

		this.bgList.push(...[this.trickMeter, this.inputField]);
	}

	private addTrickScene() {
		this.trickScene = new Trick();
		this.add(this.trickScene);
	}

	private async startCountdown() {
		const num = new ScreenElement();
		num.anchor = Vector.Half;
		num.pos = this.engine.worldToScreenCoordinates(this.camera.pos).add(new Vector(0, gameController.halfDrawHeight - 115 * gameController.pixelRatio));
		this.add(num);

		num.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/wake/ui/countdown/3'), { anchor: num.anchor });
		await gameController.waitFor(1000);
		num.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/wake/ui/countdown/2'), { anchor: num.anchor });
		await gameController.waitFor(1000);
		num.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/wake/ui/countdown/1'), { anchor: num.anchor });
		await gameController.waitFor(1000);
		num.kill();
	}

	private async startGame() {
		this.soundManager.playStart();
		await this.startCountdown();
		this.fsm.go('START_LEVEL');
	}

	private restartGame() {
		this.soundManager.disableLowPass();
		this.progress = 0;
		this.trickMeter.reset();
		this.player.reset();
		this.fsm.go('START');
	}

	private onNextLevelState() {
		this.currentLevel++;

		if (this.currentLevel >= config.levels.length) return this.gameOver();
		this.trickMeter.setConfig(config.levels[this.currentLevel]);
		this.fsm.go('START_LEVEL');
	}

	private onStartLevelState() {
		this.inputAvailable = true;
	}
}
