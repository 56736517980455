import CustomLoader from './custom-loader';
import { GAME_EVENTS, SOUND_SCENES } from '../enums';
import type { Loadable } from 'excalibur';
import { soundRes } from '../sound-res';
import GameSound from './game-sound';

class SoundManager extends GameSound<typeof soundRes> {
	private assetsMap = new Map<SOUND_SCENES, Loadable<any>[]>([
		[SOUND_SCENES.PICNIC_INTRO, [soundRes.picnicIntro, soundRes.picnicIntroDrums, soundRes.cityAmb, soundRes.photo2, soundRes.vibration]],
		[SOUND_SCENES.SURF_INTRO, [soundRes.surfIntro]],
		[SOUND_SCENES.YOGA_INTRO, [soundRes.yogaIntro]],
		[SOUND_SCENES.CONCERT_INTRO, [soundRes.concertIntro, soundRes.prePortal, soundRes.crowd]],
	]);
	private loaders = new Map<SOUND_SCENES, CustomLoader>();

	constructor() {
		super(soundRes);
	}

	load(scene: SOUND_SCENES) {
		const sceneLoader = this.loaders.get(scene);

		if (sceneLoader && sceneLoader.isLoaded()) {
			return Promise.resolve();
		}

		return new CustomLoader(this.assetsMap.get(scene)).load();
	}

	toggle(): boolean {
		this.soundEnabled = !this.soundEnabled;

		window.dispatchEvent(
			new CustomEvent(GAME_EVENTS.SOUND_TOGGLE, {
				detail: {
					soundEnabled: this.soundEnabled,
				},
			}),
		);

		return this.soundEnabled;
	}
}

const soundManager = new SoundManager();

export default soundManager;
