<template>
  <div id="wrapperglb" class="wrapper-global-fix concert">
    <!-- push-wrapper -->
    <div class="push-wrapper">
      <!-- container-->
      <div class="container">
        <TopBar progress="2"/>
        <ImageCdn class="centered adaptive-first-image-container" width="1426" height="1016" filename="concert-001.webp"/>
      </div>
      <!-- /container -->
      <!-- container-->
      <div class="container">
        <div class="wrapper">
          <ImageCdn filename="concert-002.webp" width="1000" height="752" />
          <Comment class="answer" text="Хмм… А почему никто не выступает?"/>
          <ImageCdn filename="concert-003.webp" width="1000" height="752" />
          <Comment class="answer" text="О, это же «Ураганный Шорли»! Я знаю все песни этой группы наизусть!"/>
          <div class="img-block m-t-20">
            <img alt="" class="maks-wave" src="../assets/img/concert-004.webp"/>
          </div>

          <Comment class="answer" text="Кхм-кхм… Всем привет! Скажите, вы не видели здесь что-нибудь странное?"/>

          <ImageCdn filename="concert-005.webp" width="1000" height="752" />
          <Comment class="concert-guy" text="Ещё как видели... Странно — это мягко сказано."/>
          <ImageCdn filename="concert-006.webp" width="1000" height="640" />
          <Comment class="concert-guy" text="Мы пришли в гримёрку немного передохнуть."/>

          <ImageCdn filename="concert-007.webp" width="1000" height="640"/>
          <Comment class="concert-guy" text="Но в углу комнаты услышали странные чавкания — как будто кто-то ест."/>
          <ImageCdn filename="concert-008.webp" width="1000" height="640" />
          <Comment class="concert-guy"
                   text="В углу сидел странный зверь! Мы очень напугались и быстро выбежали из гримёрки."/>
          <ImageCdn filename="concert-009.webp" width="1000" height="640" />
          <Comment class="answer" text="Получается, тот зверь до сих пор там?"/>
          <Comment class="concert-guy" text="Да, но нам совсем некогда с этим разбираться."/>
          <ImageCdn filename="concert-010.webp" width="1000" height="752" />

          <Comment class="concert-guy"
                   text="Наш гитарист так испугался, что его руки до сих пор дрожат и не могут взять ни один аккорд. А нам уже вот-вот выходить на сцену."/>
          <Comment class="answer"
                   text="Эм… Я немного играю на гитаре и знаю все ваши песни… Если нужно, я… Кхм… Могу помочь!"/>
          <ImageCdn filename="concert-011.webp" width="1000" height="752" />
          <Comment class="concert-guy" text="Ты бы очень нас выручил!"/>
          <ImageCdn filename="concert-012.webp" width="1780" height="998"/>

          <div class="img-block m-t-20 maks-play-on-guitar">
            <img alt="" class="" src="../assets/img/concert-013.webp"/>
          </div>
          <div class="btn-row">
            <button class="btn-next" @click="next">Выступить на сцене</button>
          </div>
        </div>
      </div>
      <!-- /container -->
      <PopUpBlock image="ball_01.webp" text="Недалеко обнаружено интересное событие!"/>
      <div id="triggerto" ref="triggerto"></div>
    </div>
    <!-- / push-wrapper -->
  </div>
</template>
<script>
import {defineComponent} from 'vue';
import {useRouter} from "vue-router";
import {ROUTES} from "@/preroutes";
import TopBar from '@/components/TopBar.vue';
import LetoButton from '@/components/LetoButton.vue';
import Comment from '@/components/Comment.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import TextBox from '@/components/TextBox.vue';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gameController from "../../games/src/partials/games-controller";
import {SCENES} from "../../games/src/enums";
import {GAME_EVENTS} from "../../games/src/enums";
import {SOUND_SCENES} from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';
import PopUpBlock from '@/components/PopUpBlock.vue';
import {useLoaderStore} from "@/store";

gsap.registerPlugin(ScrollTrigger);

let promice;
// let isLoaded = false;
export default defineComponent({
  name: 'ConcertView',
  components: {
    TopBar,
    LetoButton,
    Comment,
    ImageBox,
    ImageCdn,
    TextBox,
    soundManager,
    PopUpBlock,
  },
  data() {

    return {
      isLoaded: false,
      observer: null,
    }
  },
  setup() {
    const loader = useLoaderStore()
    const router = useRouter();
    return {
      router,
      loader
    }
  },
  methods: {
    async next() {
      await soundManager.stopAll(250);
      exponea.track('summer_samokat', {
        event_action: 'summer_game_instruction_concert'
      });
      if (this.isLoaded) {
        this.router.push({
          path: ROUTES.CONCERT_GAME,
        });
      } else {
        this.router.push({
          path: ROUTES.LOADER
        })
      }
    }
  },
  async mounted() {
    soundManager.play('concertIntro', true);
    this.loader.scene = SCENES.GUITAR
    this.loader.route = ROUTES.CONCERT_GAME
    this.loader.promise = gameController.prepareScene(SCENES.GUITAR)
    this.loader.promise.then(() => {
      gameController.activateScene(SCENES.GUITAR);

      this.isLoaded = true;
      localStorage.setItem("guitarLoaded", "true");
    });
    this.observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // alert("The target element enters the visible area.");
              exponea.track('summer_samokat', {
                event_action: 'summer_scroll1_concert'
              });
              // alert("Sent data v.2");
            }
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0,
        }
    );
    this.observer.observe(triggerto);
  },
  unmounted() {
    ScrollTrigger.killAll();
    // console.log('leave page');
  },
});
</script>
<style scoped>
.concert .container {
  background-color: var(--bg-dark-pink-color);
}

.m-t-minus-170 {
  margin-top: -170px;
}

.maks-wave {
  height: 254px;
}

.maks-play-on-guitar {
  margin-top: -90px;
}

.maks-play-on-guitar img {
  height: 254px;
}
</style>
