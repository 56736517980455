import GameSound from '../../../partials/game-sound';
import { res } from '../res';

export default class SoundManager extends GameSound<typeof res.sound> {
	constructor() {
		super(res.sound);
	}

	playMusic() {
		this.play('music', true);
	}

	playError() {
		this.play('error');
	}

	playGarbage1() {
		this.play('garbage1');
	}

	playGarbage2() {
		this.play('garbage2');
	}

	playGarbage3() {
		this.play('garbage3');
	}

	playGarbage4() {
		this.play('garbage4');
	}
}
