import {Loader} from 'excalibur';
import type {Loadable} from 'excalibur/build/dist/Interfaces/Loadable';

export default class CustomLoader extends Loader {
    constructor(loadables?: Loadable<any>[]) {
        super(loadables);

        this.suppressPlayButton = true;
    }
}
