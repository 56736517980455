
import {defineComponent} from "vue";
import {useSoundStore} from "@/store";

export default defineComponent({
  name: 'sound',
  data() {
    const sound = useSoundStore();

    return {
      sound
    }
  },
});
