import GameSound from '../../../partials/game-sound';
import { res } from '../res';

export default class SoundManager extends GameSound<typeof res.sound> {
  constructor() {
    super(res.sound);
  }

  playMusic() {
    this.play('music', true);
  }

  applyLowPass() {
  }

  disableLowPass() {
  }

  playFail() {
    this.play('fail');
  }

  playTrick() {
    this.play('trick');
  }

  playStart() {
    this.play('start');
  }
}
