
import { defineComponent } from 'vue';
// import { getImgAssetUrl } from "@/assets/img";

export default defineComponent({
    name: 'StickerBox',
     data() {
        return {
            classes: null,
        }
    },
    setup() {

    },
    props: {
        filename: String,
        alttext: String,
        shadow: Boolean,
    },
    methods: {
        // getImagePath: function(item) {
        //     // return new URL(`../assets/img/$(props.filename).webp`, import.meta.url);
        //     let images = require.context('../assets/', false, /\.png$|\.webp$/);
        //     return images("./" + item);
        // }
    }
})
