import Splash from '../splash';
import { res } from '../../res';
import { Sprite, Vector } from 'excalibur';
import gameController from '../../../../partials/games-controller';

export default class Splash3 extends Splash {
	private bgOffset = new Vector(0, -125).scaleEqual(gameController.pixelRatio);
	private crowdOffset = new Vector(0, 50).scaleEqual(gameController.pixelRatio);
	private maxOffset = new Vector(-25, 300).scaleEqual(gameController.pixelRatio);
	private frontOffset = new Vector(
		gameController.halfDrawWidth - 100 * gameController.pixelRatio,
		gameController.halfDrawHeight - 100 * gameController.pixelRatio,
	);

	addGraphics(): void {
		this.graphics.layers
			.create({
				name: 'bg',
				order: 0,
			})
			.use(<Sprite>res.graphics.splash3.getFrameSprite('assets/guitar/splash/splash3/4_BG'), {
				anchor: new Vector(0.5, 1),
			});

		this.graphics.layers
			.create({
				name: 'crowd',
				order: 0,
			})
			.use(<Sprite>res.graphics.splash3.getFrameSprite('assets/guitar/splash/splash3/3_crowd'), {
				anchor: new Vector(0.5, 1),
			});

		this.graphics.layers
			.create({
				name: 'max',
				order: 0,
			})
			.use(<Sprite>res.graphics.splash3.getFrameSprite('assets/guitar/splash/splash3/2_max_scene'), {
				anchor: new Vector(0.5, 1),
			});

		this.graphics.layers
			.create({
				name: 'front',
				order: 0,
			})
			.use(<Sprite>res.graphics.splash3.getFrameSprite('assets/guitar/splash/splash3/1_front_plane'), {
				anchor: new Vector(0.5, 1),
			});
	}

	fail(): void {
		super.fail();
		// this.graphics.layers.get('band-face').use(<Sprite>res.graphics.splash3.getFrameSprite('assets/guitar/splash/splash3/band-bad-face'));
		// this.graphics.layers.get('max-face').use(<Sprite>res.graphics.splash3.getFrameSprite('assets/guitar/splash/splash3/max-bad-face'), {
		// 	anchor: new Vector(0.5, 1),
		// });
		// this.graphics.layers.get('frontman-face').use(<Sprite>res.graphics.splash3.getFrameSprite('assets/guitar/splash/splash3/frontman-bad-face'), {
		// 	anchor: new Vector(0.5, 1),
		// });
	}

	success(): void {
		super.success();
		// this.graphics.layers.get('band-face').use(<Sprite>res.graphics.splash3.getFrameSprite('assets/guitar/splash/splash3/band-good-face'));
		// this.graphics.layers.get('max-face').use(<Sprite>res.graphics.splash3.getFrameSprite('assets/guitar/splash/splash3/max-good-face'), {
		// 	anchor: new Vector(0.5, 1),
		// });
		// this.graphics.layers.get('frontman-face').use(<Sprite>res.graphics.splash3.getFrameSprite('assets/guitar/splash/splash3/frontman-good-face'), {
		// 	anchor: new Vector(0.5, 1),
		// });
	}

	process(progress: number): void {
		this.graphics.layers.get('front').offset = this.frontOffset.clone().addEqual(new Vector(progress * 250, 0));
		this.graphics.layers.get('max').offset = this.maxOffset.clone().addEqual(new Vector(progress * 50 * gameController.pixelRatio, 0));
		this.graphics.layers.get('crowd').offset = this.crowdOffset.clone().addEqual(new Vector(progress * -25 * gameController.pixelRatio, 0));
		this.graphics.layers.get('bg').offset = this.bgOffset.clone().addEqual(new Vector(progress * 15 * gameController.pixelRatio, 0));
	}

	reset(): void {
		this.graphics.layers.get('bg').offset = this.bgOffset;
		this.graphics.layers.get('crowd').offset = this.crowdOffset;
		this.graphics.layers.get('max').offset = this.maxOffset;
		this.graphics.layers.get('front').offset = this.frontOffset;
	}
}
