import { Actor, ActorArgs, CollisionGroup, CollisionType, Engine, Input, Sprite, Vector } from 'excalibur';
import Trap from './trap';
import { TRASH_TYPE } from '../enums';
import { res } from '../res';
import gameController from '../../../partials/games-controller';
import { trashCollisionGroup } from '../collisions';
import { easeInOutCirc, easyRepeater, easyTween } from '../../../utils';

export default class Player extends Actor {
	lastDragPosition!: Vector;
	isBoosted = false;
	isDragged = false;
	boostTrap!: Trap;
	trapList: Trap[] = [];

	constructor(props: ActorArgs) {
		super({
			...props,
			width: 250 * gameController.pixelRatio,
			height: 150 * gameController.pixelRatio,
			pos: new Vector(0, gameController.halfDrawHeight),
			anchor: new Vector(0.5, 1),
			collisionType: CollisionType.Fixed,
			collisionGroup: CollisionGroup.collidesWith([trashCollisionGroup]),
			z: 2,
		});
	}

	onInitialize(_engine: Engine) {
		this.addGraphics();
		this.addTraps();
		this.registerEvents();
	}

	async boost() {
		if (this.isBoosted) return;

		this.isBoosted = true;
		const overSprite = <Sprite>res.assets.getFrameSprite('assets/catch/assets/player_boosted_white');

		this.graphics.use(<Sprite>res.assets.getFrameSprite('assets/catch/assets/player-boosted'));
		this.graphics.layers.get('over').use(overSprite);

		await easyTween(progress => {
			overSprite.opacity = easeInOutCirc(1 - progress);
		}, 500);

		for (const trap of this.trapList) {
			trap.unparent();
			trap.kill();
		}

		this.addChild(this.boostTrap);
	}

	async unboostCountdown() {
		const boostedSprite = <Sprite>res.assets.getFrameSprite('assets/catch/assets/player-boosted');

		this.graphics.use(<Sprite>res.assets.getFrameSprite('assets/catch/assets/player'));
		this.graphics.layers
			.create({
				name: 'boosted',
				order: 0,
			})
			.use(boostedSprite);

		let opacity = 2;
		for await (const value of easyRepeater(10, 100, () => ++opacity % 2)) {
			boostedSprite.opacity = value;
		}

		this.graphics.layers.get('boosted').hide();
	}

	async unboost() {
		if (!this.isBoosted) return;

		this.isBoosted = false;

		this.graphics.use(<Sprite>res.assets.getFrameSprite('assets/catch/assets/player'));

		this.boostTrap.unparent();
		this.boostTrap.kill();

		for (const trap of this.trapList) {
			trap.unkill();
			this.addChild(trap);
		}
	}

	private addGraphics() {
		this.graphics.use(<Sprite>res.assets.getFrameSprite('assets/catch/assets/player'));
		this.graphics.layers.create({
			name: 'over',
			order: 0,
		});
	}

	private registerEvents() {
		this.on('pointerdown', (e: Input.PointerEvent) => {
			this.isDragged = true;
			this.lastDragPosition = e.screenPos;
		});
		this.on('pointerup', () => (this.isDragged = false));
		this.scene.engine.input.pointers.primary.on('move', e => this.changePositionHandler(e));
	}

	private addTraps() {
		[TRASH_TYPE.RECYCLE1, TRASH_TYPE.RECYCLE2, TRASH_TYPE.RECYCLE3].map((obj, index) => {
			const trap = new Trap(
				{
					pos: new Vector(-88 + index * 88, -150).scaleEqual(gameController.pixelRatio),
				},
				obj,
			);

			this.trapList.push(trap);
			this.addChild(trap);
		});

		this.boostTrap = new Trap(
			{
				pos: new Vector(0, -150).scaleEqual(gameController.pixelRatio),
			},
			TRASH_TYPE.RECYCLE4,
		);
	}

	private changePositionHandler(e: Input.PointerEvent) {
		if (!this.isDragged) return;
		if (!this.lastDragPosition) return;

		this.pos.x -= this.lastDragPosition.sub(e.screenPos).x;
		this.lastDragPosition = e.screenPos;
	}
}
