import {defineStore} from "pinia";
import soundManager from "../../games/src/partials/sound-manager";
import {ROUTES} from "@/preroutes";
import {SCENES} from "../../games/src/enums";

interface SoundState {
    muted: boolean;
}

interface ScreenState {
    wrongOrientation: boolean;
}

export interface LoaderState {
    route: ROUTES | undefined,
    scene: SCENES | undefined,
    promise: Promise<void> | undefined
}

export const useSoundStore = defineStore('sound', {
    state: (): SoundState => ({
        muted: false,
    }),
    actions: {
        toggle() {
            // @ts-ignore
            this.muted = !soundManager.toggle()
        },
    },
});

export const useScreenStore = defineStore('screen', {
    state: (): ScreenState => ({
        wrongOrientation: false
    })
})

export const useLoaderStore = defineStore('loader', {
    state: (): LoaderState => ({
        promise: undefined,
        scene: undefined,
        route: undefined,
    })
})
