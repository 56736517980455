export enum TRASH_TYPE {
	RECYCLE1,
	RECYCLE2,
	RECYCLE3,
	RECYCLE4,
	RECYCLE5,
}

export enum EVENTS {
	BAD_CATCH = 'badCatch',
	GOOD_CATCH = 'goodCatch',
	BOOST = 'boost',
}
