import GameScene from '../../partials/game-scene';
import CustomLoader from '../../partials/custom-loader';
import Player from './components/player';
import {
	Color,
	Engine,
	Input,
	Physics,
	PossibleStates,
	Rectangle,
	Sprite,
	StateMachine,
	Timer,
	Vector
} from 'excalibur';
import gameController from '../../partials/games-controller';
import soundManager from '../../partials/sound-manager';
import Bin from './components/bin';
import { EVENTS, TRASH_TYPE } from './enums';
import { config } from './config';
import Trash from './components/trash';
import { res } from './res';
import { SpriteEntity } from '../../partials/sprite-entity';
import UI from './components/ui';
import Opponent from './components/opponent';
import SoundManager from './components/sound';
import { CatchTrashEvent } from './partials/custom-events';

export default class Catch extends GameScene {
	currentLevel!: number;
	loader: CustomLoader = new CustomLoader([res.assets, ...Object.values(res.sound)]);
	soundManager!: SoundManager;
	private player!: Player;
	private bin!: Bin;
	private ui!: UI;
	private opponent!: Opponent;
	private wrongTrash!: number;
	private trash!: number;
	private boostTimer!: Timer;
	private fsm!: StateMachine<PossibleStates<{
		start: string;
		states: {
			INIT: { transitions: string[] };
			RESTART: { onState: () => Promise<void>; transitions: string[] };
			NEXT_LEVEL: { onEnter: () => void; onState: () => Promise<void>; transitions: string[] };
			START: { onEnter: () => void; onState: () => void; transitions: string[] };
		};
	}>,
		unknown>;

	onInitialize() {
		super.onInitialize();
		this.soundManager = new SoundManager();
		this.soundManager.soundEnabled = soundManager.soundEnabled;
		this.engine.backgroundColor = Color.fromHex('#47CFFF');
		Physics.gravity = Vector.Down.scaleEqual(200);
	}

	onActivate() {
		super.onActivate();
		this.wrongTrash = 0;
		this.trash = 0;
		this.currentLevel = 0;
		this.fsm = StateMachine.create({
			start: 'INIT',
			states: {
				INIT: {
					transitions: ['START'],
				},
				START: {
					onEnter: () => this.killAndReset(),
					onState: () => this.onStartState(),
					transitions: ['RESTART', 'NEXT_LEVEL'],
				},
				RESTART: {
					onState: () => this.onRestartState(),
					transitions: ['START'],
				},
				NEXT_LEVEL: {
					onEnter: () => this.killAndReset(),
					onState: () => this.onNextLevelState(),
					transitions: ['START'],
				},
			},
		});

		this.addBG();
		this.addOpponent();
		this.addBin();
		this.addPlayer();
		this.addUI();
	}

	update(engine: Engine, delta: number) {
		super.update(engine, delta);

		this.movePlayer(engine, delta);
	}

	async gameOver() {
		this.inputAvailable = false;
		await this.soundManager.stopAll(1000);
		super.gameOver();
	}

	start() {
		super.start();
		this.fsm.go('START');
	}

	poorPerformanceHandler(): void {
	}

	startMusic(): void {
		this.soundManager.playMusic();
	}

	protected registerEvents() {
		super.registerEvents();

		// @ts-ignore
		this.eventDispatcher.on(EVENTS.GOOD_CATCH, (e: CatchTrashEvent) => this.goodCatchHandler(e.type));
		// @ts-ignore
		this.eventDispatcher.on(EVENTS.BAD_CATCH, (e: CatchTrashEvent) => this.badCatchHandler(e.type));
		this.eventDispatcher.on(EVENTS.BOOST, () => this.boost());
	}

	private boost() {
		this.boostTimer = new Timer({
			fcn: async () => {
				await this.player.unboostCountdown();
				this.unboost();
			},
			interval: config.boostTime,
		});

		this.add(this.boostTimer.start());

		this.player.boost();
		this.bin.boost();

		for (const entity of this.entities) {
			if (entity instanceof Trash) {
				entity.boost();
			}
		}
	}

	private unboost() {
		this.player.unboost();
		this.bin.unboost();

		for (const entity of this.entities) {
			if (entity instanceof Trash) {
				entity.unboost();
			}
		}
	}

	private addUI() {
		this.ui = new UI();

		this.add(this.ui);
	}

	private addBG() {
		const sky = new SpriteEntity();
		const skySprite = <Sprite>res.assets.getFrameSprite('assets/catch/assets/gradient');
		skySprite.scale.x = (gameController.drawWidth + 100 * gameController.pixelRatio) / skySprite.width;
		skySprite.scale.y = (gameController.halfDrawHeight + 185 * gameController.pixelRatio) / skySprite.height;
		sky.graphics.use(skySprite, {
			anchor: new Vector(0.5, 1),
		});
		sky.pos = new Vector(0, 85).scaleEqual(gameController.pixelRatio);

		const bg = new SpriteEntity();
		const bgSprite = <Sprite>res.assets.getFrameSprite('assets/catch/assets/BG');
		bg.graphics.use(bgSprite);
		bg.pos = new Vector(0, 85).scaleEqual(gameController.pixelRatio);

		const grass = new SpriteEntity();
		grass.graphics.use(
			new Rectangle({
				width: gameController.drawWidth + 50 * gameController.pixelRatio,
				height: bgSprite.height,
				color: Color.fromHex('#7ec578'),
			}),
		);
		grass.pos = new Vector(0, 240).scaleEqual(gameController.pixelRatio);

		this.add(sky);
		this.add(grass);
		this.add(bg);
	}

	private addBin() {
		this.bin = new Bin();

		this.add(this.bin);
	}

	private addOpponent() {
		this.opponent = new Opponent();

		this.add(this.opponent);
	}

	private addPlayer() {
		this.player = new Player({});

		this.add(this.player);
	}

	private goodCatchHandler(type: TRASH_TYPE) {
		if (!this.inputAvailable) return;

		switch (type) {
			case TRASH_TYPE.RECYCLE1:
				this.soundManager.playGarbage3();
				break;

			case TRASH_TYPE.RECYCLE2:
				this.soundManager.playGarbage2();
				break;

			case TRASH_TYPE.RECYCLE3:
				this.soundManager.playGarbage1();
				break;

			case TRASH_TYPE.RECYCLE4:
			case TRASH_TYPE.RECYCLE5:
				this.soundManager.playGarbage4();
				break;

			default:
				break;
		}

		this.trash++;

		this.checkLevelProgress();
	}

	private badCatchHandler(type: TRASH_TYPE) {
		if (!this.inputAvailable) return;

		if (type !== TRASH_TYPE.RECYCLE4) {
			this.soundManager.playError();
			this.camera.shake(10 * gameController.pixelRatio, 10 * gameController.pixelRatio, 125 * gameController.pixelRatio);
			this.ui.removeOneLife();
		}

		this.wrongTrash++;

		this.checkLevelProgress();
	}

	private checkLevelProgress() {
		if (this.wrongTrash >= 3) {
			return this.fsm.go('RESTART');
		}

		if (this.trash + this.wrongTrash >= config.levels[this.currentLevel].count) {
			if (this.currentLevel + 1 === config.levels.length) {
				return this.gameOver();
			}

			return this.fsm.go('NEXT_LEVEL');
		}
	}

	private onStartState() {
		this.inputAvailable = true;
		this.bin.start();
		this.opponent.start();
	}

	private killAndReset() {
		this.killAllTrash();
		this.inputAvailable = false;
		this.ui.reset();
		this.resetProgress();
	}

	private resetProgress() {
		this.trash = 0;
		this.wrongTrash = 0;
	}

	private async onRestartState() {
		this.killAllTrash();
		this.inputAvailable = false;
		this.bin.stop();
		this.bin.reset();
		this.unboost();
		this.boostTimer?.stop();

		await gameController.waitFor(1000);

		this.fsm.go('START');
	}

	private async onNextLevelState() {
		this.currentLevel++;
		this.bin.stop();
		this.bin.reset();
		this.unboost();
		this.boostTimer?.stop();

		await gameController.waitFor(1000);

		this.fsm.go('START');
	}

	private killAllTrash() {
		for (const entity of this.entities) {
			if (entity instanceof Trash) entity.kill();
		}
	}

	private movePlayer(engine: Engine, delta: number) {
		if (!this.inputAvailable) return;

		if (engine.input.keyboard.isHeld(Input.Keys.Left) || engine.input.keyboard.isHeld(Input.Keys.A)) {
			this.player.pos.x -= (750 / 1000) * delta;
		}

		if (engine.input.keyboard.isHeld(Input.Keys.Right) || engine.input.keyboard.isHeld(Input.Keys.D)) {
			this.player.pos.x += (750 / 1000) * delta;
		}

		this.player.pos.x = Math.min(Math.max(this.player.pos.x, -500), 500);
	}
}
