import {Entity, GraphicsComponent, ParallaxComponent, TransformComponent, Vector} from 'excalibur';

export class SpriteEntity extends Entity {
    transform!: TransformComponent;
    graphics!: GraphicsComponent;
    parallax!: ParallaxComponent;

    constructor() {
        super([new TransformComponent(), new GraphicsComponent(), new ParallaxComponent()]);

        this.transform = <TransformComponent>this.get(TransformComponent);
        this.graphics = <GraphicsComponent>this.get(GraphicsComponent);
        this.parallax = <ParallaxComponent>this.get(ParallaxComponent);
    }

    get pos() {
        return this.transform.pos;
    }

    set pos(v: Vector) {
        this.transform.pos = v;
    }
}
