import { TRASH_TYPE } from './enums';

export const config = {
	areas: {
		[TRASH_TYPE.RECYCLE1]: {
			min: -170,
			max: 55,
		},
		[TRASH_TYPE.RECYCLE2]: {
			min: -110,
			max: 110,
		},
		[TRASH_TYPE.RECYCLE3]: {
			min: -55,
			max: 170,
		},
		[TRASH_TYPE.RECYCLE4]: {
			min: -170,
			max: 170,
		},
		[TRASH_TYPE.RECYCLE5]: {
			min: -170,
			max: 170,
		},
	},
	levels: [
		{
			count: 20 * 0.7,
		},
		{
			count: 20 * 0.7,
		},
		{
			count: 20 * 0.7,
		},
	],
	boostTime: 4000,
};
