<template>
  <div class="picnic-wrapper-outro wrapper-global-fix" id="wrapperglb">
   <!-- container-->
        <div class="container bg-green">
              <TopBar progress="0" />
        </div>
        <!-- /container -->
        <!-- container -->
        <div class="container no-flex bg-green blogger-scroll-wrapper" ref="bloggerWrapper">
            <div class="wrapper">
                <ImageCdn filename="photo-blogger.webp" class="polaroid-new polaroid-big" width="647" height="778" />
                <Comment class="answer" text="Судя по счастливому виду Коте, он ел что-то очень вкусное. Хм, посмотрим, у кого ещё могла пропасть еда." /> 
                 <div class="img-block m-t-20">
                    <img alt="" src="../assets/img/zooms-002.webp"  width="246" height="246"/>
                 </div>
                <Comment class="answer" text="Рядом с девушкой у дерева какое-то странное пятно…" />
                    <div class="img-block m-t-20">
                        <img src="../assets/img/zooms-001.webp" width="246" height="246" alt="" />
                    </div>
                <Comment class="answer" text="О, это же Стас Просто Класс в парике! Как-то он подозрительно падает в воду." />
                <Comment class="answer" text="Похититель мог уйти в обе стороны. Куда же пойти?" />
            </div>
        </div>
        <!-- /container -->
        <!-- container -->
        <div class="container bg-green maks-scroll-cntr-1 bg-gradient-outro-1">
            <div class="wrapper">
            <div class="text-block text-dark-blue">
                <p>Помогите Максиму решить,
                    <span class="nobr">в какую</span> сторону пойти.</p>
            </div>
            <div class="btn-mutli-row">
                      <LetoButton @click="nextLeft" class="to-left" text="На йогу" />
                      <LetoButton @click="nextRight" class="to-right" text="На озеро" />
            </div>
            </div>
            <div class="wrapper-wide">
                <div class="img-block bottom-picnic-outro-one">
                    <img src="../assets/img/image-02-05.webp" class="maks-sticker__up" alt="" />
                </div>
            </div>
        </div>
        <!-- /container -->
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import Comment from '@/components/Comment.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import TextBox from '@/components/TextBox.vue';
import TopBar from '@/components/TopBar.vue'; // @ is an alias to /src
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SOUND_SCENES } from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';
gsap.registerPlugin(ScrollTrigger);



export default defineComponent({
  name: 'PicnicOutro1View',
  components: {
    TopBar,
    LetoButton,
    Comment,
    ImageBox,
    ImageCdn,
    TextBox,
    soundManager,
  }, setup() {
        const router = useRouter();
        return {
            router
        }
    },
    methods: {
        async nextLeft() {
            this.router.push({
                path: ROUTES.PICNIC_OUTRO_2_YOGA,
            });
            exponea.track('summer_samokat', {
                event_action: 'summer_to_yoga'
            });


        },
        async nextRight() {
            this.router.push({
                path: ROUTES.PICNIC_OUTRO_2_SURF,
            });
            exponea.track('summer_samokat', {
                event_action: 'summer_to_lake'
            });
        },

        async playMusic() {
            await soundManager.play('picnicIntro', true);
        }

        // maksUp() {
        //     gsap.to(".maks-sticker__up", {
        //         scrollTrigger: {
        //             trigger: ".maks-scroll-cntr-1",
        //             scroller: "#wrapperglb",
        //             start: "top bottom", // the default values
        //             end: "bottom bottom",
        //             scrub: true,
        //             // markers: true,
        //         },
        //         // duration: 2,
        //         top: "-10%",
        //         // opacity: 1,
        //     });
        //     console.log('Here');
        // }
    },
  mounted() {
    // ScrollTrigger.killAll();
        this.playMusic();
    // this.maksUp();
        // let mm = gsap.matchMedia();
        // let comments = gsap.utils.toArray(".comment");
        // // let bloggerWrapper = document.querySelector('.blogger-scroll-wrapper').offsetHeight;

        // console.log(comments);
        // comments.forEach((comment) => {
        //     // console.log(comment);
        //     gsap.set(comment, {
        //         marginBlockStart: "25px",
        //         opacity: 0,
        //     });
        // });
        // var tl = gsap.timeline({
        //     scrollTrigger: {
        //         trigger: '.blogger-scroll-wrapper',
        //         start: "top top", // the default values
        //         // end: "bottom top",
        //         end: "+=" + bloggerOffset,
        //         scrub: true,
        //         // markers: true,
        //         pin: true,
        //         // pinSpacing: false,
        //     },
        // });
        // comments.forEach((comment, index) => {

        //     tl.to(comment, {
        //         marginBlockStart: "0",
        //         opacity: 1,
        //         // duration: 1.2,
        //     });
        //     tl.to(comment, {
        //         // left: "20px",
        //         marginBlockStart: "-46px",
        //         opacity: 0,
        //         // duration: 0.2,
        //     });
        // });
        // // console.log(comments);
        // gsap.to(".maks-sticker__up", {
        //     scrollTrigger: {
        //         trigger: ".bottom-picnic-outro-one",
        //         start: "top bottom", // the default values
        //         end: "bottom top",
        //         scrub: true,
        //         // markers: true,
        //     },
        //     // duration: 2,
        //     top: "-20%",
        //     // opacity: 1,
        // });
  }, unmounted() {
        ScrollTrigger.killAll();
        // console.log('leave page');
    },
});
</script>
<style scoped>
/* .container.blogger-scroll-wrapper {
    height: 100vh !important;
    max-height: 100vh !important;
} */
.picninc-outro-top-image {
    transform: rotate(-6.74deg);
    /* max-height: 100%; */
    max-height: 400px;
}

.photo-blogger__index-image {
    /* max-height: 60vh; */
    max-height: 350px;
    margin-inline: 45px;
    /* margin-bottom: 65px;
    margin-bottom: clamp(25px,30%,45px); */
    margin-top: 45px;
    margin-bottom: 100px;
    z-index: 2000;
}
.bottom-picnic-outro-one {
    position: relative;
    /* height: 450px; */
    height: 355px;
    background-image: url(../assets/img/image-03-bottom-3.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}

.bottom-picnic-outro-one .maks-sticker__up {
    position: absolute;
    /* top: 70%; */
    bottom: -20px;
    left: calc(50% - 100px);
    margin-block-start:0 ;
    height: 254px;
}

 .bg-gradient-outro-1 {
    background: rgb(255,229,199);
background: linear-gradient(0deg, rgba(255,229,199,1) 67%, rgba(142,195,128,1) 100%);
 }


</style>
