import { SpriteEntity } from './sprite-entity';
import { ActionsComponent, Sprite, Vector } from 'excalibur';

export default class SideScrollEntity extends SpriteEntity {
	actions!: ActionsComponent;

	constructor(private width: number, private sprite: Sprite, private speed: number) {
		super();

		this.actions = new ActionsComponent();
		this.addComponent(this.actions);
	}

	onInitialize() {
		this.fillGraphics();
	}

	fillGraphics() {
		const spriteCount = Math.max(Math.ceil(this.width / this.sprite.width) * 2, 3);

		for (let i = 0; i < spriteCount; i++) {
			this.graphics.layers
				.create({
					order: i,
					name: `layer#${i}`,
					offset: new Vector(this.sprite.width * i - ((this.sprite.width * spriteCount) / 2 - this.sprite.width / 2), 0),
				})
				.use(this.sprite);
		}
	}

	move() {
		const speedRatio = this.speed < 0 ? -1 : 1;

		this.actions.getQueue().isComplete() &&
			this.actions.repeatForever(async actions => {
				await actions.moveBy(new Vector(this.sprite.width * speedRatio, 0), Math.abs(this.speed)).toPromise();
			});
	}

	stop() {
		this.actions.clearActions();
	}

	onPreUpdate() {
		this.pos.x = this.pos.x % this.sprite.width;
	}
}
