import { version } from '../../package.json';
import { Color, DisplayMode, Engine, GameEvent, Input } from 'excalibur';
import GameScene from './game-scene';
import WakeGame from '../scenes/wake/wake';
import YogaGame from '../scenes/yoga/yoga';
import GuitarGame from '../scenes/guitar/guitar';
import CatchGame from '../scenes/catch/catch';
import { GAME_EVENTS, SCENES } from '../enums';
import { Pane } from 'tweakpane';
import { devicePixelRatio } from '../utils';
import Bugsnag from '@bugsnag/js';
import { debounce } from 'throttle-debounce';
import JSONSpriteSheet from './json-spritesheet';

Bugsnag.start({
	appVersion: version,
	apiKey: 'd6e784d488305f27175847eea62d503a',
});

class GamesController extends Engine {
	public poorPerformance = false;

	constructor() {
		super({
			displayMode: DisplayMode.FitScreenAndFill,
			viewport: {
				width: 390,
				height: 672,
			},
			resolution: {
				width: 390 * devicePixelRatio,
				height: 672 * devicePixelRatio,
			},
			fixedUpdateFps: 30,
			pixelRatio: 1,
			maxFps: 60,
			configurePerformanceCanvas2DFallback: {
				allow: true,
				threshold: {
					fps: 20,
					numberOfFrames: 100,
				},
			},
			pointerScope: Input.PointerScope.Canvas,
			backgroundColor: Color.Black,
			suppressConsoleBootMessage: true,
		});

		this.pauseAudioWhenHidden = false;

		this.canvas.style.userSelect = 'none';
		this.canvas.style.transition = 'opacity 100ms ease';

		if (!JSONSpriteSheet.canUseWebP()) {
			Bugsnag.notify(new Error('cantUseWebP'));
		}
	}

	get pixelRatio(): number {
		return devicePixelRatio;
	}

	useCanvas2DFallback() {
		Bugsnag.notify(new Error('useCanvas2DFallback'));
		this.poorPerformance = true;
		this.currentScene.eventDispatcher.emit(GAME_EVENTS.LOW_FPS, new GameEvent());
	}

	onInitialize() {
		// this.activateDebug();
		// this.showFpsCounter();

		this.addScene(SCENES.CATCH, new CatchGame());
		this.addScene(SCENES.WAKE, new WakeGame());
		this.addScene(SCENES.YOGA, new YogaGame());
		this.addScene(SCENES.GUITAR, new GuitarGame());

		this.registerEvents();
	}

	async prepareScene(scene: SCENES) {
		return await (<GameScene>this.scenes[scene]).prepare();
	}

	activateScene(scene: SCENES) {
		this.goToScene(scene);
	}

	startCurrentSceneMusic() {
		(<GameScene>this.currentScene).startMusic();
	}

	startCurrentScene() {
		(<GameScene>this.currentScene).start();
	}

	waitFor(time: number): Promise<void> {
		return new Promise(res => {
			this.clock.schedule(() => res(), time);
		});
	}

	private handleScreenOrientation() {
		const wrongOrientation = matchMedia('(max-width: 1024px) and (orientation: landscape)').matches;

		window.dispatchEvent(
			new CustomEvent(GAME_EVENTS.SCREEN_ORIENTATION_CHANGED, {
				detail: {
					wrongOrientation,
				},
			}),
		);

		return wrongOrientation;
	}

	private registerEvents() {
		this.eventDispatcher.on(GAME_EVENTS.GAME_OVER, this.gameOver.bind(this));
		window.addEventListener('resize', () => {
			this.canvas.style.opacity = '0';
			this.handleScreenOrientation();
		});
		window.addEventListener(
			'resize',
			debounce(500, async () => {
				const wrongOrientation = this.handleScreenOrientation();

				if (!wrongOrientation) {
					requestAnimationFrame(() => (this.canvas.style.opacity = '1'));
				}
			}),
		);
	}

	// @ts-ignore
	private activateDebug() {
		this.debug.entity.showId = false;
		this.debug.collider.showGeometry = true;
		this.debug.transform.showPosition = true;

		this.showDebug(true);
	}

	// @ts-ignore
	private showFpsCounter() {
		const fpsPane = new Pane();
		fpsPane.addMonitor(this.clock.fpsSampler, 'fps');
	}

	private gameOver() {
		this.canvas.dispatchEvent(new Event(GAME_EVENTS.GAME_OVER));
	}
}

const gameController = new GamesController();
gameController.start();

export default gameController;
