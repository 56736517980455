<template>
  <div id="wrapperglb" class="wrapper-global-fix yoga-wrapper">
    <!-- push-wrapper -->
    <div class="push-wrapper">
      <!-- container-->
      <div class="container yoga-container-first">
        <TopBar progress="1"/>
          <ImageCdn class="t-m-0 adaptive-first-image-container" filename="yoga-001-4.webp"/>
      </div>
      <!-- /container -->
      <!-- container-->
      <div class="container yoga-container">
        <div class="wrapper">
          <ImageCdn class="shadow" filename="yoga-002.webp"/>
          <Comment class="answer" text="Ух, привет! Скажи, ты… видела? Фух… Что-то мне…"/>
          <ImageCdn class="max-yoga-tired-1" filename="yoga-003.webp"/>
          <ImageCdn class="max-yoga-tired-2" filename="yoga-004.webp"/>
          <ImageCdn class="" filename="yoga-005.webp"/>
          <ImageCdn class="shadow" filename="yoga-006.webp"/>
          <Comment class="yoga" text="Привет, я Вера Вольт. Ты в порядке? Давай помогу."/>
          <Comment class="answer" text="Наверное, я немного забегался."/>
          <ImageCdn class="shadow" filename="yoga-007.webp"/>
          <Comment class="yoga" text=" Думаю, тебе нужно восстановить дыхание. В этом хорошо помогает йога."/>
          <ImageCdn class="shadow" filename="yoga-008.webp"/>
          <Comment class="yoga" text="У нас с ребятами как раз начинается тренировка. Пойдём со мной, если хочешь!"/>
          <Comment class="answer" text="Не уверен, что это хорошая идея. Хотя... Ладно, давай!"/>
          <ImageCdn class="maks-confused no-round" filename="yoga-009.webp"/>
        </div>
      </div>
      <!-- /container -->
      <div class="wrapper-wide btn-row yoga-container">
        <button class="btn-next" @click="next">Начать тренировку</button>
      </div>
      <div id="triggerto" ref="triggerto"></div>
    </div>
    <!-- / push-wrapper -->
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {useRouter} from "vue-router";
import {ROUTES} from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import Comment from '@/components/Comment.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import TopBar from '@/components/TopBar.vue'; // @ is an alias to /src
import {gsap} from 'gsap';
import gameController from "../../games/src/partials/games-controller";
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {SCENES} from "../../games/src/enums";
import {GAME_EVENTS} from "../../games/src/enums";

gsap.registerPlugin(ScrollTrigger);
import soundManager from '../../games/src/partials/sound-manager';
import {SOUND_SCENES} from "../../games/src/enums";
import {useLoaderStore} from "@/store";

let promice;
// let isLoaded = false;
export default defineComponent({
  name: 'YogaView',
  components: {
    TopBar,
    LetoButton,
    Comment,
    ImageBox,
    ImageCdn,
    soundManager,
  },
  data() {
    return {
      isLoaded: false,
      observer: null,
    }
  },
  setup() {
    const loader = useLoaderStore()

    const router = useRouter();
    return {
      loader,
      router
    }
  },
  methods: {
    async next() {
      await soundManager.stopAll(250);
      exponea.track('summer_samokat', {
        event_action: 'summer_game_instruction_yoga'
      });
      if (this.isLoaded) {
        this.router.push({
          path: ROUTES.YOGA_GAME
        });
      } else {
        this.router.push({
          path: ROUTES.LOADER
        })
      }
    }
  },
  async mounted() {
    soundManager.play('yogaIntro', true);
    this.loader.scene = SCENES.YOGA
    this.loader.route = ROUTES.YOGA_GAME
    this.loader.promise = gameController.prepareScene(SCENES.YOGA)
    this.loader.promise.then(() => {
      gameController.activateScene(SCENES.YOGA);
      this.isLoaded = true;
    });
    this.observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // alert("The target element enters the visible area.");
              exponea.track('summer_samokat', {
                event_action: 'summer_scroll1_yoga'
              });
              // alert("Sent data v.2");
            }
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0,
        }
    );
    this.observer.observe(triggerto);
  },
  unmounted() {
    ScrollTrigger.killAll();
  },
})
</script>

<style scoped>

#wrapperglb.yoga-wrapper {
  background-color: var(--bg-green-color-new);
}

/* .yoga-container-first {
    background-color: var(--bg-blue-color);
} */
.yoga-container {
  background-color: var(--bg-green-color-new);
}

.max-yoga-tired-1 :deep() img {
  margin-inline: 0;
  max-width: 80%;
  height: 254px;
  margin: auto;
}

.max-yoga-tired-2 :deep() img {
  margin-inline: 0;
  max-width: 80%;
  height: 254px;
  /* margin-left: auto; */
  margin: auto;
}

.maks-confused :deep() img {
  height: 254px;
}
</style>
