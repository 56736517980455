import { ParticleEmitter, Sprite, toRadians } from 'excalibur';
import { res } from '../res';

export default class Sparks extends ParticleEmitter {
  constructor() {
    super({
      particleSprite: <Sprite>res.graphics.ui.getFrameSprite('assets/guitar/ui/star'),
      radius: 0,
      minVel: 0,
      maxVel: 175,
      minAngle: 0,
      maxAngle: toRadians(360),
      isEmitting: false,
      emitRate: 25,
      fadeFlag: true,
      particleLife: 1000,
      maxSize: 0,
      minSize: 0,
      startSize: 0,
      endSize: 0,
    });
  }
}
