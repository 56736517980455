import { Animation, AnimationStrategy, ImageSource, range, SpriteSheet } from 'excalibur';
import type { ISpriteSheetJSON } from '../types';

export default class SpriteSheetAnimation {
	private animationMap = new Map<string, Animation>();
	private raw: ISpriteSheetJSON;
	private spriteSheet!: SpriteSheet;

	constructor(data: ISpriteSheetJSON, imageSource: ImageSource, private readonly frameRate = 1000 / 30) {
		this.raw = data;

		this.makeSpriteSheet(imageSource);
		this.parseAnimations();
	}

	private makeSpriteSheet(imageSource: ImageSource) {
		this.spriteSheet = SpriteSheet.fromImageSourceWithSourceViews({
			image: imageSource,
			sourceViews: Object.values(this.raw.frames).map(({ frame }) => ({
				width: frame.w,
				height: frame.h,
				x: frame.x,
				y: frame.y,
			})),
		});
	}

	private parseKeyName(key: string) {
		const keys = key.split('/');
		return key.replace(`/${keys[keys.length - 1]}`, '');
	}

	private parseAnimations() {
		const animationKeys = new Set([
			...Object.keys(this.raw.frames).map(key => {
				return this.parseKeyName(key);
			}),
		]);

		animationKeys.forEach(key => {
			const startIndex = Object.keys(this.raw.frames).findIndex(frameKey => this.parseKeyName(frameKey) === key);
			const endIndex = startIndex + Object.keys(this.raw.frames).filter(frameKey => this.parseKeyName(frameKey) === key).length - 1;
			const anim = Animation.fromSpriteSheet(this.spriteSheet, range(startIndex, endIndex), this.frameRate);
			anim.pause();
			this.animationMap.set(key, anim);
		});
	}

	getAnimation(
		name: string,
		conf?: {
			duration?: number;
			strategy?: AnimationStrategy;
		},
	): Animation | null {
		if (this.animationMap.has(name)) {
			const anim = <Animation>this.animationMap.get(name);

			if (conf) {
				anim.strategy = conf?.strategy || AnimationStrategy.Loop;
				anim.frameDuration = conf?.duration || this.frameRate;
			}

			return anim;
		}

		return null;
	}
}
