import AnimationsList from '../../partials/animations-list';
import { res } from './res';
import SpriteSheetAnimation from '../../partials/spritesheet-animation';
import { Animation, AnimationStrategy } from 'excalibur';

type WakeAnimList = {
	wave: Animation;
	boatTrace: Animation;
	foam: Animation;
	splash: Animation;
	boardTrace: Animation;
};

class Animations extends AnimationsList<WakeAnimList> {
	constructor() {
		super(res.graphics.fx);
	}

	protected getList(): WakeAnimList {
		const spriteSheetAnimation = new SpriteSheetAnimation(this.spritesheet.data, this.spritesheet.imageSource);

		return {
			wave: <Animation>spriteSheetAnimation.getAnimation('assets/wake/FX-Animations/wave'),
			boatTrace: <Animation>spriteSheetAnimation.getAnimation('assets/wake/FX-Animations/boat_trace'),
			foam: <Animation>spriteSheetAnimation.getAnimation('assets/wake/FX-Animations/foam'),
			splash: <Animation>spriteSheetAnimation.getAnimation('assets/wake/FX-Animations/splash', { strategy: AnimationStrategy.End }),
			boardTrace: <Animation>spriteSheetAnimation.getAnimation('assets/wake/FX-Animations/board_trace'),
		};
	}
}

type TricksAnim = {
	trick1: Animation;
	trick2: Animation;
	trick3: Animation;
};

class Tricks extends AnimationsList<TricksAnim> {
	constructor() {
		super(res.graphics.tricks);
	}

	protected getList(): TricksAnim {
		const animSpriteSheet = new SpriteSheetAnimation(this.spritesheet.data, this.spritesheet.imageSource, 1000 / 2);

		return {
			trick1: <Animation>animSpriteSheet.getAnimation('assets/wake/tricks/Trick 01', { strategy: AnimationStrategy.Freeze }),
			trick2: <Animation>animSpriteSheet.getAnimation('assets/wake/tricks/Trick 02', { strategy: AnimationStrategy.Freeze }),
			trick3: <Animation>animSpriteSheet.getAnimation('assets/wake/tricks/Trick 03', { strategy: AnimationStrategy.Freeze }),
		};
	}
}

type CanThrowAnim = {
	throw: Animation;
};

class ThrowAnim extends AnimationsList<CanThrowAnim> {
	constructor() {
		super(res.graphics.boat);
	}

	protected getList(): CanThrowAnim {
		const animSpriteSheet = new SpriteSheetAnimation(this.spritesheet.data, this.spritesheet.imageSource, 1000 / 20);

		return {
			throw: <Animation>animSpriteSheet.getAnimation('assets/wake/boat/dude', { strategy: AnimationStrategy.Freeze }),
		};
	}
}

export const animations = new Animations();
export const tricksAnimation = new Tricks();
export const canThrowAnimation = new ThrowAnim();
