
import {defineComponent, ref, onMounted} from 'vue';
import {useRouter} from "vue-router";
import {ROUTES} from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import BubbleRule from '@/components/BubbleRule.vue';
import GameBar from '@/components/GameBar.vue';
import gameController from "../../games/src/partials/games-controller";
import {SCENES} from "../../games/src/enums";
import {GAME_EVENTS} from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';

let promice;
var text;
export default defineComponent({
  name: 'YogaGame',
  components: {
    GameBar,
    LetoButton,
    BubbleRule,
  },
  setup() {
    // const wrppr = ref();
    const router = useRouter();
    return {
      // wrppr,
      router
    }
  },
  data() {
    return {
      showWrpr: true,
      textBefore: "",
      textAfter: "",
      mapImage: "",
      exponea: "summer_game_yoga"
      // showBar: false,
    };
  },
  created() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent,)) {
      this.textBefore = 'Запомните асаны, которые показывает Вера, и повторите их в правильном порядке.';
      this.textAfter = 'Для этого нажимайте по очереди картинки на лотосе.';
      this.mapImage = "help-yoga-game-desktop.webp";
    } else {
      this.textBefore = 'Запомните асаны, которые показывает Вера, и повторите их в правильном порядке.';
      this.textAfter = 'Для этого нажимайте по очереди картинки на лотосе.';
      this.mapImage = "help-yoga-game-desktop.webp";
    }
  },
  methods: {
    // startGame() {
    //     this.showWrpr = false;
    //     // this.showBar = true;
    //     gameController.startCurrentScene();
    // },
    endGame() {
      setTimeout(() => {
        // this.showWrpr = true;
        // this.showBar = false;

        document.querySelector('canvas')?.classList.remove('show');
        this.router.push({
          path: ROUTES.YOGA_OUTRO
        });
      }, 1500);
    }
  },
  async mounted() {
    gameController.startCurrentSceneMusic();
    document.querySelector('canvas')?.classList.add('show');
    // promice = await gameController.prepareScene(SCENES.YOGA);
    // gameController.activateScene(SCENES.YOGA);
    // gameController.startCurrentScene();
    // console.log(promice);
    document.querySelector('canvas')?.addEventListener(GAME_EVENTS.GAME_OVER, () => {
      this.endGame();
    }, {
      once: true
    });

  }, unmounted() {
  },
});
