import { Vector } from 'excalibur';

export const config = {
	inputValue: 2,
	failTime: 1000,
	cameraDefaultPosition: new Vector(0, -40),
	playerStartPosition: new Vector(-15, 0),
	playerPositionXOffset: 50,
	boatStartPosition: new Vector(269, 5),
	trick: 0.75,
	fail: 0.05,
	cameraZoomTime: 750,
	cameraZoomValue: 1.5,
	levels: [
		{
			radius: 28,
			time: 5,
			failTime: 2500,
		},
		{
			radius: 33,
			time: 6,
			failTime: 2250,
		},
		{
			radius: 38,
			time: 7,
			failTime: 2250,
		},
	],
};
