
import { defineComponent } from 'vue';
import Sound from '@/components/Sound.vue'

export default defineComponent({
  name: 'GameBar',
  components: {
    Sound,
  },
  props: {
    global: Boolean,
    filename: String,
  },
});
