import SRTLoader from './partials/srt-loader';
import { Sound } from 'excalibur';
import JSONSpriteSheet from '../../partials/json-spritesheet';
import { getAssetUrl } from '../../utils';

export const res = {
	srt: new SRTLoader(getAssetUrl('./assets/guitar/srt-test.srt')),
	graphics: {
		notes: new JSONSpriteSheet(getAssetUrl('./assets/guitar/plashki.json'), getAssetUrl('./assets/guitar/2x/plashki.json')),
		ui: new JSONSpriteSheet(getAssetUrl('./assets/guitar/ui.json'), getAssetUrl('./assets/guitar/2x/ui.json')),
		splash1: new JSONSpriteSheet(getAssetUrl('./assets/guitar/splash1.json'), getAssetUrl('./assets/guitar/2x/splash1.json')),
		splash2: new JSONSpriteSheet(getAssetUrl('./assets/guitar/splash2.json'), getAssetUrl('./assets/guitar/2x/splash2.json')),
		splash3: new JSONSpriteSheet(getAssetUrl('./assets/guitar/splash3.json'), getAssetUrl('./assets/guitar/2x/splash3.json')),
	},
	sound: {
		music: new Sound(getAssetUrl('./assets/sound/concert/concert_nosolo.mp3')),
		solo: new Sound(getAssetUrl('./assets/sound/concert/concert_solo.mp3')),
		soloFail: new Sound(getAssetUrl('./assets/sound/concert/concert_badsolo.mp3')),
		loop: new Sound(getAssetUrl('./assets/sound/concert/concert_preloop.mp3')),
		crowd: new Sound(getAssetUrl('./assets/sound/fx/Concert/norm_crowd.mp3')),
		angryCrowd: new Sound(getAssetUrl('./assets/sound/fx/Concert/angry_crowd.mp3')),
		cheerCrowd: new Sound(getAssetUrl('./assets/sound/fx/Concert/cheer_crowd.mp3')),
	},
};
