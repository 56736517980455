import { Actor, Animation, Sprite, Vector } from "excalibur";
import { res } from '../res';
import { easyTween, random } from '../../../utils';
import { config } from '../config';
import gameController from '../../../partials/games-controller';
import { canThrowAnimation } from '../animations';

export default class Boat extends Actor {
	private initPos = config.boatStartPosition.clone();

	onInitialize() {
		this.anchor = new Vector(0.5, 1);
		this.pos = this.initPos.scaleEqual(gameController.pixelRatio);

		this.addGraphics();
		this.move();
	}

	move() {
		const moveSpeed = 40;
		const offset = { min: -15, max: 15 };

		this.actions.repeatForever(async actions => {
			await actions.moveBy(new Vector(0, random.integer(offset.min, offset.max)), moveSpeed).toPromise();
			await actions.moveTo(this.initPos, moveSpeed).toPromise();
		});
	}

	async throwCan() {
		this.graphics.layers.get('throw').use(<Animation>canThrowAnimation.getAnimation('throw'), {
			anchor: Vector.Half,
		});
		this.canAnimation();
		await canThrowAnimation.play('throw');
		this.addGraphics();
		this.graphics.layers.get('can').hide();
	}

	async canAnimation() {
		await gameController.waitFor((1000 / 20) * 5);
		const startX = -160 * gameController.pixelRatio;
		const startY = -140 * gameController.pixelRatio;
		let startPos = new Vector(startX, startY);
		const canLayer = this.graphics.layers.create({
			name: 'can',
			order: 0,
			offset: startPos,
		});
		canLayer.use(<Sprite>res.graphics.boat.getFrameSprite('assets/wake/boat/can'), {
			anchor: Vector.Half,
		});
		easyTween(progress => {
			startPos.x = startX - progress * 120 * gameController.pixelRatio;
			startPos.y = startY + progress * 30 * gameController.pixelRatio;
		}, (1000 / 20) * 5);
	}

	private addGraphics() {
		this.graphics.use(<Sprite>res.graphics.boat.getFrameSprite('assets/wake/boat/boat_throw-anim_boat_1'));
		this.graphics.layers
			.create({
				name: 'throw',
				order: 0,
				offset: new Vector(-130, -116).scaleEqual(gameController.pixelRatio),
			})
			.use(<Sprite>res.graphics.boat.getFrameSprite('assets/wake/boat/dude/dude_throw_0010'), {
				anchor: Vector.Half,
			});
	}
}
