
import { defineComponent } from 'vue';
import soundManager from '../../games/src/partials/sound-manager';
import Sound from '../components/Sound.vue'

export default defineComponent({
    name: 'TopBar',
    components: {
        soundManager,
        Sound
    },
    data() {
        return {
            soundMute: false,
        }
    },
    props: {
      global: Boolean,
      progress: String,
    }
});
