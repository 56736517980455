import { ActorArgs, ScreenElement, Sprite, Vector } from 'excalibur';
import { res } from '../res';
import { easeInOutCirc, easyTween } from '../../../utils';

export default class Chakra extends ScreenElement {
	constructor(props: ActorArgs = {}, public readonly stateId: number) {
		super({
			...props,
		});
	}

	disable() {
		const sprite = <Sprite>res.graphics.ui.getFrameSprite(`assets/yoga/UI/chakras/${this.stateId}_white`);
		this.graphics.use(sprite, {
			anchor: Vector.Half,
		});
	}

	async activate() {
		const overSprite = <Sprite>res.graphics.ui.getFrameSprite(`assets/yoga/UI/chakras/${this.stateId}_w`);

		this.graphics.use(<Sprite>res.graphics.ui.getFrameSprite(`assets/yoga/UI/chakras/${this.stateId}`), {
			anchor: Vector.Half,
		});

		this.graphics.layers
			.create({
				name: 'over',
				order: 0,
			})
			.use(overSprite, {
				anchor: Vector.Half,
			});

		await easyTween(progress => {
			overSprite.opacity = easeInOutCirc(1 - progress);
		}, 500);

		this.graphics.layers.get('over')?.hide();
	}
}
