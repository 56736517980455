
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import TopBar from '@/components/TopBar.vue';
import PromocodeFooter from '@/components/PromocodeFooter.vue';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SOUND_SCENES } from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';

gsap.registerPlugin(ScrollTrigger);
// const input = ref(null);
export default defineComponent({
    name: 'PromoCodePicnicToYogaView',
    components: {
        TopBar,
        LetoButton,
        ImageBox,
        ImageCdn,
        PromocodeFooter,
        soundManager,
    },

    setup() {
        const prmcde = ref();
        const infobbl = ref();
        const router = useRouter();
        return {
            prmcde,
            infobbl,
            router
        }
    },
    methods: {
        next() {
            this.router.push({
                path: ROUTES.YOGA
            });
        },
    },
    mounted() {
        soundManager.play('photo2')
        soundManager.load(SOUND_SCENES.YOGA_INTRO);
        var pole = this.prmcde;
        // var prmcdetext = this.prmcde.value;
        var bbl = this.infobbl;
        // console.log(prmcdetext);
        pole.addEventListener('click', function () {
            var prmcdetext = pole.value;
            navigator.clipboard.writeText(prmcdetext);
            pole.classList.add('done');
            // bbl.classList.add('active');
            setTimeout(() => {
                pole.classList.remove('done');
                // bbl.classList.remove('active');
            }, 2500);
        });
    },
    unmounted() {
        soundManager.stopAll(250);
        ScrollTrigger.killAll();
    },
});
