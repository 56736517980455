import JSONSpriteSheet from '../../partials/json-spritesheet';
import { Sound } from 'excalibur';
import { getAssetUrl } from '../../utils';

export const res = {
	assets: new JSONSpriteSheet(getAssetUrl('./assets/catch/assets.json'), getAssetUrl('./assets/catch/2x/assets.json')),
	sound: {
		music: new Sound(getAssetUrl('./assets/sound/03_game_musor.mp3')),
		error: new Sound(getAssetUrl('./assets/sound/fx/Garbage/Gbg_Error.mp3')),
		garbage1: new Sound(getAssetUrl('./assets/sound/fx/Garbage/Grbg1.mp3')),
		garbage2: new Sound(getAssetUrl('./assets/sound/fx/Garbage/Grbg2.mp3')),
		garbage3: new Sound(getAssetUrl('./assets/sound/fx/Garbage/Grbg3.mp3')),
		garbage4: new Sound(getAssetUrl('./assets/sound/fx/Garbage/Grbg4.mp3')),
	},
};
