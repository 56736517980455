import { Vector } from 'excalibur';

export default {
	trapStartPosition: new Vector(-10, -105),
	progressTime: 72500 * 0.75,
	splashScreenTime: 15000,
	splashFadeDuration: 25,
	successCoolDown: 10000,
	failCountToFailState: 3,
	failTimeToFailState: 3000,
};
