import JSONSpriteSheet from '../../partials/json-spritesheet';
import { Sound } from 'excalibur';
import { getAssetUrl } from '../../utils';

export const res = {
	graphics: {
		ui: new JSONSpriteSheet(getAssetUrl('./assets/yoga/ui.json'), getAssetUrl('./assets/yoga/2x/ui.json')),
		instructor: new JSONSpriteSheet(getAssetUrl('./assets/yoga/instructor.json'), getAssetUrl('./assets/yoga/2x/instructor.json')),
		player: new JSONSpriteSheet(getAssetUrl('./assets/yoga/maxim.json'), getAssetUrl('./assets/yoga/2x/maxim.json')),
		bg: new JSONSpriteSheet(getAssetUrl('./assets/yoga/bg.json'), getAssetUrl('./assets/yoga/2x/bg.json')),
	},
	sound: {
		music: new Sound(getAssetUrl('./assets/sound/07_game_yoga.mp3')),
		chakra: new Sound(getAssetUrl('./assets/sound/fx/Yoga/Chakra.mp3')),
		note1: new Sound(getAssetUrl('./assets/sound/fx/Yoga/Note1.mp3')),
		note2: new Sound(getAssetUrl('./assets/sound/fx/Yoga/Note2.mp3')),
		note3: new Sound(getAssetUrl('./assets/sound/fx/Yoga/Note3.mp3')),
		note4: new Sound(getAssetUrl('./assets/sound/fx/Yoga/Note4.mp3')),
		note5: new Sound(getAssetUrl('./assets/sound/fx/Yoga/Note5.mp3')),
	},
};
