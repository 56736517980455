import { ActorArgs, Engine, ScreenElement, Shape, Vector } from 'excalibur';
import { EVENTS } from '../enums';
import { InputEvent } from '../partials/custom-events';
import { InputItem } from './input-item';
import gameController from '../../../partials/games-controller';

export class InputField extends ScreenElement {
	onInitialize(_engine: Engine) {
		super.onInitialize(_engine);

		this.pos = new Vector(gameController.halfDrawWidth, gameController.drawHeight - 90 * gameController.pixelRatio);

		this.addItems();
		this.registerEvents();
	}

	private registerEvents() {
		this.scene.eventDispatcher.on(EVENTS.PLAYER_POSE, e => this.setGraphics((<InputEvent>e).indexId));
		this.scene.eventDispatcher.on(EVENTS.INSTRUCTOR_POSE, e => this.setGraphics((<InputEvent>e).indexId));
	}

	private setGraphics(index: number) {
		Array.from(this.children.entries()).filter(([, en]) => {
			const item = <InputItem>en;

			if (index === item.indexId) item.activateFx();
		});
	}

	private addItems() {
		const items: [ActorArgs, (item: InputItem) => void][] = [
			[
				{
					pos: new Vector(-100, 50).scaleEqual(gameController.pixelRatio),
					z: 1,
				},
				item =>
					item.collider.useBoxCollider(
						50 * gameController.pixelRatio,
						50 * gameController.pixelRatio,
						Vector.Half,
						new Vector(-40, 5).scaleEqual(gameController.pixelRatio),
					),
			],
			[
				{
					pos: new Vector(-55, 20).scaleEqual(gameController.pixelRatio),
					z: 2,
				},
				item => {
					item.collider.useCompositeCollider([
						Shape.Box(50 * gameController.pixelRatio, 50 * gameController.pixelRatio, Vector.Half, new Vector(-40, -35).scaleEqual(gameController.pixelRatio)),
						Shape.Box(40 * gameController.pixelRatio, 50 * gameController.pixelRatio, Vector.Half, new Vector(-20, 5).scaleEqual(gameController.pixelRatio)),
					]);
				},
			],
			[
				{
					pos: new Vector(0, 0).scaleEqual(gameController.pixelRatio),
					z: 3,
				},
				item => {
					item.collider.useCompositeCollider([Shape.Box(100 * gameController.pixelRatio, 160 * gameController.pixelRatio)]);
				},
			],
			[
				{
					pos: new Vector(55, 20).scaleEqual(gameController.pixelRatio),
					z: 2,
				},
				item => {
					item.collider.useCompositeCollider([
						Shape.Box(50 * gameController.pixelRatio, 50 * gameController.pixelRatio, Vector.Half, new Vector(40, -35).scaleEqual(gameController.pixelRatio)),
						Shape.Box(40 * gameController.pixelRatio, 50 * gameController.pixelRatio, Vector.Half, new Vector(20, 5).scaleEqual(gameController.pixelRatio)),
					]);
				},
			],
			[
				{
					pos: new Vector(100, 50).scaleEqual(gameController.pixelRatio),
					z: 1,
				},
				item =>
					item.collider.useBoxCollider(
						50 * gameController.pixelRatio,
						50 * gameController.pixelRatio,
						Vector.Half,
						new Vector(40, 5).scaleEqual(gameController.pixelRatio),
					),
			],
		];

		for (const [index, [args, callback]] of items.entries()) {
			const item = new InputItem(args, index + 1);
			callback && callback(item);
			this.addChild(item);
		}
	}

	reset() {
		// this.setGraphics(0);
	}
}
