<template>
  <div id="wrapperglb" class="picnic-wrapper wrapper-global-fix">
    <!-- push-wrapper -->
    <div class="push-wrapper">
      <!-- container-->
      <div class="container no-flex">
        <TopBar progress="0"/>
          <ImageCdn filename="picnic-001-6.webp" class="adaptive-first-image-container" width="1046" height="561" />

      </div>
      <!-- /container -->
      <!-- container-->
      <div class="container second-container">
        <div class="wrapper">
          <ImageCdn filename="picnic-002.webp" width="500" height="374" />
          <Comment class="answer" text="Всем привет! Что тут случилось?"/>
          <Comment class="kote" text="Да мы сами не поняли! Всё было так:"/>
          <!-- slide-side__wrapper -->
          <div class="slide-side__wrapper polaroid-slider">
            <!-- slide-side__item -->
            <div class="slide-side__item slide-side__1">
              <PolaroidPhoto img="image-02-02.webp" turn="left"/>
              <Comment class="picnic-girl" text="Мы заказали продукты от Самоката, и Коте начал готовить закуски."/>
            </div>
            <!-- /slide-side__item -->
            <!-- slide-side__item -->
            <div class="slide-side__item slide-side__2">
              <PolaroidPhoto img="image-02-02-1.webp" turn="right"/>
              <Comment class="picnic-girl" text="Пока Коте занимался готовкой, мы веселились и фотографировались,"/>
            </div>
            <!-- /slide-side__item -->
            <!-- slide-side__item -->
            <div class="slide-side__item slide-side__3">
              <PolaroidPhoto img="image-02-02-2.webp" turn="left"/>

              <Comment class="picnic-girl"
                       text="как вдруг мимо пронёсся вихрь. Вся еда пропала, а на скатерти остался беспорядок!"/>
            </div>
            <!-- /slide-side__item -->
          </div>
          <!-- /slide-side__wrapper -->
          <ImageCdn filename="picnic-003.webp" width="716" height="536" />

          <Comment class="picnic-girl" text="Возможно, что-то видно на последнем снимке, но мы не можем его найти."/>

          <Comment class="answer" text="Может, помочь вам найти фотографию?"/>

          <div ref="glbwrp" class="img-block m-t-20 picnic-cleanup">
            <img alt="" src="../assets/img/image-02-04.webp"/>
            <img alt="" class="maks-sticker__up" src="../assets/img/image-02-05.webp"/>
          </div>
          <div class="wrapper-wide btn-row">
            <button class="btn-next" @click="next">Помочь ребятам</button>
          </div>
        </div>
      </div>
      <!-- /container -->
      <div id="triggerto" ref="triggerto"></div>
    </div>
    <!-- / push-wrapper -->
  </div>
</template>
<script lant="js">
import {ref, defineComponent, nextTick, triggerRef} from 'vue';
import {useRouter} from "vue-router";
import {ROUTES} from "@/preroutes";
import TopBar from '@/components/TopBar.vue';
import LetoButton from '@/components/LetoButton.vue';
import Comment from '@/components/Comment.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import TextBox from '@/components/TextBox.vue';
import PolaroidPhoto from '@/components/PolaroidPhoto';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gameController from "../../games/src/partials/games-controller";
import {SCENES} from "../../games/src/enums";
import {GAME_EVENTS} from "../../games/src/enums";
import {SOUND_SCENES} from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';
import {useLoaderStore} from "@/store";


let anim;

// let trdot = document.querySelector('#triggerto');
// let isLoaded = false;
gsap.registerPlugin(ScrollTrigger);

export default defineComponent({
  name: 'PicnicView',
  components: {
    TopBar,
    LetoButton,
    Comment,
    ImageBox,
    ImageCdn,
    TextBox,
    PolaroidPhoto,
    soundManager,
  }, data() {
    return {
      observer: null,
      isReached: false,
      isLoaded: false,
    }
  },
  setup() {
    const loader = useLoaderStore()
    const router = useRouter();
    const glbwrp = ref();
    const triggerto = ref();
    return {
      router,
      glbwrp,
      loader
    }
  },
  methods: {
    async next() {
      await soundManager.stopAll(250);
      exponea.track('summer_samokat', {
        event_action: 'summer_game_instruction_picnic'
      });
      if (this.isLoaded) {
        this.router.push({
          path: ROUTES.PICNIC_GAME,
        });
      }else {
        this.router.push({
          path: ROUTES.LOADER
        })
      }
    },
    doScroll(event) {
      const scrollHeight = event.target.scrollHeight; //полная высота контейнера
      const scrollTop = event.target.scrollTop;
      const clientHeight = event.target.clientHeight;
      const triggerPoint = scrollHeight * 0.8;
      console.log(scrollHeight);
      console.log(triggerPoint);
      if (scrollTop >= triggerPoint) {
        if (this.isReached == false) {
          alert('Done! dsfsdfsdhfjsdhfsdjkhfsjfhsdjkfhsdjfhdsjkhk');
          this.isReached = true;
        }
      }
    },
    scrollTrigger() {
      // var obj = trg.trigger.id;
      observer.observe(document.querySelector('#trigger'));
      // console.log(obj);
      // console.log(observer);
      // console.log(obj);
    },
    expCall() {
      alert('Done!');
    },

  },
  async mounted() {
    // this.glbwrp.addEventListener('scroll', this.doScroll);
    // new IntersectionObserver(this.expCall(), {
    //     root: this.glbwrp,
    //     // rootMargin: "0px",
    //     threshold: 0.9,
    // });

    this.observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // alert("The target element enters the visible area.");
              exponea.track('summer_samokat', {
                event_action: 'summer_scroll_picnic'
              });
              // alert("Sent data v.2");
            }
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0,
        }
    );
    nextTick(() => {
      this.observer.observe(triggerto);
      // console.log(this.observer);
    });

    // this.handleIntersect();

    soundManager.play('picnicIntroDrums', true, 0.8);
    this.loader.scene = SCENES.CATCH
    this.loader.route = ROUTES.PICNIC_GAME
    this.loader.promise = gameController.prepareScene(SCENES.CATCH)
    this.loader.promise.then(() => {
      gameController.activateScene(SCENES.CATCH);
      this.isLoaded = true;
    });
    //   gsap.to(".slide-side__1", {
    //         scrollTrigger: {
    //             trigger: ".slide-side__1",
    //             scroller: "#wrapperglb",
    //             start: "top center", // the default values
    //             end: "bottom center",
    //             scrub: true,
    //             // markers: true,
    //         },
    //         // duration: 2,
    //         marginLeft: "0%",
    //         opacity: 1,
    //     });
    //     gsap.to(".slide-side__2", {
    //         scrollTrigger: {
    //             trigger: ".slide-side__2",
    //             scroller: "#wrapperglb",
    //             start: "top center", // the default values
    //             end: "bottom center",
    //             scrub: true,
    //             // markers: true,
    //         },
    //         // duration: 2,
    //         marginLeft: "0%",
    //         opacity: 1,
    //     });
    //     gsap.to(".slide-side__3", {
    //         scrollTrigger: {
    //             trigger: ".slide-side__3",
    //             scroller: "#wrapperglb",
    //             start: "top center", // the default values
    //             end: "bottom center",
    //             scrub: true,
    //             // markers: true,
    //         },
    //         // duration: 2,
    //         marginLeft: "0%",
    //         opacity: 1,
    //     });
    // this.anim = gsap.to(".maks-sticker__up", {
    //     scrollTrigger: {
    //         trigger: ".picnic-cleanup",
    //         scroller: "#wrapperglb",
    //         start: "top center", // the default values
    //         end: "bottom center",
    //         scrub: true,
    //         // markers: true,
    //     },
    //     // duration: 2,
    //     marginBlockStart: "-55%",
    //     // opacity: 1,
    // });
  },
  unmounted() {
    // this.glbwrp.removeEventListener(this.doScroll);
    // this.anim.kill();
    soundManager.stopAll(250);
    // ScrollTrigger.killAll();
  },
});
</script>
<style scoped>
.adaptive-first-image-container.fix-under-topbar {
  height: 90vh;
  max-height: 90vh;
  margin-block-start: -100px;
}

.polaroid-slider {
  margin-top: 30px;
}

.p-slider__slide {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  /* object-fit: cover; */
}

#wrapperglb.picnic-wrapper {
  background-color: var(--bg-light-green-color);
}

.slide-side__item.slide-side__1 .comment {
  margin-top: 45px;
}

.slide-side__item.slide-side__2 .comment {
  margin-top: 30px;
}

.slide-side__item.slide-side__3 .comment {
  margin-top: 55px;
}

.p-slider__slide:not(.s4) {
  /* margin-block-start: -20%; */
  margin-block-end: -43%;
}

.p-slider__slide img {
  margin-inline: auto;
}

.p-slider__slide.s1 {
  z-index: 70;
  margin-block-end: -38%;
}

.p-slider__slide.s2 {
  z-index: 80;
  /* margin-block-end: -9%; */
}

.p-slider__slide.s3 {
  z-index: 90;
  /* margin-block-end: -22.5%; */
}

.p-slider__slide.s4 {
  /* margin-block-start: -23%; */
  z-index: 100;
}

.bottom-picnic-outro-one .maks-sticker__up {
  /* position: absolute;
  top: 70%; */
  /* left: calc(50% - 158px); */
  /* margin-block-start:0 ; */
}

.picnic-cleanup .maks-sticker__up {
  height: 254px;
  margin-top: -95px;
}

.slide-side__item {
  /* padding-block: 25px; */
  /* opacity: 0; */
  width: 100%;
  /* position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  height: 100%; */
}

.slide-side__item.slide-side__1 {
  /* margin-left: 140%; */
  margin-top: 45px;
  z-index: 20;
}

.slide-side__item.slide-side__2 {
  /* left: 110%;
  opacity: 0; */
  /* margin-left: -140%; */
  margin-top: 32px;
  z-index: 30;
}

.slide-side__item.slide-side__3 {
  /* left: 110%;
  opacity: 0; */
  /* margin-left: 140%; */
  margin-top: 57px;
  z-index: 40;
}

/* .slide-side__item.slide-side__1 .comment,
.slide-side__item.slide-side__2 .comment {
    opacity: 1;
} */

.slide-side__item.slide-side__1 img {
  transform: rotate(-12deg);
}

.slide-side__item.slide-side__2 img {
  transform: rotate(-2deg);
}

.slide-side__item.slide-side__3 img {
  transform: rotate(17deg);
}
</style>
