<template>
        <div class="popup" ref="popupblck">
            <img :src="`${ filePath }${ image }`" class="popup-img" alt="" />
            <p>{{ text }}</p>
            <div class="popup-close" @click="close"></div>
        </div>
</template>
<script lang="js">
import { ref, defineComponent } from 'vue';
import { SOUND_SCENES } from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';

export default defineComponent({
    name: 'PopUpBlock',
    components: {
        soundManager,
    },
    props: {
        image: String,
        text: String,
    },
    setup() {
        const popupblck = ref();
        return {
            popupblck,
        }
    },
    data() {
        return {
            scrollT: 0,
            filePath: process.env.VUE_APP_IMAGE_PATH,
        }
    },
    methods: {
        worker(event) {
                setTimeout(() => {
                    this.popupblck && this.popupblck.classList.add('show');
                    soundManager.play('vibration', false);
                }, 500);
                setTimeout(() => {
                    this.popupblck && this.popupblck.classList.remove('show');
                    soundManager.stop('vibration', false);
                }, 5500);
        },
        close() {
            this.popupblck.classList.remove('show');
            soundManager.stop('vibration', false);
        },

    },
    mounted() {
        // console.log(this.popupblck);
        soundManager.load(SOUND_SCENES.PICNIC_INTRO);
        this.worker();
    },
    unmounted() {
        // soundManager.stopAll(250);
        // console.log('leave page');
    },
    
});
</script>
<style scoped>
    .popup-wrapper {
        position: fixed;
        top: 20px;
        left: 0;
        right: 0;
    }

    .popup {
        position: fixed;
        top: -200px;
        left: calc(50vw - 175px);
        display: flex;
        max-width: 350px;
        flex-wrap: nowrap;
        align-items: center;
        gap: 10px;
        background-color: var(--white-color);
        padding: 16px 20px;
        border-radius: 16px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
        transition: top 0.7s ease-out;
        cursor: pointer;
        z-index: 10000;
    }

    .popup.show {
        top: 20px;
        transition: top 0.7s ease-in;
    }

    .popup-img {
        max-width: 50px;
    }

    .popup-wrapper p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }
    .popup-close {
        width: 20px;
        height: 20px;
        background-image: url(../assets/img/close-popup.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;
    }
    @media only screen and (max-width: 450px) {
        .popup {
            left: 20px;
            right: 20px;
            max-width: unset;
        }
    }
</style>