import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sound = _resolveComponent("Sound")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["game-bar", { "global": _ctx.global }])
  }, [
    _createVNode(_component_Sound)
  ], 2))
}