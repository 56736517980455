import { Actor, Timer } from 'excalibur';
import Splash3 from './splash-screens/splash3';
import Splash2 from './splash-screens/splash2';
import Splash1 from './splash-screens/splash1';
import Splash from './splash';
import config from '../config';

export default class SplashScreen extends Actor {
  private splash = 0;
  private timer!: Timer;
  private scenes!: Splash[];

  constructor() {
    super({
      z: -1,
    });

    this.scenes = [new Splash2(), new Splash1(), new Splash3()];

    for (const scene of this.scenes) {
      this.addChild(scene);
    }
  }

  onInitialize() {
    (<Splash>this.scenes[0]).init();
  }

  start() {
    this.addTimer();

    (<Splash>this.scenes[this.splash]).start(false);
  }

  stop() {
    this.timer.stop();
  }

  success() {
    for (const [, child] of this.scenes.entries()) {
      (<Splash>child).success();
    }
  }

  fail() {
    for (const [, child] of this.scenes.entries()) {
      (<Splash>child).fail();
    }
  }

  async showSuccess() {
    this.timer.stop();
    this.timer.interval = 6000;
    this.timer.start();
    await (<Splash>this.scenes[this.splash]).hide();
    this.splash = 2;
    await (<Splash>this.scenes[this.splash]).start();
  }

  private addTimer() {
    this.timer = new Timer({
      repeats: true,
      fcn: () => this.showNextSplash(),
      interval: config.splashScreenTime,
    }).start();

    this.scene.add(this.timer);
  }

  private async showNextSplash() {
    this.timer.interval = config.splashScreenTime;
    await (<Splash>this.scenes[this.splash]).hide();

    this.splash = ++this.splash % (this.scenes.length - 1);
    await (<Splash>this.scenes[this.splash]).start();
  }
}
