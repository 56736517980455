import JSONSpriteSheet from '../../partials/json-spritesheet';
import { Sound } from 'excalibur';
import { getAssetUrl } from '../../utils';

export const res = {
	graphics: {
		ui: new JSONSpriteSheet(getAssetUrl('./assets/wake/ui.json'), getAssetUrl('./assets/wake/2x/ui.json')),
		player: new JSONSpriteSheet(getAssetUrl('./assets/wake/player.json'), getAssetUrl('./assets/wake/2x/player.json')),
		boat: new JSONSpriteSheet(getAssetUrl('./assets/wake/boat.json'), getAssetUrl('./assets/wake//2x/boat.json')),
		bg: new JSONSpriteSheet(getAssetUrl('./assets/wake/bg.json'), getAssetUrl('./assets/wake/2x/bg.json')),
		fx: new JSONSpriteSheet(getAssetUrl('./assets/wake/fx-animations.json'), getAssetUrl('./assets/wake/2x/fx-animations.json')),
		tricks: new JSONSpriteSheet(getAssetUrl('./assets/wake/tricks.json'), getAssetUrl('./assets/wake/2x/tricks.json')),
	},
	sound: {
		music: new Sound(getAssetUrl('./assets/sound/05_game_board.mp3')),
		fail: new Sound(getAssetUrl('./assets/sound/fx/Board/Board_fall.mp3')),
		start: new Sound(getAssetUrl('./assets/sound/fx/Board/Board_start.mp3')),
		trick: new Sound(getAssetUrl('./assets/sound/fx/Board/Board_trick.mp3')),
	},
};
