import { Resource } from 'excalibur';
import srtParser2 from 'srt-parser-2';

export default class SRTLoader extends Resource<JSON> {
	private parser = new srtParser2();

	constructor(path: string) {
		super(path, 'text');
	}

	async load(): Promise<JSON> {
		if (this.data) return this.data;

		const srt = await super.load();

		// @ts-ignore
		return (this.data = <JSON>this.parser.fromSrt(<string>srt));
	}
}
