import { Sound } from 'excalibur';
import { easyTween } from '../utils';
import { GAME_EVENTS } from '../enums';

export default class GameSound<T> {
	soundEnabled = true;

	constructor(protected readonly sound: { [key: string]: Sound }) {
		// @ts-ignore
		window.addEventListener(GAME_EVENTS.SOUND_TOGGLE, (e: CustomEvent) => {
			this.soundEnabled = e.detail.soundEnabled;

			for (const [name] of Object.entries(this.sound)) {
				// @ts-ignore
				this.volume(name, this.soundEnabled ? 1 : 0);
			}
		});

		document.addEventListener('visibilitychange', () => {
			if (this.soundEnabled) {
				if (document.visibilityState === 'visible') this.unPauseAll();
				else this.pauseAll();
			}
		});
	}

	pause(name: keyof T) {
		this.sound[<string>name].pause();
	}

	pauseAll() {
		for (const [name] of Object.entries(this.sound)) {
			// @ts-ignore
			this.pause(name);
		}
	}

	unPauseAll() {
		for (const [name] of Object.entries(this.sound)) {
			// @ts-ignore
			this.sound[name].isPaused() && this.sound[name].play();
		}
	}

	loop(name: keyof T) {
		this.sound[<string>name].loop = true;
	}

	unLoop(name: keyof T) {
		this.sound[<string>name].loop = false;
	}

	mute(name: keyof T) {
		this.volume(name, 0);
	}

	muteAll() {
		for (const [name] of Object.entries(this.sound)) {
			// @ts-ignore
			this.mute(name);
		}
	}

	unMute(name: keyof T) {
		this.volume(name, 1);
	}

	unMuteAll() {
		for (const [name] of Object.entries(this.sound)) {
			// @ts-ignore
			this.unMute(name);
		}
	}

	play(name: keyof T, loop = false, vol = 1) {
		const sound = <Sound>this.sound[<string>name];
		sound.loop = loop;
		this.volume(name, vol);
		return sound.play();
	}

	stop(name: keyof T, duration: number | null = null): Promise<void> {
		return new Promise(async resolve => {
			if (duration) {
				const sound = this.sound[<string>name];
				const initVolume = sound.volume;

				if (!sound.isPlaying()) return resolve();

				await easyTween(progress => {
					this.volume(name, initVolume - initVolume * progress);
				}, duration);
				sound.stop();
				resolve();
			} else {
				this.sound[<string>name].stop();
				resolve();
			}
		});
	}

	stopAll(duration: number | null = null): Promise<void> {
		return new Promise(async resolve => {
			await Promise.all([
				...Object.keys(this.sound).map(key => {
					// @ts-ignore
					return this.stop(key, duration);
				}),
			]);

			resolve();
		});
	}

	playbackRate(name: keyof T, rate: number) {
		this.sound[<string>name].playbackRate = rate;
	}

	volume(name: keyof T, vol: number) {
		this.sound[<string>name].volume = this.soundEnabled ? vol : 0;
	}

	duration(name: keyof T) {
		return this.sound[<string>name].duration;
	}

	progress(name: keyof T) {
		return (<Sound>this.sound[<string>name]).getPlaybackPosition();
	}

	seek(name: keyof T, position: number) {
		(<Sound>this.sound[<string>name]).seek(position);
	}

	isPlaying(name: keyof T) {
		return (<Sound>this.sound[<string>name]).isPlaying();
	}

	crossfade(name1: keyof T, name2: keyof T, value = 0.5, loop = false, forceStart = true) {
		value = Math.max(Math.min(1, value), 0);

		const track1: Sound = this.sound[<string>name1];
		const track2: Sound = this.sound[<string>name2];

		if (forceStart) {
			track1.play();
			track2.play();
		}

		this.volume(name1, 1 - value);
		this.volume(name2, value);

		if (loop) {
			this.loop(name1);
			this.loop(name2);
		}
	}
}
