import { Entity, Timer, Vector } from 'excalibur';
import { random } from '../../../utils';
import Trash from './trash';
import gameController from '../../../partials/games-controller';
import { config } from '../config';
import Catch from '../catch';
import { TRASH_TYPE } from '../enums';

export default class Bin extends Entity {
  private timer!: Timer;
  private isBoosted = false;
  private canBoost = true;

  onInitialize() {
    this.timer = new Timer({
      fcn: () => this.sendNewObject(),
      interval: 1000,
      repeats: true,
      numberOfRepeats: config.levels[(<Catch>this.scene).currentLevel].count,
    });

    this.scene.add(this.timer);
  }

  start() {
    this.timer.maxNumberOfRepeats = config.levels[(<Catch>this.scene).currentLevel].count;
    this.timer.start();
  }

  stop() {
    this.timer.stop();
  }

  boost() {
    this.isBoosted = true;
    this.timer.interval = 1500;
  }

  unboost() {
    this.isBoosted = false;
    this.timer.interval = 1000;
  }

  reset() {
    this.canBoost = true;
  }

  private sendNewObject() {
    const boosted = this.timer.timesRepeated / this.timer.maxNumberOfRepeats > 0.5 && this.canBoost;
    let objectType = boosted ? TRASH_TYPE.RECYCLE4 : random.integer(0, (<Catch>this.scene).currentLevel);
    const area: { min: number; max: number } = config.areas[<TRASH_TYPE>objectType];

    if (boosted) this.canBoost = false;

    const trash = new Trash(
      {
        pos: new Vector(random.integer(area.min, area.max) * gameController.pixelRatio, -gameController.screen.halfDrawHeight - 50),
      },
      objectType,
    );

    if (this.isBoosted) trash.boost();

    this.scene.add(trash);
  }
}
