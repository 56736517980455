
// @ts-nocheck
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'PreLoader',
  components: {},
  props: {
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      timeout: 0,
      text: '',
      hideLoader: true,
    }
  },
  methods: {
    accept() {
      this.hideLoader = false;
    },
    changeText(i) {
      const text = ['готовим приключение', 'придумываем саундтрек', 'рисуем графику', 'пишем код',]
      i = i % text.length
      this.text = text[i]
      this.timeout = setTimeout(() => this.changeText(++i), 1000)
    }
  },
  mounted() {
    let i = 0

    this.changeText(i)
  },
  unmounted() {
    clearTimeout(this.timeout)
  }
})
