
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import BubbleRule2 from '@/components/BubbleRule2.vue';
import GameBar from '@/components/GameBar.vue';
import gameController from "../../games/src/partials/games-controller";
import { SCENES } from "../../games/src/enums";
import { GAME_EVENTS } from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';

let promice;
var text;
export default defineComponent({
    name: 'SurfGame',
    components: {
        GameBar,
        LetoButton,
        BubbleRule2,
    },
    setup() {
        // const wrppr = ref();
        const router = useRouter();
        return {
            // wrppr,
            router
        }
    },
    data() {
        return {
            showWrpr: true,
            textBefore: "",
            textAfter: "",
            mapImage: "",
            mapImage2: "",
            exponea: "summer_game_lake"
            // showBar: false,
        };
    },
    created() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent,)) {
            this.textBefore = 'Нажимайте кнопки стрелок на экране и постарайтесь удержать равновесие.';
            this.textAfter = 'Если сможете продержаться в зелёной зоне несколько секунд, Максим сделает трюк.';
            this.mapImage = "help-surf-game-mobile.webp";
            this.mapImage2 = "help-surf-game-range.webp";
        } else {
            this.textBefore = 'Нажимайте кнопки стрелок на клавиатуре и постарайтесь удержать равновесие.';
            this.textAfter = 'Если сможете продержаться в зелёной зоне несколько секунд, Максим сделает трюк. После трёх трюков игра закончится.';
            this.mapImage = "help-surf-game-desktop.webp";
            this.mapImage2 = "help-surf-game-range.webp";
        }
    },
    methods: {
        // startGame() {
        //     this.showWrpr = false;
        //     // this.showBar = true;
        //     gameController.startCurrentScene();
        // },
        endGame() {
            setTimeout(() => {
                // this.showWrpr = true;
                // this.showBar = false;

                document.querySelector('canvas')?.classList.remove('show');
                this.router.push({
                    path: ROUTES.SURF_OUTRO
                });
            }, 1500);
        }
    },
    async mounted() {
        gameController.startCurrentSceneMusic();
        document.querySelector('canvas')?.classList.add('show');
        // promice = await gameController.prepareScene(SCENES.WAKE);
        // gameController.activateScene(SCENES.WAKE);
        // gameController.startCurrentScene();
        // console.log(promice);
        document.querySelector('canvas')?.addEventListener(GAME_EVENTS.GAME_OVER, () => {
            this.endGame();
        }, {
          once: true
        });

    }, unmounted() {
    },
});
