<template>
    <div class="wrapper-global-fix" id="wrapperglb">
        <!-- push-wrapper -->
        <div class="push-wrapper">
        <!-- container-->
        <div class="container yoga-container">
                    <TopBar progress="1"/>
        </div>
        <!-- /container -->
        <!-- container-->
        <div class="container yoga-container">
            <div class="wrapper">
                <ImageCdn filename="yoga-outro-001.webp" width="1000" height="752" class=""/>
                <Comment class="answer" text="После тренировки мне правда стало лучше: чувствую прилив сил и ясность ума!" />
                <ImageCdn filename="yoga-outro-002.webp" class="maks-wow"/>
                <div class="comment-group">
                    <Comment class="answer" text="Понять бы теперь, куда направился похититель. Думай, Максим, думай…" />
                </div>
                <ImageCdn filename="yoga-outro-003.webp" class="shadow" />
                <div class="comment-group">
                    <Comment class="answer" text="Хмм… Я чувствую аромат вкусной еды." />
                    <Comment class="answer" text="А ещё слышу динамичную музыку." />
                    <Comment class="answer" text="И земля дрожит так, как будто кто-то танцует…" />
                    <Comment class="answer" text="Точно! Рядом же музыкальный фестиваль, а там всегда много еды." />
                </div>
                <ImageCdn filename="yoga-outro-004.webp" class="shadow" />
                <div class="comment-group">
                    <Comment class="answer" text="А вот и следы! Теперь я знаю, куда идти." />
                    <Comment class="answer" text="Спасибо тебе! После тренировки я стал очень сконцентрированным." />
                </div>
                <Comment class="yoga" text="Это тебе спасибо за приятную компанию." />
                <ImageCdn filename="yoga-outro-005.webp" class="shadow" />
                <Comment class="yoga" text="Кстати, держи шоколадку на меду от Самоката. В конце занятий я всегда раздаю такие ученикам. Правда часть шоколадок сегодня куда-то пропала." />
                <Comment class="answer" text="Вау, как приятно!" />
            </div>
        </div>
        <!-- /container -->
        <div class="wrapper-wide btn-row bg-green">
            <LetoButton @click="next" text="Продолжить" />
        </div>
            <div id="triggerto" ref="triggerto"></div>
        </div>
        <!-- / push-wrapper -->
    </div>
</template>

<script lang="js">
import { defineComponent } from 'vue'
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import Comment from '@/components/Comment.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import PopUpBlock from '@/components/PopUpBlock.vue';
import TopBar from '@/components/TopBar.vue'; // @ is an alias to /src
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import soundManager from '../../games/src/partials/sound-manager';
import { SOUND_SCENES } from "../../games/src/enums";

export default defineComponent({
    name: 'YogaOutroView',
    components: {
        TopBar,
        LetoButton,
        Comment,
        ImageBox,
        ImageCdn,
        soundManager,
        PopUpBlock,
    },
    data() {
        return {
            observer: null,
        }
    },
    setup() {
        const router = useRouter();
        return {
            router
        }
    },
    methods: {
        async next() {
            this.router.push({
                path: ROUTES.PROMOCODE_YOGA
            });
        },
        async playMusic() {
            await soundManager.play('yogaIntro', true);
        },
    },
    mounted() {
        this.playMusic();
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // alert("The target element enters the visible area.");
                    exponea.track('summer_samokat', {
                        event_action: 'summer_scroll2_yoga'
                    });
                    // alert("Sent data v.2");
                }
            });
        },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0,
            }
        );
        this.observer.observe(triggerto);
    },
     unmounted() {
        ScrollTrigger.killAll();
    },
})
</script>

<style scoped>
.yoga-container-first {
    background-color: var(--bg-blue-color);
}
.yoga-container {
    background-color: var(--bg-light-green-color);
}

.max-yoga-tired-1 img {
    margin-inline: 0;
    max-width: 50%;
}

.max-yoga-tired-2 img {
    margin-inline: 0;
    max-width: 50%;
    margin-left: auto;
}

.maks-wow :deep() img {
    height: 282px;
}
</style>
