import { SpriteEntity } from '../../../partials/sprite-entity';
import { easyTween } from '../../../utils';
import config from '../config';

export default abstract class Splash extends SpriteEntity {
	state: 'success' | 'fail' = 'success';

	async hide() {
		await easyTween(progress => (this.graphics.opacity = 1 - progress), config.splashFadeDuration);
		for (const layersKey of this.graphics.layers.currentKeys()) this.graphics.layers.get(layersKey).hide();
	}

	async start(fade = true) {
		if (fade) {
			this.graphics.opacity = 0;
			this.addGraphics();
			this.reset();
			if (this.state === 'success') this.success();
			if (this.state === 'fail') this.fail();
			await easyTween(progress => (this.graphics.opacity = progress), config.splashFadeDuration);
		}

		await easyTween(progress => this.process(progress), config.splashScreenTime);
	}

	init() {
		this.addGraphics();
		this.reset();
	}

	abstract addGraphics(): void;

	fail() {
		this.state = 'fail';
	}

	success() {
		this.state = 'success';
	}

	abstract process(progress: number): void;

	abstract reset(): void;
}
