<template>
    <div class="global-cover">
        <div class="global-cover__image">
          <div class="global-cover__image__wrapper">
            <img src="https://d8ee5427-d9ac-4e02-972a-fc031e943b6a.selcdn.net/img/samokat_summer_logo_white.webp" class="global-cover__image__logo" width="240" height="69px" alt="Самокат" />
            <picture>
              <source media="only screen and (max-width: 650px)" srcset="https://d8ee5427-d9ac-4e02-972a-fc031e943b6a.selcdn.net/img/splash-intro-desktop_v5-mob.webp">
              <img src="https://d8ee5427-d9ac-4e02-972a-fc031e943b6a.selcdn.net/img/splash-intro-desktop_v4.webp" width="390" height="506" class="cover-image__splash" alt=""/>
            </picture>
          </div>
        </div>
        <div class="global-cover__text__wrapper">
            <div class="global-cover__text">
                <h1>Квест-игра: Тайна исчезнувших продуктов</h1>
                <p>Отправляйтесь в новое приключение вместе с Максимом и пройдите три мини-игры — вы познакомитесь с крутыми ребятами, попробуете летние увлечения и даже проявите себя на сцене.</p>
                <p>А ещё получите приятные промокоды на заказ в Самокате.</p>
                <p class="sound-text">Для полного погружения включите звук или наденьте наушники.</p>
                <LetoButton :disabled="!this.assetsIsLoaded"  @click="start" :text="this.assetsIsLoaded ? 'Начать приключения' : 'Загрузка ...'" class="btn-white btn-cover" />
            </div>
        </div>
        <div class="global-cover_bg_color "></div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import LetoButton from '@/components/LetoButton.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import CoockieBar from '@/components/CoockieBar.vue';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import soundManager from '../../games/src/partials/sound-manager';
import { SOUND_SCENES } from "../../games/src/enums";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default defineComponent({
    name: 'CoverPage',
    components: {
      LetoButton,
      ImageBox,
      CoockieBar,
      ImageCdn,
    },
  data() {
    return {
      assetsIsLoaded: false,
      hideCover: true,
      imagePath: "",
    }
  },
  setup() {
    const router = useRouter();
    return {
      router,
    }
  },
  methods: {
    async start() {
      this.router.push({
        path: ROUTES.CITY,
      });
      exponea.track('summer_samokat', {
        event_action: 'summer_go'
      });
    }
  },
  mounted() {
    soundManager.load(SOUND_SCENES.PICNIC_INTRO).then(() => this.assetsIsLoaded = true);
  },
  unmounted() {
    ScrollTrigger.killAll();
    // console.log('leave page');
  },
})
</script>
<style scoped>
.global-cover {
    position: fixed;
    inset: 0;
    color: var(--white-color);
    z-index: 1000;
    /* padding: 5vh 15vw 10vh 5vw; */
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* gap: 10vw; */
    gap: 0;
    align-items: center;
    /* border: 5px solid white; */
}

/* .global-cover > div {
    border: 5px solid white;
} */

.global-cover_bg_color {
    position: fixed;
    inset: 0;
    background-color: var(--pink-color);
    z-index: -100;
}

.global-cover h1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 20px;
}

.global-cover__text__wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
}

.global-cover__text {
    max-width: 390px;
    /* width: 390px; */
}

.global-cover p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.global-cover p.sound-text {
  position: relative;
  padding-left: 44px;
}

.global-cover p.sound-text:before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 38px;
  height: 38px;
  background-image: url(../assets/img/charm-sound.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.global-cover p:not(:first-child) {
    margin-top: 22px;
}


.global-cover__image {
    /* height: 100%; */
    position: relative;
    text-align: center;
    z-index: -1;
}

.global-cover__image__wrapper {
  max-width: 550px;
  width: auto;
  margin-left: auto;
}

img.cover-image__splash {
    /* max-width: inherit;
    max-height: clac(100vh - 75px);
    height: clac(100vh - 75px); */
    /* max-width: 100%; */
    /* max-width: 75%; */
    /* max-width: 390px; */
    max-width: 550px;
    height: auto;
    width: auto;
    max-height: 100%;
    /* margin-inline: auto; */
    margin-inline: auto;
}

.btn-cover {
    width: 290px;
    margin-top: 40px;
}

.global-cover__bg {
    display: none;
    margin-top: -55px;
    height: 150px;
    background-image: url(../assets/img/cover-spirals.webp);
    background-position: top center 60%;
    background-repeat: no-repeat;
    background-size: 180%;
    z-index: 100;
}

.global-cover__image__logo {
  /* position: absolute; */
  max-width: 240px;
  width: 240px;
  left: calc(50% - 120px);
  margin-inline: auto;
}

@media only screen and (min-width: 1440px) {
    .global-cover {
        max-width: 1440px;
        margin-inline: auto;
    }
}

@media only screen and (max-width: 650px) {
    .global-cover {
        display: block;
        padding: 23px 0 0 0;
        overflow-y: auto;
        z-index: 500;
    }

    /* .global-cover__image {
        border-radius: 0 0 123px 123px;
        background-color: var(--white-color);
    } */

    /* .global-cover__image img {
        border-radius: 0 0 123px 123px;
    } */

    .global-cover__image__wrapper,
    img.cover-image__splash {
        max-width: 100%;
    }
    .global-cover__text {
        margin: 32px 20px 0 20px;
        /* max-width: 300px; */
    }

    .btn-cover {
        margin-top: 60px;
        margin-bottom: 60px;
        width: 100%;
        text-align: center;
    }

    .global-cover:after {
        content: none;
    }

    .global-cover__bg {
        display: block;
    }

    .global-cover__image__logo {
      margin-bottom: 13px;
    }
}</style>
