import { Actor, Animation, AnimationStrategy, Sprite, Vector } from 'excalibur';
import { res } from '../res';
import gameController from '../../../partials/games-controller';
import animations from '../animations';

export default class Opponent extends Actor {
	constructor() {
		super({
			pos: new Vector(0, 90).scaleEqual(gameController.pixelRatio),
			anchor: new Vector(0.5, 1),
		});
	}

	onInitialize() {
		this.addGraphics();
	}

	private addGraphics() {
		const garbage = <Animation>animations.getAnimation('garbage');
		garbage.pause();
		this.graphics.use(garbage);
	}

	private pushGarbage() {
		const garbage = new Actor();
		garbage.graphics.use(<Sprite>res.assets.getFrameSprite('assets/catch/assets/flying-garbage'));
		garbage.pos = new Vector(0, -100).scaleEqual(gameController.pixelRatio);
		garbage.vel = Vector.Up.scaleEqual(400 * gameController.pixelRatio);
		garbage.angularVelocity = 5;
		garbage.on('exitviewport', () => garbage.kill());
		this.scene.add(garbage);
	}

	private async pushGarbageAnimation() {
		await gameController.waitFor(250);
		this.pushGarbage();
		await gameController.waitFor(500);
		this.pushGarbage();
		await gameController.waitFor(500);
		this.pushGarbage();
	}

	async start() {
		const anim = <Animation>(<Animation>animations.getAnimation('garbage'));
		this.pushGarbageAnimation();
		anim.strategy = AnimationStrategy.Loop;
		anim.reset();
		anim.play();
		await gameController.waitFor(1000);
		anim.strategy = AnimationStrategy.Freeze;
	}
}
