import { ScreenElement, Sprite, Vector } from 'excalibur';
import { res } from '../res';
import gameController from '../../../partials/games-controller';

export default class UI extends ScreenElement {
	private lifeCount = 3;

	constructor() {
		super({
			pos: new Vector(12, 15).scaleEqual(gameController.pixelRatio),
			z: 10,
		});
	}

	onInitialize() {
		this.reset();
	}

	removeOneLife() {
		this.lifeCount--;
		this.graphics.use(<Sprite>res.assets.getFrameSprite(`assets/catch/assets/ui/health_${this.lifeCount}`));
	}

	reset() {
		this.lifeCount = 3;

		this.graphics.use(<Sprite>res.assets.getFrameSprite(`assets/catch/assets/ui/health_${this.lifeCount}`));
	}
}
