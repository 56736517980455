<template>
  <div v-if="showWrpr" id="wrapperglb" ref="wrppr" class="surf-wrapper wrapper-global-fix">
    <!-- push-wrapper -->
    <div class="push-wrapper">
      <!-- container-->
      <div class="container">
        <TopBar progress="1"/>
            <ImageCdn filename="surf-001-2.webp" class="adaptive-first-image-container" />
      </div>
      <!-- /container -->
      <!-- container -->
      <div class="container">
        <div class="wrapper">
          <div class="img-block m-t-20">
            <img alt="" class="shadow" src="../assets/img/surf-002.webp"/>
          </div>
          <Comment class="answer" text="Хей! Ребята!"/>
        </div>
      </div>
      <!-- container -->
      <!-- container -->
      <div class="container">
        <div class="wrapper">
          <div class="img-block m-t-20">
            <img alt="" class="shadow" src="../assets/img/surf-003.webp"/>
          </div>
          <Comment class="answer"
                   text="Привет, а можете подвезти меня к парню с этого фото? Кажется, он уплыл к другому берегу озера."/>
          <div class="img-block m-t-20">
            <img alt="" class="shadow" src="../assets/img/surf-004.webp"/>
          </div>
          <Comment class="surf-guy"
                   text="О, это же Стас Просто Класс! Давай прокатим тебя до него на вейк-сёрфе? А то места в катере уже нет."/>

          <Comment class="answer" text="Никогда не делал этого раньше, но давайте попробуем…"/>
          <div class="img-block m-t-20 surf-rock-up">
            <img alt="" class="shadow" src="../assets/img/surf-005.webp"/>
            <img alt="" class="maks-rock__up" src="../assets/img/maks-rock.webp"/>
          </div>
        </div>
      </div>
      <!-- container -->
      <!-- container -->
      <div class="container">
        <div class="btn-row">
          <button class="btn-next" @click="next">Продолжить</button>
        </div>
      </div>
      <!-- /container -->
      <PopUpBlock image="kote-popup.webp" text="Коте Оганезов лайкнул ваше фото"/>
      <div id="triggerto" ref="triggerto"></div>
    </div>
    <!-- / push-wrapper -->
  </div>
</template>
<script>
import {defineComponent, ref, onMounted} from 'vue';
import {useRouter} from "vue-router";
import {ROUTES} from "@/preroutes";
import LetoButton from '@/components/LetoButton.vue';
import TopBar from '@/components/TopBar.vue'; // @ is an alias to /src
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Comment from '@/components/Comment.vue';
import ImageBox from '@/components/ImageBox.vue';
import ImageCdn from '@/components/ImageCdn.vue';
import TextBox from '@/components/TextBox.vue';
import gameController from "../../games/src/partials/games-controller";
import {SCENES} from "../../games/src/enums";
import {GAME_EVENTS} from "../../games/src/enums";
import soundManager from '../../games/src/partials/sound-manager';
import {SOUND_SCENES} from "../../games/src/enums";
import PopUpBlock from '@/components/PopUpBlock.vue';
import {useLoaderStore} from "@/store";


gsap.registerPlugin(ScrollTrigger);

// let isLoaded = false;

export default defineComponent({
  name: 'SurfView',
  components: {
    TopBar,
    LetoButton,
    Comment,
    ImageBox,
    ImageCdn,
    TextBox,
    soundManager,
    PopUpBlock,
  },
  setup() {
    // const wrppr = ref();
    const router = useRouter();
    const loader = useLoaderStore()

    return {
      // wrppr,
      loader,
      router
    }
  },
  data() {
    return {
      showWrpr: true,
      isLoaded: false,
      observer: null,
      // showBar: false,
    };
  },
  methods: {
    async next() {
      await soundManager.stopAll(250);
      exponea.track('summer_samokat', {
        event_action: 'summer_game_instruction_lake'
      });
      if (this.isLoaded) {
        this.router.push({
          path: ROUTES.SURF_GAME
        });
      } else {
        this.router.push({
          path: ROUTES.LOADER
        })
      }
    },
  },
  async mounted() {
    soundManager.play('surfIntro', true);
    this.loader.scene = SCENES.WAKE
    this.loader.route = ROUTES.SURF_GAME
    this.loader.promise = gameController.prepareScene(SCENES.WAKE)
    this.loader.promise.then(() => {
      gameController.activateScene(SCENES.WAKE);
      // debugger
      this.isLoaded = true;
    });
    this.observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // alert("The target element enters the visible area.");
              exponea.track('summer_samokat', {
                event_action: 'summer_scroll1_lake'
              });
              // alert("Sent data v.2");
            }
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0,
        }
    );
    this.observer.observe(triggerto);
    // promice = await gameController.prepareScene(SCENES.YOGA);
    // gameController.activateScene(SCENES.YOGA);
    // gsap.to(".maks-rock__up", {
    //     scrollTrigger: {
    //         trigger: ".surf-rock-up",
    //         scroller: "#wrapperglb",
    //         start: "top center", // the default values
    //         end: "bottom center",
    //         scrub: true,
    //         // markers: true,
    //     },
    //     // duration: 2,
    //     marginTop: "-200px",
    //     // opacity: 1,
    // });
  },

  unmounted() {
    // ScrollTrigger.killAll();
  },
});
</script>
<style scoped>
#wrapperglb.surf-wrapper {
  background-color: var(--bg-blue-color-2);
}

.adaptive-first-image-container.restricted-500 {
  max-width: 100vw;
  margin: auto;
}

.adaptive-first-image-container.restricted-500 img {
  object-fit: cover;
}

.surf-first-gradient {
  /* padding-block-start: 30px; */
  padding-block-start: -130px;
  background: rgb(71, 207, 255);
  background: linear-gradient(180deg, rgba(71, 207, 255, 1) 60%, rgba(142, 195, 128, 1) 100%);
}

.surf-rock-up .maks-rock__up {
  position: relative;
  margin-top: -110px;
  height: 254px;
}


@media only screen and (max-width: 700px) {
  .adaptive-first-image-container.restricted-500 {
    max-width: inherit;
    margin: auto;
  }

  .adaptive-first-image-container.restricted-500 img {
    margin-left: -10%;
  }
}
</style>
