<template>
  <router-view v-slot="{ Component, route }">
    <Transition :name="route.meta.transition || 'route'" mode="out-in">

      <component :is="Component"></component>

    </Transition>
  </router-view>
  <ChangedOrientationInformer v-if="this.screenStore.wrongOrientation"/>
            <CoockieBar />
</template>

<script lang="js">

import { defineComponent } from 'vue';
import TopBar from '@/components/TopBar.vue';
import CoockieBar from '@/components/CoockieBar.vue';
import ChangedOrientationInformer from '@/components/ChangedOrientationInformer.vue';
import {GAME_EVENTS} from "../games/src/enums";
import {useScreenStore} from "@/store";

export default defineComponent({
  components: {
    TopBar,
    ChangedOrientationInformer,
    CoockieBar,
  },
  setup() {
    const screenStore = useScreenStore()

    return {
      screenStore
    }
  },
  mounted() {
    window.addEventListener(GAME_EVENTS.SCREEN_ORIENTATION_CHANGED, ({detail}) => {
      this.screenStore.wrongOrientation = detail.wrongOrientation
    })
  }
})
</script>


<style>
@font-face {
    font-family: 'Euclid';
     src: url('assets/fonts/EuclidCircularA-Regular-WebM.woff2') format('woff2'),
         url('assets/fonts/EuclidCircularA-Regular-WebM.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Euclid';
    src: url('assets/fonts/EuclidCircularA-Regular-WebM.woff2') format('woff2'),
         url('assets/fonts/EuclidCircularA-Regular-WebM.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Euclid';
    src: url('assets/fonts/EuclidCircularA-Medium-WebXL.woff2') format('woff2'),
         url('assets/fonts/EuclidCircularA-Medium-WebXL.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;

}


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
}

:root {
    --white-color: #FFFFFF;
    --black-color: #000;
    --gray-from-black: rgba(0,0,0,0.3);
    --bg-blue-color: #47CFFF;
    /* --bg-blue-color-2: #1CC0E5; */
    --bg-blue-color-2: #94C2D2;
    --bg-light-green-color: #8EC380;
    --bg-green-color-new: #89BF79;
    --bg-dark-gren-color: #16615D;
    --bg-gray-color: #F5F5F5;
    --bg-dark-pink-color: #DF92A0;
    --bg-purple-color: #93628E;
    /* --bg-dark-purple-color: #493C60; */
    --bg-dark-purple-color: #483C60;
    --bg-beige-light: #FFE5C7;
    --pink-color: #FF335F;
    --dark-red-color: #E62E55;
    --text-dark-green: #0A2927;
    --text-dark-blue: #044155;
}

/* Fix ios bounce scroll */
html, body {
    overscroll-behavior-y: none;
}

html {
    font-size: 16px;
    /* line-height: 1.6; */
}

body {
    background-color: var(--black-color);
    font-family: 'Euclid';
    min-width: 320px;
    overflow-x: hidden;
    font-variant-ligatures: common-ligatures;
    font-feature-settings: 'kern' 1, 'liga' 1;
    font-kerning: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-size: clamp(1rem, -0.25rem + 3.333vw, 2.75rem); */
}

img,
/* canvas, */
svg {
    display: block;
    max-width: 100%;
    height: auto;
}

canvas {
    /*position: relative;*/
    /* background-color: #FF335F; */
    /* transition: opacity 0.5s ease; */
    /* z-index: -1000 */
    opacity: 0 !important;
    will-change: opacity;
}

canvas.show {
    opacity: 1 !important;
}

input[type="text"] {
  font-size: inherit;
}

button {
    font-family: 'Euclid';
}

#samokat {
    background-color: var(--black-color);
    /* display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  background-color: #000;
  z-index: 1000 */
};

#samokat.game {
    display: none;
    background-color: rgba(0,0,0,0);
}

#samokat.game .first-container {
    background-color: inherit;
}

/* #samokat, */
/* .wrapper-global-fix, */
#wrapperglb {
    display: block;
    position: fixed;
    inset: 0;
    overflow-y: auto;
    z-index: 100;
    background-color: var(--black-color);
    /* opacity: 1; */
    /* transition: opacity 0.5s ease; */
    /* padding-block-start: 2vh; */
}

#wrapperglb.game {
    display: none;
}

.img-block.centered img,
img.centered {
    display: block;
    margin-inline: auto;
}

/* img.shadow {
    box-shadow: -3px 4px 0 0 rgba(10,76,73,0.4);
} */

/* h2,h3,h4,h5,h6,p {
    margin-block-start: 1em;
} */

.container {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: clamp(25px,5vh,60px);
    /* min-height: 100vh; */
    /* background-color: var(--white-color); */
    margin-inline: auto;
    /* margin-top: -2px; */
    overflow: hidden;
}

.container.no-flex {
  display: block;
}

.container:first-child {
    padding-block-start: 2vh;
}

.first-container {
    /* padding-block-start: 2vh; */
    background-color: var(--bg-blue-color);
}

.adaptive-first-image-container {
    height: 70vh;
    max-height: 70vh;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    /* background-image: url(../assets/img/image-02-index.webp);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover; */
}

.adaptive-image-container {
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.portal-image-container {
    height: 495px;
    max-height: 495px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.adaptive-first-image-container img,
.adaptive-image-container img,
.portal-image-container img {
    /* display: none; */
    max-width: inherit;
    width: inherit;
    height: 100%;
    max-height: 100%;
    margin: auto;
}

.second-container {
    background-color: var(--bg-light-green-color);
    position: relative;
    z-index: 100;
}

.container .wrapper {
    width: 100%;
    /* width: clamp(320px, 100%, 640px); */
    width: clamp(320px, 100%, 500px);
    /* max-width: 640px; */
    padding-inline: 20px;
    margin-inline: auto;
}

.container .wrapper-wide {
    width: clamp(320px, 100%, 100vw);
    margin-inline: auto;
}

.container .wrapper-normal {
    width: auto;
    max-width: 420px;
    margin-inline: auto;
}

.wrapper .text-block,
.wrapper.text-block {
    width: auto;
    max-width: 420px;
    margin-inline: auto;
    /* margin-top: clamp(20px,12vh,140px); */
    margin-block: 60px;
    text-align: center;
    color: var(--black-color);
    font-size: 20px;
    line-height: 28px;
}

.wrapper.text-block.index-slide-text {
    /* color: var(--white-color); */
    margin-block-start: 35px;
}

.wrapper .text-block.text-dark-blue {
    color: var(--text-dark-blue);
}

.wrapper .text-block.text-dark-green {
    color: var(--text-dark-green);
}

.wrapper .img-block {
    position: relative;
    max-width: 500px;
    margin-inline: auto;
    /* margin-top: clamp(20px,12vh,140px); */
}

.wrapper .img-block img,
.wrapper-wide .img-block img {
    margin-inline: auto;
    border-radius: 20px;
}

.wrapper .img-block.polaroid img,
.wrapper-wide .img-block.polaroid img {
    border-radius: 0;
}

.wrapper .img-block.polaroid-new img,
.wrapper-wide .img-block.polaroid-new img {
    border-radius: 6px;
    box-shadow: -3px 4px 0px 0px rgba(10, 76, 73, 0.40);
}

.wrapper .img-block.polaroid,
.wrapper-wide .img-block.polaroid {
    max-width: 450px;
    margin-block-start: 70px;
    margin-block-end: 25px;
    transform: rotate(-6deg);
}

.wrapper .img-block.polaroid-new,
.wrapper-wide .img-block.polaroid-new {
    max-width: 246px;
    margin-block-start: 48px;
    margin-block-end: 40px;
    transform: rotate(-5deg);

}

.wrapper .img-block.polaroid-new.polaroid-reverse,
.wrapper-wide .img-block.polaroid-new.polaroid-reverse {
    transform: rotate(5deg);
}

.wrapper .img-block.polaroid-new.polaroid-big,
.wrapper-wide .img-block.polaroid-new.polaroid-big {
  max-width: 320px;
  margin-block-end: 60px;
}

.wrapper .img-block.no-round,
.wrapper-wide .img-block.no-round,
.wrapper .img-block.no-round img,
.wrapper-wide .img-block.no-round img {
    margin-inline: auto;
    border-radius: 0px;
}

.wrapper .img-block.first-flash-block {
    background-image: url(assets/img/image-01-03-bg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-block-start: 60px;
    /* margin-block-end: clamp(20px,5vh,48px); */
    margin-block-end: 0;
}

.btn-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 60px;
}

.btn-row.btn-row__slim {
    padding-block: 40px;
}

.btn-mutli-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
}

.last-button-row {
    position: relative;
    background-color: var(--bg-dark-gren-color);
    margin-block-start: clamp(-60px,-5vh,-25px);
    z-index: 12;
}

/* .last-button-row::before {
    content: "";
    position: absolute;
    top: clamp(-160px,-14.5vw,-40px);
    height: clamp(43px,15vw,163px);
    left: 0;
    width: 100%;
    background-image: url(assets/img/page1-bottom-bush@2x.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
} */

.maks-sticker__up {
    margin-block-start: 10%;
    /* opacity: 0; */
    z-index: 150;
}

.maks-rock__up {
  /* position: absolute; */
  margin-block-start: -10%;
  /* opacity: 0; */
  z-index: 150;
}


.comment {
    display: grid;
    grid-template-columns: auto 45px;
    grid-template-areas: "comment-text comment-icon";
    gap: 6px;
    /* margin-block: 50px; */
    /* margin-top: clamp(20px,12vh,140px); */
    /* margin-top: 16px; */    /* margin-top: clamp(20px,12vh,140px); */
    margin-top: 20px;
    /* margin-inline: auto; */
    margin-inline-start: 96px;
    margin-inline-end: 0px;
    align-items: end;
    justify-content: end;
    /* opacity: 0; */
    transition: all 0.7s ease;
}

.hide-comments .comment {
    margin-block-start: 16px;
    opacity: 0;
}

.comment-text {
    grid-area: comment-text;
    background-color: var(--white-color);
    color: var(--black-color);
    padding: 12px 16px;
    border-radius: 20px 20px 0 20px;
    font-size: 1rem;
    line-height: 1.6;
    /* max-width: calc(100% - 96px); */
    /* text-wrap: balance; */
}

.comment-icon {
    grid-area: comment-icon;
    width: 45px;
    height: 45px;
    border-radius: 100vw;
    /* background-color: #e95656; */
    background-image: url(assets/img/bot.png);
    background-position: center;
    background-size: contain;
}

.comment.answer {
    grid-template-columns: 45px auto;
    grid-template-areas: "comment-icon comment-text";
    justify-content: start;
    margin-inline-start: 0px;
    margin-inline-end: 96px;
}

.comment.answer .comment-text {
    border-radius: 20px 20px 20px 0;
}

.comment.answer .comment-icon {
    background-image: url(assets/img/comment-maxim.webp);
    /* background-color: #8e58ec; */
}

.comment.p-man .comment-icon {
    background-image: url(assets/img/p-man.svg);
}

.comment.b-man .comment-icon {
    background-image: url(assets/img/b-man.svg);
}

.comment.dog .comment-icon {
    background-image: url(assets/img/comment-dog.webp);
}

.comment.picnic-girl .comment-icon {
    background-image: url(assets/img/comment-picninc-girl.webp);
}

.promocode-input {
    position: relative;
    display: block;
    color: #404040;
    font-size: 20px;
    /* font-family: Euclid Circular A; */
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.6px;
    padding: 18px 62px 18px 20px;
    border: 0px solid var(--white-color);
    background-color: var(--white-color);
    outline: 0;
    max-width: 320px;
    width: 320px;
    margin-inline: auto;
    border-radius: 22px;
    background-image: url(assets/img/promocode-copy-2.webp);
    background-position: right 18px center;
    background-repeat: no-repeat;
    background-size: 28px;
    transition: all 0.1s ease;
    cursor: pointer;
    z-index: 100;
}

.promocode-input.done {
    background-image: url(assets/img/promocode-copy-done.webp);
    cursor: not-allowed;
}

.bg-gradient {
    background: rgb(71,207,255);
    background: linear-gradient(0deg, rgba(71,207,255,1) 0%, rgba(142,195,128,1) 65%);
}


.bg-gradient-biege {
    background: rgb(255,229,199);
background: linear-gradient(0deg, rgba(255,229,199,1) 15%, rgba(142,195,128,1) 100%);
}
.btn-next {
    outline: 0;
    border: 0px;
    font-size: 18px;
    line-height: 24px;
    color: white;
    background-color: var(--pink-color);
    padding: 20px 51px;
    border-radius: 40px;
    text-decoration: none;
    cursor: pointer;
}

.btn-next:hover {
    background-color: var(--dark-red-color);
}

.btn-next.to-left,
.btn-next.to-right {
    border-radius: 6px;
    position: relative;
}

.btn-next.to-left {
  padding: 20px 51px 20px 36px;
}

.btn-next.to-right {
  padding: 20px 36px 20px 51px;
}

.btn-next.to-left:before,
.btn-next.to-right:after {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  background-color: var(--pink-color);
  /* opacity: 0.5; */
  top: 8px;
  border-radius: 6px;
  transform: rotate(-45deg);
}

.btn-next.to-left:hover:before,
.btn-next.to-right:hover:after {
    background-color: var(--dark-red-color);
}

.btn-next.to-left:before {
  left: -19px;
}

.btn-next.to-right:after {
  right: -19px;
}

.img-block.m-t-0 {
    margin-block-start: 0px;
}

.m-t-16 {
    margin-block-start: 16px;
}

.m-t-20 {
    margin-block-start: 20px;
}

.m-t-40 {
    margin-block-start: 40px;
}

.m-b-20 {
    margin-block-end: 20px;
}

.m-b-40 {
    margin-block-end: 40px;
}

.b-t-35 {
    padding-block-start: 35px;
}

.m-t-n-30 {
    margin-block-start: -30px;
}

.bg-blue {
    background-color: var(--bg-blue-color);
}

.bg-beige {
    background-color: var(--bg-beige-light);
}

.bg-beige-with-gradient {
    background: rgb(255,229,199);
background: linear-gradient(0deg, rgba(255,229,199,1) 70%, rgba(255,114,133,1) 100%);
}

.bg-green {
    background-color: var(--bg-light-green-color);
}

.route-enter-from {
    opacity: 0;
}

.route-page-enter-top {
    opacity: 1;
}

.route-enter-active {
    transition: opacity 1s ease;
}

.route-leave-from {
    opacity: 1;
}

.route-leave-to {
    opacity: 0;
}

.route-leave-active {
    transition: opacity .3s ease;
}

.flash-enter-active {
  transition: opacity 1s ease-in 0.5s;
}

.flash-enter-active:after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1000;
  transition: opacity 1s ease-in 0.5s;
}

.flash-enter-to:after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0;
}

.flash-leave-active {
  transition: opacity .3s ease;
}

.flash-leave-to {
  opacity: 0;
}

.nobr {
    white-space: nowrap;
}

#triggerto {
    position: absolute;
    /* display: none; */
    bottom: 10%;
    left: 10%;
    width: 10%;
    height: 10px;
    /* background-color: black; */
    z-index: -10000;
}

.push-wrapper {
    position: relative;
}

@media only screen and (min-width: 1440px) {
    html {
        background-color: var(--bg-gray-color);
    }
    /* body {
        width: 1400px;
        max-width: 1400px;
        margin-inline: auto;
    } */
    .last-button-row::before  {
        top: -160px;
        height: 163px;
    }
}

@media only screen and (max-width: 640px) {
    .first-flash-block img {
        max-width: 333px;
    }

    .comment {
        margin-inline-start: 51px;
        margin-inline-end: 0px;
    }

    .comment.answer {
        margin-inline-start: 0px;
        margin-inline-end: 51px;
    }
    .wrapper .img-block.polaroid-new,
    .wrapper-wide .img-block.polaroid-new {
        margin-block-end: 28px;
    }
}
</style>
