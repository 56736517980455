import { Random } from 'excalibur';

export const random = new Random();

export const lerp = (start: number, end: number, t: number) => start * (1 - t) + end * t;

export const isBetween = (min: number, max: number, v: number) => v >= min && v <= max;

export const easeInCirc = (x: number) => {
	return 1 - Math.sqrt(1 - Math.pow(x, 2));
};

export const easeInOutQuint = (x: number): number => {
	return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
};

export const easeInOutCirc = (x: number): number => {
	return x < 0.5 ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2 : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2;
};

export const devicePixelRatio = Math.floor(window.devicePixelRatio || 1) > 1 ? 2 : window.innerHeight > 672 ? 2 : 1;
// export const devicePixelRatio = 1;

export const easyTween = (cb: (progress: number) => void, duration: number): Promise<void> => {
	const now = performance.now();
	return new Promise(resolve => {
		requestAnimationFrame(function loop(time) {
			const progress = Math.min((time - now) / duration, 1);

			cb(progress);

			if (progress === 1) {
				return resolve();
			}

			requestAnimationFrame(loop);
		});
	});
};

export const easyRepeater = async function* generateSequence<T>(times: number, interval: number, cb: () => T) {
	for (let i = 0; i <= times; i++) {
		await new Promise(resolve => setTimeout(resolve, interval));
		yield cb();
	}
};

export const getAssetUrl = (path: string) => {
	const prefix = 'https://d8ee5427-d9ac-4e02-972a-fc031e943b6a.selcdn.net/';
	return `${prefix}${path}`;
};
