<template>
    <div class="img-block m-t-20" :class='{ "shadow": shadow, "centered": centered }'>
        <img :src="`${filePath}${filename}`" :width="width" :height="height" :alt="alttext" />
    </div>
</template>

<script lang="js">
import { defineComponent } from 'vue';
// import { getImgAssetUrl } from "@/assets/img";
import { getAssetUrl } from "@/utils/asset"


export default defineComponent({
    name: 'ImageCdn',
    setup() {

    },
    components: {
        getAssetUrl,
    },
    data() {
        return {
            filePath: process.env.VUE_APP_IMAGE_PATH,
        }
    },
    props: {
        filename: String,
        alttext: String,
        width: String,
        height: String,
        shadow: Boolean,
        centered: Boolean,
    },
    computed: {
        src() {
            return require(`${this.filename}`);
        },
    },
    methods: {
        // getImagePath: function(item) {
        //     // return new URL(`../assets/img/$(props.filename).webp`, import.meta.url);
        //     let images = require.context('../assets/', false, /\.png$|\.webp$/);
        //     return images("./" + item);
        // }
    },
    mounted() {
        // console.log(process.env.VUE_APP_IMAGE_PATH);
    },
})
</script>

<style scoped>
/* .img-block.shadow img {
        box-shadow: -3px 4px 0 0 rgba(10,76,73,0.4);
    } */
</style>
