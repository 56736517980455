import { Color, GameEvent, ScreenElement, Sprite, Vector } from 'excalibur';
import gameController from '../../../partials/games-controller';
import { res } from '../res';
import TrickMeterArc from '../partials/trickmeter-arc';
import { EVENTS } from '../enums';

export default class TrickMeter extends ScreenElement {
	private point!: ScreenElement;
	private progressCircle!: ScreenElement;
	private trickMeterArc!: TrickMeterArc;
	private zeroProgressTrickMeterArc!: TrickMeterArc;
	private trickStartTime!: DOMHighResTimeStamp | null;

	constructor(private config: { time: number; radius: number }) {
		super();
	}

	onInitialize() {
		this.pos = new Vector(gameController.halfDrawWidth, gameController.drawHeight - 215 * gameController.pixelRatio);
		this.anchor = new Vector(0.5, 0);

		this.addGraphics();
		this.addTrickMeterArc();
		this.addPoint();
		this.setProgress(0);
	}

	showLine(color: 'green' | 'orange' | 'red') {
		this.graphics.layers.get('line').use(<Sprite>res.graphics.ui.getFrameSprite(`assets/wake/ui/arc/${color}`));
	}

	hideLine() {
		this.graphics.layers.get('line').hide();
	}

	setProgress(progress: number) {
		const maxOffsetAngle = -23;
		const progressAngle = progress * maxOffsetAngle;
		const offsetAngle = 180;
		const currentAngle = (offsetAngle + progressAngle) * (Math.PI / 180);

		let rad = 400 * gameController.pixelRatio;
		let offset = new Vector(0, rad);

		this.point.pos.x = rad * Math.sin(currentAngle) + offset.x;
		this.point.pos.y = rad * Math.cos(currentAngle) + offset.y;
	}

	reset() {
		this.setProgress(0);
		this.resetTrickProgress();
		this.hideTrickProgress();
		this.hideLine();
	}

	setConfig(config: { time: number; radius: number }) {
		this.config = config;

		this.addTrickMeterArc();
	}

	async trickProgress(color: number) {
		if (!this.trickStartTime) this.trickStartTime = performance.now();

		const elapsed = performance.now() - this.trickStartTime;

		this.trickMeterArc.flagDirty();
		this.trickMeterArc.progress = elapsed / (this.config.time * 1000);
		this.trickMeterArc.opacity = 1;
		this.zeroProgressTrickMeterArc.opacity = 0;

		this.trickMeterArc.color = [Color.fromHex('#53FF5A'), Color.fromHex('#FFBD01'), Color.fromHex('#FF4901')][color];

		if (elapsed >= this.config.time * 1000) this.scene.eventDispatcher.emit(EVENTS.TRICK, new GameEvent());
	}

	resetTrickProgress() {
		this.trickStartTime = null;
		this.trickMeterArc.flagDirty();
		this.trickMeterArc.progress = 0;
		this.trickMeterArc.opacity = 0;
		this.zeroProgressTrickMeterArc.opacity = 1;
	}

	hideTrickProgress() {
		this.trickMeterArc.opacity = 0;
		this.zeroProgressTrickMeterArc.opacity = 0;
	}

	private addTrickMeterArc() {
		if (this.progressCircle) this.removeChild(this.progressCircle);

		this.progressCircle = new ScreenElement();
		this.progressCircle.pos = new Vector(0, 108).scaleEqual(gameController.pixelRatio);

		this.trickMeterArc = new TrickMeterArc({
			lineWidth: 9 * gameController.pixelRatio,
			radius: this.config.radius * gameController.pixelRatio,
		});
		this.trickMeterArc.opacity = 0;

		this.progressCircle.graphics.layers
			.create({
				name: 'trickMeterArc',
				order: 0,
			})
			.use(this.trickMeterArc, {
				anchor: Vector.Half,
			});

		this.zeroProgressTrickMeterArc = new TrickMeterArc({
			lineWidth: 7 * gameController.pixelRatio,
			radius: this.config.radius * gameController.pixelRatio,
		});
		this.zeroProgressTrickMeterArc.opacity = 0;

		this.progressCircle.graphics.layers
			.create({
				name: 'zeroProgressTrickMeterArc',
				order: 0,
			})
			.use(this.zeroProgressTrickMeterArc, {
				anchor: Vector.Half,
			});

		this.addChild(this.progressCircle);
	}

	private addGraphics() {
		this.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/wake/ui/arc/arc'), {
			anchor: new Vector(0.5, 0),
			offset: new Vector(0, 10).scaleEqual(gameController.pixelRatio),
		});

		this.graphics.layers.create({
			name: 'line',
			order: 0,
			offset: new Vector(0, 13).scaleEqual(gameController.pixelRatio),
		});
	}

	private addPoint() {
		this.point = new ScreenElement();
		this.point.graphics.use(<Sprite>res.graphics.ui.getFrameSprite('assets/wake/ui/arc/pipka'), { anchor: Vector.Half });

		this.addChild(this.point);
	}
}
