
// @ts-nocheck
import PreLoader from '@/components/PreLoader.vue';
import LetoButton from '@/components/LetoButton';
import {LoaderState, useLoaderStore} from "@/store";
import {useRouter} from "vue-router";
import gameController from "../../games/src/partials/games-controller";
import {SCENES} from "../../games/src/enums";
import {ROUTES} from "@/preroutes";

export default {
  name: "LoaderView",
  components: {
    PreLoader,
    LetoButton
  },
  data: () => {
    return {
      timeout: undefined,
      waitingTooMuch: false
    }
  },
  setup() {
    const router = useRouter()
    const loader = useLoaderStore()

    return {
      router,
      loader,
    }
  },
  mounted() {
    setTimeout(() => this.waiting(), 1000)
  },
  methods: {
    reload() {
      const loader = <LoaderState>this.loader
      loader.promise = gameController.prepareScene(<SCENES>loader.scene)
      this.waiting()
    },
    async waiting() {
      const loader = <LoaderState>this.loader

      this.waitingTooMuch = false

      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout)
        this.waitingTooMuch = true
      }, 15000)

      loader.promise?.then(() => {
        this.router.push({
          path: <ROUTES>loader.route
        })
      })
    }
  }
}
