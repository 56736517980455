import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a945f8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["img-block m-t-20", { "shadow": _ctx.shadow }])
  }, [
    _createElementVNode("img", _mergeProps({
      src: require(`@/assets/img/${_ctx.filename}`)
    }, _ctx.$attrs, {
      class: _ctx.classes,
      alt: _ctx.alttext
    }), null, 16, _hoisted_1)
  ], 2))
}