<template>
    <div id="recaptcha"
           class="g-recaptcha"
           :data-sitekey="siteKey"
           data-callback="getToken"
           data-size="invisible" />
</template>

<!-- <script setup>
import { computed, onMounted, defineComponent } from 'vue';
import http from 'axios';
import { useRouter } from "vue-router";
import { ROUTES } from "@/preroutes";
import { usePromocodeStore } from "@/store/promocode";
const promocodeStore = usePromocodeStore();

const router = useRouter();
const siteKey = "6LdG3dAmAAAAAJOCNKRny-f0gW0s0jsd6tavSbdu";
// const siteKey = "6LeRT-kUAAAAAJMzSZOu5hSNbx2fCtEsy06YUz2M";
window.grecaptcha.ready(function () {
    console.log('g ready');
    grecaptcha.render('recaptcha');
    // grecaptcha.render();
    
});

window.getToken = (response) => {
    console.log('получился ответ');
    promocodeStore.getPromocode("genhgtuitu04");
    console.log(promocodeStore.promocode);

    // console.log(response);
    // http.post('https://leto.groznov.co/api/promocode ', {
    //     // crossDomain: true,
    //     'token': response
    // }).then((res) => {
    //     // promocodeStore.getPromocode(res.data.data.code)
    //     // emit('success', res);
    //     console.log(res);
    // });
}
</script> -->
<script>
import { computed, onMounted, defineComponent } from 'vue';
import http from 'axios';
// import Cookies from "js-cookie";

export default defineComponent({
    name: 'CpBox',
    // setup() {
    //     const siteKey = computed(() => {
    //         return '6LdG3dAmAAAAAJOCNKRny-f0gW0s0jsd6tavSbdu';
    //     });

    //     const handleError = () => {
    //         // Do some validation
    //         console.log('error');
    //     };

    //     const handleSuccess = (response: any) => {
    //         // Do some validation
    //         console.log("yarr");
    //         console.log(response);
    //     };

    //     return {
    //         handleSuccess,
    //         handleError,
    //         siteKey,
    //     };
    // },
    data() {
        return {
            // response: '',
            siteKey: "6LdG3dAmAAAAAJOCNKRny-f0gW0s0jsd6tavSbdu",
        }
    },
    methods: {
        getToken(response) {
            console.log(response);
            // grecaptcha.execute();
            // console.log(Response);
        }
    },
    mounted() {
        window.grecaptcha.ready(function () {
            console.log('g ready');
            grecaptcha.render('recaptcha');
            // grecaptcha.render();

        });

        // window.getToken = (response) => {
        //     console.log('получился ответ');
        //     promocodeStore.getPromocode("genhgtuitu04");
        //     console.log(promocodeStore.promocode);

        //     // console.log(response);
        //     // http.post('https://leto.groznov.co/api/promocode ', {
        //     //     // crossDomain: true,
        //     //     'token': response
        //     // }).then((res) => {
        //     //     // promocodeStore.getPromocode(res.data.data.code)
        //     //     // emit('success', res);
        //     //     console.log(res);
        //     // });
        // };
        // this.handleSuccess();
        // this.getToken('dfsfsd');
        // console.log(document.querySelector('#recaptcha')?.classList);
        // this.handleSuccess(elm);
        // this.rCaptchaCall(Response);
        // window.rCaptchaCall = this.rCaptchaCall;
        // const script = document.createElement('script');
        // script.onload = () => {
        //   //do stuff with the script
        // };
        // script.src = 'https://www.google.com/recaptcha/api.js';

        // document.head.appendChild(script); //or something of the likes
    },
})
</script>



<style scoped>
    #recaptcha {
        /* z-index: -10000; */
        /* visibility: hidden; */
    }
</style>
