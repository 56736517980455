import { Color, Raster, RasterOptions } from 'excalibur';

type TrickMeterArcOptions = {
	radius: number;
	progress?: number;
};

export default class TrickMeterArc extends Raster {
	private radius: number;
	public progress: number;

	constructor(options: RasterOptions & TrickMeterArcOptions) {
		super(options);
		this.lineWidth = options.lineWidth || 15;
		this.width = options.radius * 2 + this.lineWidth * 2;
		this.height = options.radius * 2 + this.lineWidth * 2;
		this.radius = options.radius;
		this.progress = options.progress || 0;
		this.color = options.color || Color.Black;

		this.rasterize();
	}

	clone(): TrickMeterArc {
		// @ts-ignore
		return new TrickMeterArc({
			radius: this.radius,
			...this.cloneRasterOptions(),
			...this.cloneGraphicOptions(),
		});
	}

	execute(ctx: CanvasRenderingContext2D): void {
		let path = new Path2D();
		path.arc(this.radius + this.lineWidth, this.radius + this.lineWidth, this.radius, 0, 2 * Math.PI);
		ctx.strokeStyle = 'rgba(1,1,1,0.3)';
		ctx.lineWidth = this.lineWidth;
		ctx.stroke(path);

		let progress = new Path2D();
		progress.arc(this.radius + this.lineWidth, this.radius + this.lineWidth, this.radius, -0.5 * Math.PI, (this.progress * 2 - 0.5) * Math.PI);

		ctx.strokeStyle = this.color.toString('rgb');
		ctx.lineCap = 'round';
		ctx.lineWidth = this.lineWidth;

		this.progress > 0 && ctx.stroke(progress);
	}
}
