import { Entity, GraphicsComponent, Sprite, TransformComponent, Vector } from 'excalibur';
import { EVENTS } from '../enums';
import { res } from '../res';
import { InstructorPoseEvent } from '../partials/custom-events';

export default class Instructor extends Entity {
	private transform!: TransformComponent;
	private graphics!: GraphicsComponent;

	constructor() {
		super([new TransformComponent(), new GraphicsComponent()]);

		this.transform = <TransformComponent>this.get(TransformComponent);
		this.graphics = <GraphicsComponent>this.get(GraphicsComponent);
	}

	get pos() {
		return this.transform.pos;
	}

	set pos(v: Vector) {
		this.transform.pos = v;
	}

	onInitialize() {
		this.pos = new Vector(0, 150 * this.scene.engine.pixelRatio);
		this.showPose(0);
		this.registerEvents();
	}

	private registerEvents() {
		// @ts-ignore
		this.scene.eventDispatcher.on(EVENTS.INSTRUCTOR_POSE, (e: InstructorPoseEvent) => this.showPose(e.indexId));
	}

	private showPose(indexId: number) {
		const xAnchor = (() => {
			switch (indexId) {
				case 3:
					return 0.473;

				default:
					return 0.5;
			}
		})();

		this.graphics.use(<Sprite>res.graphics.instructor.getFrameSprite(`assets/yoga/Instructor/instructor${indexId}`), {
			anchor: new Vector(xAnchor, 1),
		});
	}

	showLoosePose() {
		this.graphics.use(<Sprite>res.graphics.instructor.getFrameSprite(`assets/yoga/Instructor/instructor_loose`), {
			anchor: new Vector(0.325, 1),
		});
	}

	showWinPose() {
		this.graphics.use(<Sprite>res.graphics.instructor.getFrameSprite(`assets/yoga/Instructor/instructor_win`), {
			anchor: new Vector(0.281, 1),
		});
	}

	showStartPose() {
		this.graphics.use(<Sprite>res.graphics.instructor.getFrameSprite(`assets/yoga/Instructor/instructor0`), {
			anchor: new Vector(0.5, 1),
		});
	}

	showDrinksPose(){
		this.graphics.use(<Sprite>res.graphics.instructor.getFrameSprite(`assets/yoga/Instructor/instructor_drink`), {
			anchor: new Vector(0.5, 1),
		});
	}
}
