import { Actor, Animation, Color, Rectangle, Sprite, Vector } from 'excalibur';
import { res } from '../res';
import gameController from '../../../partials/games-controller';
import { config } from '../config';
import { tricksAnimation } from '../animations';

export default class Trick extends Actor {
	private initPos = config.playerStartPosition.clone();

	onInitialize() {
		this.pos = this.initPos;
		this.z = 10;
		this.graphics.opacity = 0;
	}

	show(trick: number) {
		this.addGraphics();
		// @ts-ignore
		const trickAnim = <Animation>tricksAnimation.getAnimation(`trick${trick}`);
		trickAnim.scale = new Vector(config.cameraZoomValue / 2, config.cameraZoomValue / 2);
		this.graphics.layers.get('player').use(trickAnim);

		if (trick === 1) {
			this.graphics.layers.get('player').offset = new Vector(-20, 0).scaleEqual(gameController.pixelRatio);
		}

		if (trick === 2) {
			this.graphics.layers.get('player').offset = new Vector(-10, -40).scaleEqual(gameController.pixelRatio);
		}

		if (trick === 3) {
			this.graphics.layers.get('player').offset = new Vector(-10, -50).scaleEqual(gameController.pixelRatio);
		}

		requestAnimationFrame(() => {
			this.graphics.opacity = 1;
		});
	}

	async hide() {
		this.graphics.opacity = 0;
		requestAnimationFrame(() => this.graphics.hide());
	}

	async playTrick(name: 'trick1' | 'trick2' | 'trick3') {
		await tricksAnimation.play(name);
	}

	private addGraphics() {
		this.graphics.layers
			.create({
				name: 'water',
				order: 0,
				offset: new Vector(0, gameController.halfDrawHeight),
			})
			.use(
				new Rectangle({
					width: gameController.drawWidth + 50 * gameController.pixelRatio,
					height: gameController.drawHeight,
					color: Color.fromHex('#0a749a'),
				}),
				{
					anchor: new Vector(0.5, 1),
				},
			);

		const waveSprite = <Sprite>res.graphics.tricks.getFrameSprite('assets/wake/tricks/tricks-wave');
		waveSprite.scale = new Vector(config.cameraZoomValue / 2, config.cameraZoomValue / 2);

		this.graphics.layers
			.create({
				name: 'wave',
				order: 0,
				offset: new Vector(30 * gameController.pixelRatio, gameController.halfDrawHeight - 50 * gameController.pixelRatio),
			})
			.use(waveSprite, {
				anchor: new Vector(0.5, 1),
			});

		this.graphics.layers.create({
			name: 'player',
			order: 0,
			offset: new Vector(30 * gameController.pixelRatio, 0),
		});
	}
}
