import {defineStore} from "pinia";

interface Promocode {
    promocode: string
}

export const usePromocodeStore = defineStore('promocode', {
    state: (): Promocode => ({
        promocode: 'ошибка получения'
    }),
    actions: {
        setPromocode(promocode: String) {
            // @ts-ignore
            this.promocode = promocode
        }
    }
});