import JSONSpriteSheet from './json-spritesheet';
import { Animation } from 'excalibur';
import gameController from './games-controller';

export default abstract class AnimationsList<T extends { [key: string]: Animation }> {
  protected list!: T;

  protected constructor(protected readonly spritesheet: JSONSpriteSheet) {
  }

  getAnimation(name: keyof T) {
    if (!this.spritesheet.isLoaded()) return null;

    if (!this.list) this.list = this.getList();

    return this.list[name];
  }

  play(name: keyof T): Promise<void> {
    return new Promise(async resolve => {
      const anim = <Animation>this.getAnimation(name);

      anim.reset();
      anim.play();

      if (anim.canFinish) {
        await gameController.waitFor(anim.frames.length * anim.frameDuration);
        resolve();
      }
    });
  }

  protected abstract getList(): T;
}
